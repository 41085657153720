import { FC } from 'react';
import { useAtomValue } from 'jotai';
import { useUpdateAtom } from 'jotai/utils';

import { classMerge } from 'utils/classMerge';

import { SNACKBAR_FAILED_PROGRESS } from 'configConstants';

import {
  snackbarAtom,
  snackbarProgressAtom,
  updateProgressSnackbarAtom,
} from 'atoms/snackbars/snackbars.atom';

import classes from './SnackbarsStackViewer.module.css';

const SnackbarsStackViewer: FC = () => {
  const snackbars = useAtomValue(snackbarAtom);
  const progress = useAtomValue(snackbarProgressAtom);
  const updateSnackbar = useUpdateAtom(updateProgressSnackbarAtom);

  const finishedProgress = progress.filter(
    ({ progressValue }) => progressValue === 100
  ).length;
  const isFailedSnackbar = progress.filter(
    ({ progressValue }) => progressValue === SNACKBAR_FAILED_PROGRESS
  ).length;

  const handleShowSnackbars = () => {
    snackbars.map(({ key, progressValue, isVisible, isProgress }) => {
      if (isProgress) {
        updateSnackbar({
          snackbarKey: key as number,
          isVisible: !isVisible,
        });
      }
    });
  };

  return (
    <div
      className={classMerge(classes.root, {
        [classes.failedSnackbar]: !!isFailedSnackbar,
      })}
      onClick={handleShowSnackbars}
    >
      <div className={classes.progressBar}>
        <div className={classes.bodyInProgress}>
          {finishedProgress}/{progress.length}
        </div>
        <div className={classes.progresBarInner}>
          <div
            className={classes.progressBarTotal}
            style={{ width: `${(finishedProgress / progress.length) * 100}%` }}
          />
        </div>
      </div>
    </div>
  );
};

export default SnackbarsStackViewer;
