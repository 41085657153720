import { ShortcutConfig } from 'models/shortcut/shortcutConfig.model';

export const mapShortcutToKeyConfig = (
  key: string
): Omit<ShortcutConfig, 'keyDownCb' | 'name' | 'keyUpCb'> => {
  const areMultipleKeys = key.includes('+');
  const array = areMultipleKeys ? key.split('+').map((k) => k.trim()) : [key];

  return {
    ctrlKey: array.includes('ctrl'),
    altKey: array.includes('alt'),
    metaKey: array.includes('meta'),
    key: areMultipleKeys
      ? array.find(
          (k) => k !== 'alt' && k !== 'ctrl' && k !== 'shift' && k !== 'meta'
        ) || ''
      : key,
    shiftKey: array.includes('shift'),
  };
};
