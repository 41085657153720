import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';

import { StateTypes } from 'models';
import { TotpSignInUserRequest } from 'models/user/totpSignInUserRequest.model';

import { userTotpSignInAtom } from 'atoms/manager/users';
import { getStateByScopeAtom } from 'atoms/state';

import { IconAvatar } from 'icons';

import Button from 'common/Button/Button';
import FormInput from 'common/FormInput/FormInput';
import Subtitle2 from 'common/Subtitle2/Subtitle2';

import { totpLoginSchema } from 'validations/schemas/totpLoginSchema';

import AuthBox from '_account/AuthBox/AuthBox';

import classes from './TotpLogin.module.css';

const TotpLogin: FC = () => {
  const history = useHistory();
  const isLoading = useAtomValue(getStateByScopeAtom(StateTypes.AUTH_FORM));
  const signIn = useUpdateAtom(userTotpSignInAtom);

  const onSuccessCallback = () => {
    history.push('/manager');
  };

  const formikConfig = {
    initialValues: {
      code: '',
    },
    validationSchema: totpLoginSchema,
    validateOnMount: false,
    onSubmit: (values: TotpSignInUserRequest) => {
      signIn({ values, onSuccessCallback });
    },
  };

  return (
    <AuthBox heading="The most powerful web based 3D editor">
      <div className={classes.avatar}>
        <IconAvatar size={200} />
      </div>
      <div className={classes.wrapper}>
        <div className={classes.heading}>
          <Subtitle2>
            An email with the verification code was sent to your email address.
            Enter the code below.
          </Subtitle2>
        </div>
        <Formik {...formikConfig}>
          <Form className={classes.form}>
            <FormInput name="code" label="Code" />
            <div className={classes.actions}>
              <Button variant="primary" disabled={isLoading} type="submit">
                Sign In
              </Button>
            </div>
          </Form>
        </Formik>
      </div>
    </AuthBox>
  );
};

export default TotpLogin;
