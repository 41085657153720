import { FC, ReactNode } from 'react';

import Heading3 from 'common/Heading3/Heading3';
import Logo from 'common/Logo/Logo';

import classes from './AuthBox.module.css';

type Props = {
  children: ReactNode;
  heading: string;
};

const AuthBox: FC<Props> = ({ children, heading }) => {
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <Logo />
          <Heading3>{heading}</Heading3>
        </div>
        <div className={classes.formBox}>{children}</div>
      </div>
    </div>
  );
};

export default AuthBox;
