import { FC, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';

import {
  getSelectedWorkspaceLicenseAtom,
  workspaceLicenseAtom,
} from 'atoms/manager/workspaces.atom';
import { modalDataAtom } from 'atoms/modal';

import Body1 from 'common/Body1/Body1';
import Button from 'common/Button/Button';
import Heading1 from 'common/Heading1/Heading1';

import classes from './ExceededLimitsModal.module.css';

type Props = {
  body?: string;
};

const ExceededLimitsModal: FC<Props> = ({ body }) => {
  const history = useHistory();
  const workspaceLicense = useAtomValue(workspaceLicenseAtom);
  const getSelectedWorkspaceLicense: any = useUpdateAtom(
    getSelectedWorkspaceLicenseAtom
  );
  const toggleModal = useUpdateAtom(modalDataAtom);

  useEffect(() => {
    getSelectedWorkspaceLicense();
  }, [getSelectedWorkspaceLicense]);

  const handleRedirectToMarketplace = () => {
    const path =
      workspaceLicense?.licenseType !== 'FREE' ? 'boosters' : 'plans';
    toggleModal(null);
    history.push(`/manager/marketplace/${path}`);
  };

  const handleCancel = () => {
    if (history.location.pathname.includes('/manager')) {
      history.goBack();
    }

    toggleModal(null);
  };

  return (
    <div className={classes.root}>
      <div className={classes.heading}>
        <Heading1>Limit exceeded</Heading1>
      </div>
      <div className={classes.message}>
        <Body1>{body}</Body1>
        <Body1>
          To continue, expand your limits by
          {workspaceLicense?.licenseType === 'FREE'
            ? ' upgrading your plan'
            : ' buying booster packs'}
        </Body1>
      </div>
      <div className={classes.buttons}>
        <Button variant="primary" onClick={handleRedirectToMarketplace}>
          Go to marketplace
        </Button>
        <Button variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default ExceededLimitsModal;
