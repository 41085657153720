import { FC, ReactNode } from 'react';

import { classMerge } from 'utils/classMerge';

import classes from './Body2.module.css';

type Props = {
  children: ReactNode;
  ellipsis?: boolean;
  title?: string;
};

const Body2: FC<Props> = ({ children, ellipsis, title }) => {
  return (
    <div
      className={classMerge(classes.root, { [classes.ellipsis]: ellipsis })}
      title={title}
    >
      {children}
    </div>
  );
};

export default Body2;
