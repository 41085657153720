import { FC } from 'react';

import Body1 from 'common/Body1/Body1';

import classes from './InputCharacterCounter.module.css';

type Props = {
  length: number;
  maxLength: number;
};

const InputCharacterCounter: FC<Props> = ({ length, maxLength }) => {
  return (
    <div className={classes.root}>
      <div className={classes.lengthColor}>
        <Body1>{length}/</Body1>
      </div>
      <div className={classes.maxLengthColor}>
        <Body1>{maxLength}</Body1>
      </div>
    </div>
  );
};

export default InputCharacterCounter;
