// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Subtitle2-module__root--yHlCA {\n  font-size: var(--subtitle2FontSize);\n  font-weight: 400;\n  letter-spacing: 0.1px;\n}\n\n.Subtitle2-module__bold--NG5Ve {\n  font-weight: 700;\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/Subtitle2/Subtitle2.module.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".root {\n  font-size: var(--subtitle2FontSize);\n  font-weight: 400;\n  letter-spacing: 0.1px;\n}\n\n.bold {\n  font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Subtitle2-module__root--yHlCA",
	"bold": "Subtitle2-module__bold--NG5Ve"
};
export default ___CSS_LOADER_EXPORT___;
