import { AssetType, NODE_TYPES } from '@metavrse-inc/metavrse-lib';

export const uploadableWithoutConvertionAssetTypes: Record<string, AssetType> =
  {
    c3b: NODE_TYPES.object,
    jpg: NODE_TYPES.image,
    jpeg: NODE_TYPES.image,
    tiff: NODE_TYPES.image,
    gif: NODE_TYPES.image,
    png: NODE_TYPES.image,
    js: NODE_TYPES.javascript,
    css: NODE_TYPES.stylesheet,
    mp4: NODE_TYPES.video,
    mov: NODE_TYPES.video,
    hdr: NODE_TYPES.image,
  };
