import { World } from '@metavrse-inc/metavrse-lib';

import { BASE_PATH, SCENES_FOLDER, WORLD_FILENAME } from 'configConstants';

import { fsp } from 'services/fs.service';
import { convertToJSON } from 'services/helpers';

export const writeWorldFile = async (
  key: string,
  data: World
): Promise<void | null> => {
  try {
    await fsp.writeFile(
      `${BASE_PATH}${SCENES_FOLDER}/${key}/${WORLD_FILENAME}`,
      new TextEncoder().encode(JSON.stringify(data))
    );
  } catch (error) {
    console.log('ERROR writeWorldFile: ', error);
    return null;
  }
};

export const readWorldFile = async (key: string): Promise<World | null> => {
  try {
    const data = await fsp.readFile(
      `${BASE_PATH}${SCENES_FOLDER}/${key}/${WORLD_FILENAME}`
    );

    if (typeof data === 'string') {
      return null;
    }

    return convertToJSON<World>(data);
  } catch (error) {
    console.log('ERROR readWorldFile: ', error);
    return null;
  }
};
