// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Login-module__avatar--ErdqI {\n  margin-right: 50px;\n  width: 40%;\n  max-width: 200px;\n}\n\n.Login-module__form--OzQWy {\n  display: grid;\n  grid-template-rows: repeat(2, minmax(43px, auto));\n  width: var(--formWidth);\n}\n\n.Login-module__actions--jgpd8 {\n  display: grid;\n  grid-template-rows: repeat(3, 1fr);\n  row-gap: var(--spacing6);\n  margin-top: var(--spacing6);\n}\n\n.Login-module__error--sDyt6 {\n  color: var(--error);\n  margin-top: var(--spacing2);\n  min-height: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/_account/Login/Login.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,iDAAiD;EACjD,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,kCAAkC;EAClC,wBAAwB;EACxB,2BAA2B;AAC7B;;AAEA;EACE,mBAAmB;EACnB,2BAA2B;EAC3B,gBAAgB;AAClB","sourcesContent":[".avatar {\n  margin-right: 50px;\n  width: 40%;\n  max-width: 200px;\n}\n\n.form {\n  display: grid;\n  grid-template-rows: repeat(2, minmax(43px, auto));\n  width: var(--formWidth);\n}\n\n.actions {\n  display: grid;\n  grid-template-rows: repeat(3, 1fr);\n  row-gap: var(--spacing6);\n  margin-top: var(--spacing6);\n}\n\n.error {\n  color: var(--error);\n  margin-top: var(--spacing2);\n  min-height: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatar": "Login-module__avatar--ErdqI",
	"form": "Login-module__form--OzQWy",
	"actions": "Login-module__actions--jgpd8",
	"error": "Login-module__error--sDyt6"
};
export default ___CSS_LOADER_EXPORT___;
