import { atom } from 'jotai';

import { AuthenticationStatus } from 'models/user/AuthenticationStatus.enum';

const { NOT_VERIFIED, AUTHENTICATED, NOT_AUTHENTICATED } = AuthenticationStatus;

export const isAuthenticatedAtom = atom<AuthenticationStatus>(NOT_VERIFIED);

export const signInAtom = atom(null, (get, set) => {
  set(isAuthenticatedAtom, AUTHENTICATED);
});

export const signOutAtom = atom(null, (get, set) => {
  set(isAuthenticatedAtom, NOT_AUTHENTICATED);
});
