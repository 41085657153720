import { ChangeEvent, FC } from 'react';
import { FormikErrors, FormikTouched } from 'formik';

import { classMerge } from 'utils/classMerge';

import { DEFAULT_DROPDOWN_VALUE } from 'configConstants';

import { Option } from 'models/dropdown';

import { IconArrowDown } from 'icons';

import classes from './Dropdown.module.css';

type Props = {
  label: string;
  name: string;
  options: Option[];
  isLight?: boolean;
  value: string;
  defaultOption?: boolean;
  handleChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  touched?: FormikTouched<any>;
  errors?: FormikErrors<any>;
};

const Dropdown: FC<Props> = ({
  label,
  name,
  options,
  isLight,
  value,
  defaultOption = true,
  handleChange,
  ...rest
}) => {
  const isInitialValue = value === DEFAULT_DROPDOWN_VALUE;
  const { touched, errors } = rest;
  const isTouchedAndError = touched && touched[name] && errors && errors[name];

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <select
          name={name}
          className={classMerge(classes.dropdown, {
            [classes.dropdownError]: isTouchedAndError,
            [classes.dropdownWhite]: !isInitialValue,
            [classes.dropdownLight]: isLight,
            [classes.dropdownPlaceholder]: isInitialValue,
          })}
          onChange={handleChange}
          placeholder={label}
          value={value}
        >
          {defaultOption && (
            <option value="" disabled>
              Select {label.toLowerCase()}
            </option>
          )}
          {options.map((option, index) => (
            <option key={`${option.value}-${index}`} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <div className={classes.icon}>
          <IconArrowDown />
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
