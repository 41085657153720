import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

import { SELECTED_WORKSPACE_ID } from 'configConstants';

import { LoadingStatus, StateTypes } from 'models';
import { Option } from 'models/dropdown';
import WorkspaceActiveLicence from 'models/manager/subscriptions/workspaceActiveLicence.model';
import {
  NewWorkspaceUserRequest,
  WorkspaceUserResponse,
} from 'models/manager/workspace-users.model';
import {
  UpdateWorkspaceUser,
  WorkspaceResponse,
} from 'models/manager/workspaces.model';
import { LimitsUsageInformation } from 'models/manager/workspaces/limitsUsageInformation';
import { WorkspaceLimitsTypes } from 'models/manager/workspaces/workspaceLimitsTypes.enum';
import { WorkspaceLimitsUsageResponse } from 'models/manager/workspaces/workspaceLimitsUsageResponse.model';
import SnackbarType from 'models/snackbars/snackbarType';
import { WorkspaceAccessType } from 'models/workspaces';

import { axiosInstance } from 'services/axios';
import {
  deleteWorkspace,
  getWorkspaceActiveLicence,
  getWorkspaceLimitsUsage,
  getWorkspaces,
  updateWorkspace,
} from 'services/workspaces.service';
import {
  createWorkspaceUser,
  deleteWorkspaceUser,
  getWorkspaceUsers,
  getWorkspaceUsersWithoutProject,
  updateWorkspaceUser,
} from 'services/workspaces-users.service';

import {
  getLimitsWithErrorMessages,
  getWorkspaceLimitsByType,
} from 'atoms/helpers/manager/workspaceLimits-helpers';
import { userDataAtom } from 'atoms/manager/users';
import { setSnackbarAtom } from 'atoms/snackbars/snackbars.atom';
import { setStateAtom } from 'atoms/state';

const { MANAGER_RIGHT, MANAGER_CENTER } = StateTypes;
const { LOADING, READY } = LoadingStatus;
const { ERROR, SUCCESS, WARNING } = SnackbarType;

export const isEditWorkspaceDataAtom = atom<boolean>(false);
export const isAddWorkspaceUserModeAtom = atom<boolean>(false);
export const workspacesAtom = atom<WorkspaceResponse[] | null>(null);
export const dividedWorkspacesAtom = atom<Record<
  WorkspaceAccessType,
  WorkspaceResponse[]
> | null>(null);
export const selectedWorkspaceAtom = atom<WorkspaceResponse | null>(null);
export const workspaceLimitsUsageAtom = atom<
  WorkspaceLimitsUsageResponse[] | null
>(null);
export const workspaceLimitsByTypeAtom =
  atom<WorkspaceLimitsUsageResponse | null>(null);
export const workspaceUsersAtom = atom<WorkspaceUserResponse[] | null>(null);
export const selectedWorkspaceIdAtom = atomWithStorage<number | null>(
  SELECTED_WORKSPACE_ID,
  null
);
export const workspaceLicenseAtom = atom<WorkspaceActiveLicence | null>(null);
export const workspaceUsersWithoutProjectsAtom = atom<Option[]>([]);

export const selectedWorkspaceUserAtom = atom<
  undefined | WorkspaceUserResponse
>(undefined);

export const workspaceIdAtom = atom(
  (get) => get(selectedWorkspaceIdAtom),
  (get, set, workspaceId: number) => {
    set(selectedWorkspaceIdAtom, workspaceId);
  }
);

export const getWorkspacesAtom = atom(null, async (get, set) => {
  set(setStateAtom([MANAGER_CENTER]), { value: LOADING });

  const userId = get(userDataAtom)?.user.id;

  if (!userId) {
    set(setStateAtom([MANAGER_CENTER]), { value: READY });
    return;
  }

  try {
    const response = await getWorkspaces(axiosInstance, userId);
    set(workspacesAtom, response.data);
    set(setStateAtom([MANAGER_CENTER]), { value: READY });
  } catch (error: any) {
    console.log(error);
    set(setStateAtom([MANAGER_CENTER]), { value: READY });
  }
});

export const fillWorkspaceAtom = atom(
  null,
  (get, set, workspace: WorkspaceResponse | null) => {
    set(selectedWorkspaceAtom, workspace);
  }
);

export const filterWorkspacesByOrganizationIdAtom = atom(
  null,
  (get, set, organizationId: number | null) => {
    const workspaces = get(workspacesAtom);
    const filteredWorkspaces = organizationId
      ? workspaces?.filter((w) => w.organizationId === organizationId)
      : workspaces;

    const dividedWorkspaces: Record<string, WorkspaceResponse[]> = {
      MANAGED: filteredWorkspaces || [],
    };

    set(dividedWorkspacesAtom, dividedWorkspaces);
  }
);

export const organizationsFromWorkspacesAtom = atom<Option[]>((get) => {
  const workspaces = get(workspacesAtom) || [];
  const organizationsMap = workspaces.reduce((pv, workspace) => {
    pv.set(workspace.organization.id, {
      label: workspace.organization.name,
      value: workspace.organization.id,
    });
    return pv;
  }, new Map<number, Option>());

  return [
    { label: 'All organizations', value: 0 },
    ...Array.from(organizationsMap.values()),
  ];
});

export const getWorkspaceLimitsAtom = atom(
  null,
  async (get, set, workspaceId: number) => {
    set(setStateAtom([MANAGER_RIGHT]), { value: LOADING });

    try {
      const response = await getWorkspaceLimitsUsage(
        axiosInstance,
        workspaceId
      );

      set(workspaceLimitsUsageAtom, response.data);
      set(setStateAtom([MANAGER_RIGHT]), { value: READY });
    } catch (error: any) {
      set(workspaceLimitsUsageAtom, null);
      set(setStateAtom([MANAGER_RIGHT]), { value: READY });
    }
  }
);

export const getWorkspaceLimitsUsageAtom = atom(
  null,
  async (get, set, { workspaceId, type, pathname }: LimitsUsageInformation) => {
    await set(getWorkspaceLimitsAtom, workspaceId);
    const limits = get(workspaceLimitsUsageAtom);

    const limitsWithErrorMessages = getLimitsWithErrorMessages(
      limits ?? [],
      type
    );

    if (limitsWithErrorMessages.length > 0 && pathname !== '/manager') {
      set(setSnackbarAtom, {
        title: 'Limits Warning!',
        body:
          limitsWithErrorMessages
            .map((limitError) => limitError.usageErrorMessage)
            .join('\r\n') + '\nRemember to update your plan!',
        type: WARNING,
      });
    }
  }
);

export const getLimitsByTypeAtom = atom(
  null,
  (get, set, type: WorkspaceLimitsTypes) => {
    const workspaceLimits = get(
      workspaceLimitsUsageAtom
    ) as WorkspaceLimitsUsageResponse[];

    set(
      workspaceLimitsByTypeAtom,
      getWorkspaceLimitsByType(type, workspaceLimits)
    );
  }
);

export const getWorkspaceUsersAtom = atom(
  null,
  (get, set, workspaceId: number) => {
    set(setStateAtom([MANAGER_RIGHT]), { value: LOADING });

    getWorkspaceUsers(
      axiosInstance,
      workspaceId,
      (res) => {
        set(workspaceUsersAtom, res.data);
        set(setStateAtom([MANAGER_RIGHT]), { value: READY });
      },
      () => {
        set(workspaceUsersAtom, null);
        set(setStateAtom([MANAGER_RIGHT]), { value: READY });
      }
    );
  }
);

export const addWorkspaceUserAtom = atom(
  null,
  (get, set, newWorkspaceUser: NewWorkspaceUserRequest) => {
    set(setStateAtom([MANAGER_RIGHT]), { value: LOADING });

    const workspaceId = get(selectedWorkspaceAtom)?.id;

    if (!workspaceId) {
      set(setStateAtom([MANAGER_RIGHT]), { value: READY });
      return;
    }

    createWorkspaceUser(
      axiosInstance,
      workspaceId,
      newWorkspaceUser,
      (res) => {
        const workspaceUsers = get(workspaceUsersAtom);

        if (workspaceUsers) {
          const newUsers = [...workspaceUsers, res.data];

          set(workspaceUsersAtom, newUsers);
        }

        set(setStateAtom([MANAGER_RIGHT]), { value: READY });
        set(setSnackbarAtom, {
          title: 'User added',
          body: 'Workspace user was added!',
          type: SUCCESS,
        });
      },
      (error) => {
        set(setStateAtom([MANAGER_RIGHT]), { value: READY });
        set(setSnackbarAtom, {
          title: 'Error',
          body: error.response?.data.message,
          type: ERROR,
        });
      }
    );
  }
);

export const deleteWorkspaceUserAtom = atom(
  null,
  (get, set, userId: number) => {
    set(setStateAtom([MANAGER_RIGHT]), { value: LOADING });

    const workspaceId = get(selectedWorkspaceAtom)?.id;

    if (!workspaceId) {
      set(setStateAtom([MANAGER_RIGHT]), { value: READY });
      return;
    }

    deleteWorkspaceUser(
      axiosInstance,
      workspaceId,
      userId,
      () => {
        const newWorkspaceUsers =
          get(workspaceUsersAtom)?.filter((user) => user.userId !== userId) ||
          [];

        set(workspaceUsersAtom, newWorkspaceUsers);

        set(setStateAtom([MANAGER_RIGHT]), { value: READY });
        set(setSnackbarAtom, {
          title: 'User deleted',
          body: 'Workspace user was deleted',
          type: SUCCESS,
        });
      },
      (error) => {
        set(setStateAtom([MANAGER_RIGHT]), { value: READY });
        set(setSnackbarAtom, {
          title: 'Error',
          body: error.response?.data.message,
          type: ERROR,
        });
      }
    );
  }
);

export const updateWorkspaceUserAtom = atom(
  null,
  (get, set, { userId, newRole, successCallback }: UpdateWorkspaceUser) => {
    set(setStateAtom([MANAGER_RIGHT]), { value: LOADING });

    const workspaceId = get(selectedWorkspaceAtom)?.id;

    if (!workspaceId) {
      set(setStateAtom([MANAGER_RIGHT]), { value: READY });
      return;
    }

    updateWorkspaceUser(
      axiosInstance,
      workspaceId,
      userId,
      newRole,
      (res) => {
        const unchangedUsers =
          get(workspaceUsersAtom)?.filter((user) => user.userId !== userId) ||
          [];

        const newWorkspaceUsers = [...unchangedUsers, res.data];

        set(workspaceUsersAtom, newWorkspaceUsers);

        set(setStateAtom([MANAGER_RIGHT]), { value: READY });
        set(setSnackbarAtom, {
          title: 'Workspace user changed',
          body: 'Workspace user was changed',
          type: SUCCESS,
        });

        successCallback();
      },
      (error) => {
        set(setStateAtom([MANAGER_RIGHT]), { value: READY });
        set(setSnackbarAtom, {
          title: 'Error',
          body: error.response?.data.message,
          type: ERROR,
        });
      }
    );
  }
);

export const deleteWorkspaceAtom = atom(null, (get, set) => {
  set(setStateAtom([MANAGER_RIGHT, MANAGER_CENTER]), {
    value: LOADING,
  });

  const workspaceId = get(selectedWorkspaceAtom)?.id;

  if (!workspaceId) {
    set(setStateAtom([MANAGER_RIGHT, MANAGER_CENTER]), {
      value: READY,
    });
    return;
  }

  deleteWorkspace(
    axiosInstance,
    workspaceId,
    () => {
      const newWorkspaces =
        get(workspacesAtom)?.filter(
          (workspace) => workspace.id !== workspaceId
        ) || [];

      set(workspacesAtom, newWorkspaces);
      set(selectedWorkspaceAtom, null);

      set(setStateAtom([MANAGER_RIGHT, MANAGER_CENTER]), {
        value: READY,
      });
      set(setSnackbarAtom, {
        title: 'Workspace deleted',
        body: 'Workspace was deleted!',
        type: SUCCESS,
      });
    },
    (error) => {
      set(setStateAtom([MANAGER_RIGHT, MANAGER_CENTER]), {
        value: READY,
      });
      set(setSnackbarAtom, {
        title: 'Error',
        body: error.response?.data.message,
        type: ERROR,
      });
    }
  );
});

export const updateWorkspaceAtom = atom(null, (get, set, name: string) => {
  set(setStateAtom([MANAGER_RIGHT, MANAGER_CENTER]), {
    value: LOADING,
  });

  const workspaceId = get(selectedWorkspaceAtom)?.id;

  if (!workspaceId) {
    set(setStateAtom([MANAGER_RIGHT, MANAGER_CENTER]), {
      value: READY,
    });
    return;
  }

  updateWorkspace(
    axiosInstance,
    workspaceId,
    name,
    (res) => {
      const currentSelectedWorkspace = get(selectedWorkspaceAtom);

      if (currentSelectedWorkspace) {
        const newSelectedWorkspace = {
          ...currentSelectedWorkspace,
          name: res.data.name,
        };

        set(selectedWorkspaceAtom, newSelectedWorkspace);

        const unchangedWorkspaces =
          get(workspacesAtom)?.filter(
            (workspace) => workspace.id !== workspaceId
          ) || [];

        const newWorkspaces = [...unchangedWorkspaces, newSelectedWorkspace];

        set(workspacesAtom, newWorkspaces);
      }

      set(setStateAtom([MANAGER_RIGHT, MANAGER_CENTER]), {
        value: READY,
      });
      set(setSnackbarAtom, {
        title: 'Workspace name updated',
        body: 'Workspace name was updated!',
        type: SUCCESS,
      });
    },
    (error) => {
      set(setStateAtom([MANAGER_RIGHT, MANAGER_CENTER]), {
        value: READY,
      });
      set(setSnackbarAtom, {
        title: 'Error',
        body: error.response?.data.message,
        type: ERROR,
      });
    }
  );
});

export const workspacesForDropdownAtom = atom<Option[]>((get) => {
  const workspaces = get(workspacesAtom) || [];

  return workspaces.map((workspace) => ({
    label: workspace.name,
    value: workspace.id,
  }));
});

export const fillSelectedWorkspaceUserAtom = atom(
  null,
  (get, set, user: WorkspaceUserResponse | undefined) => {
    set(selectedWorkspaceUserAtom, user);
  }
);

export const closeWorkspaceAsideAtom = atom(null, (get, set) => {
  set(selectedWorkspaceAtom, null);
  set(selectedWorkspaceUserAtom, undefined);
});

export const closeWorkspaceFormTabsAtom = atom(null, (get, set) => {
  const editProjectData = get(isEditWorkspaceDataAtom);
  const editUserRole = get(selectedWorkspaceUserAtom);
  const addUserMode = get(isAddWorkspaceUserModeAtom);

  if (editProjectData) {
    set(isEditWorkspaceDataAtom, (prev) => !prev);
  }

  if (editUserRole !== undefined) {
    set(selectedWorkspaceUserAtom, undefined);
  }

  if (addUserMode) {
    set(isAddWorkspaceUserModeAtom, (prev) => !prev);
  }
});

export const getSelectedWorkspaceLicenseAtom = atom(null, async (get, set) => {
  const selectedWorkspaceId = get(selectedWorkspaceIdAtom) as number;
  set(workspaceLicenseAtom, null);

  try {
    const response = await getWorkspaceActiveLicence(
      axiosInstance,
      selectedWorkspaceId
    );

    set(workspaceLicenseAtom, response.data);
  } catch (error) {
    console.error(error);
  }
});

export const getWorkspaceUsersWithoutProjectAtom = atom(
  null,
  async (get, set, projectId: number) => {
    set(setStateAtom([MANAGER_RIGHT]), {
      value: LOADING,
    });
    set(workspaceUsersWithoutProjectsAtom, []);

    const workspaceId = get(selectedWorkspaceIdAtom) as number;

    try {
      const response = await getWorkspaceUsersWithoutProject(
        axiosInstance,
        workspaceId,
        projectId
      );

      const emails = response.data.map(({ user }) => ({
        label: user.email,
        value: user.email,
      }));

      set(workspaceUsersWithoutProjectsAtom, emails);
      set(setStateAtom([MANAGER_RIGHT]), {
        value: READY,
      });
    } catch (error: any) {
      set(setSnackbarAtom, {
        title: 'Error',
        body: error.response?.data.message,
        type: ERROR,
      });
      set(setStateAtom([MANAGER_RIGHT]), {
        value: READY,
      });
    }
  }
);
