import { CherryGLVersion } from '@metavrse-inc/metavrse-lib';
import { atom } from 'jotai';
import { atomFamily, RESET } from 'jotai/utils';
import JSZip from 'jszip';

import { PROJECTS_FETCH_LIMIT } from 'configConstants';

import { LoadingStatus, StateTypes } from 'models';
import {
  CreateProject,
  DeleteProjectUser,
  GetProjectsOrder,
  NewProjectUser,
  ProjectResponse,
  ProjectTypeOptions,
  TableView,
  UpdateProject,
  UpdateProjectUser,
} from 'models/manager/project.model';
import { ProjectUserResponse } from 'models/manager/project-user.model';
import { ProjectUserRole } from 'models/projectMetadata';
import { ProjectsSort } from 'models/projectsSort';
import SnackbarType from 'models/snackbars/snackbarType';

import { axiosInstance } from 'services/axios';
import {
  createProject,
  deleteProject,
  duplicateProject,
  getProject,
  getProjects,
  importProject,
  updateProject,
} from 'services/projects.service';
import { getProjectHeroShot } from 'services/projects-heroshot.service';
import {
  createProjectUser,
  deleteProjectUser,
  getProjectUsers,
  getUserProjectRole,
  updateProjectUser,
} from 'services/projects-users.service';

import { userDataAtom } from 'atoms/manager/users';
import {
  selectedWorkspaceIdAtom,
  workspaceIdAtom,
} from 'atoms/manager/workspaces.atom';
import { setSnackbarAtom } from 'atoms/snackbars/snackbars.atom';
import { getStateByScopeAtom, setStateAtom } from 'atoms/state';

import noImagePlaceholder from 'assets/images/no-image.png';

import { NoHeroShotError } from 'errors/heroshots.error';

export const tableViewAtom = atom<TableView>('LIST');
export const projectTypeFilterAtom = atom<ProjectTypeOptions>('PROJECTS');

export const errorAtom = atom('');
export const isEditProjectDataAtom = atom<boolean>(false);
export const isAddProjectUserModeAtom = atom<boolean>(false);
export const projectAtom = atom<null | ProjectResponse>(null);
export const projectsAtom = atom<ProjectResponse[]>([]);
export const projectUsersAtom = atom<null | ProjectUserResponse[]>(null);
export const sortProjectsAtom = atom<ProjectsSort>({
  field: 'name',
  direction: 'asc',
});
export const areAllProjectsFetchedAtom = atom(false);
export const offsetAtom = atom(0);
export const selectedProjectUserAtom = atom<undefined | ProjectUserResponse>(
  undefined
);
export const userRoleAtom = atom<ProjectUserRole | undefined>(undefined);

const __heroShots = atom<Record<number, string>>({});
export const managerHeroShotsAtomFamily = atomFamily(
  (id: number | undefined) =>
    atom(
      (get) =>
        (typeof id === 'number' && get(__heroShots)[id]) || noImagePlaceholder,
      (get, set, encodedImage: string | typeof RESET) => {
        if (typeof id !== 'number') return;

        if (encodedImage === RESET) {
          set(__heroShots, (prev) => {
            const { [id]: _, ...rest } = prev;

            return rest;
          });
        } else {
          set(__heroShots, (prev) => ({ ...prev, [id]: encodedImage }));
        }
      }
    ),
  (a, b) => a === b
);

const { MANAGER_RIGHT, MANAGER_CENTER, IMPORT } = StateTypes;
const { LOADING, READY } = LoadingStatus;
const { SUCCESS, ERROR } = SnackbarType;

export const getProjectAtom = atom(null, async (get, set, id: number) => {
  set(setStateAtom([MANAGER_RIGHT]), { value: LOADING });

  try {
    const res = await getProject(axiosInstance, id);
    set(projectAtom, res.data);
    set(setStateAtom([MANAGER_RIGHT]), { value: READY });
  } catch (error: any) {
    set(errorAtom, error.message);
    set(setStateAtom([MANAGER_RIGHT]), { value: READY });
  }
});

export const getUserProjectRoleAtom = atom(
  null,
  async (get, set, projectId: number) => {
    const userId = get(userDataAtom)?.user.id;

    if (!userId) {
      return;
    }

    try {
      const response = await getUserProjectRole(
        axiosInstance,
        projectId,
        userId
      );
      set(userRoleAtom, response.data.role);
    } catch (error: any) {
      set(setSnackbarAtom, {
        title: 'Error',
        body: error.message,
        type: ERROR,
      });
    }
  }
);

export const createProjectAtom = atom(
  null,
  async (
    get,
    set,
    { values, successCallback, errorCallback }: CreateProject
  ) => {
    set(setStateAtom([MANAGER_CENTER, MANAGER_RIGHT]), {
      value: LOADING,
    });

    const workspaceId = get(selectedWorkspaceIdAtom);

    if (!workspaceId) {
      set(setStateAtom([MANAGER_CENTER, MANAGER_RIGHT]), {
        value: READY,
      });
      return;
    }

    const newProject = {
      ...values,
      workspaceId: workspaceId,
      version: CherryGLVersion,
    };

    try {
      const response = await createProject(axiosInstance, newProject);
      if (response?.data) {
        successCallback(response.data.id);
        set(setStateAtom([MANAGER_CENTER, MANAGER_RIGHT]), {
          value: READY,
        });
      }
    } catch (error: any) {
      if (error.response.status === 422) {
        errorCallback(error.response?.data?.message);
      } else {
        set(setSnackbarAtom, {
          title: 'Error',
          body: error.response?.data?.message,
          type: ERROR,
        });
      }
      set(setStateAtom([MANAGER_CENTER, MANAGER_RIGHT]), {
        value: READY,
      });
    }
  }
);

export const getProjectsAtom = atom(
  null,
  async (get, set, { sort, limit, offset }: GetProjectsOrder) => {
    set(setStateAtom([MANAGER_CENTER]), { value: LOADING });
    const _offset = offset || get(offsetAtom);

    const workspaceId = get(selectedWorkspaceIdAtom);

    if (!workspaceId) {
      set(setStateAtom([MANAGER_CENTER]), { value: READY });
      return;
    }

    try {
      const response = await getProjects(
        axiosInstance,
        sort,
        limit,
        _offset,
        workspaceId
      );
      const currentProjects = get(projectsAtom) || [];
      const fetchedProjects = response.data;
      const areAllProjectsFetched =
        fetchedProjects.length < PROJECTS_FETCH_LIMIT;

      fetchedProjects.forEach(async ({ id }) => {
        try {
          const encodedImage = await getProjectHeroShot(id);
          set(managerHeroShotsAtomFamily(id), encodedImage);
        } catch (err) {
          if (!(err instanceof NoHeroShotError)) {
            console.error(err);
          } else {
            set(managerHeroShotsAtomFamily(id), RESET);
          }
        }
      });

      set(areAllProjectsFetchedAtom, areAllProjectsFetched);
      set(projectsAtom, [...currentProjects, ...fetchedProjects]);
    } catch (error: any) {
      set(errorAtom, error.response?.data.message);
    } finally {
      set(setStateAtom([MANAGER_CENTER]), { value: READY });
    }
  }
);

export const duplicateProjectAtom = atom(
  null,
  (get, set, projectId: number) => {
    set(setStateAtom([MANAGER_CENTER]), { value: LOADING });

    duplicateProject(
      axiosInstance,
      projectId,
      (res) => {
        set(projectAtom, res.data);

        const oldProjects = get(projectsAtom);
        const newProjects = [res.data, ...(oldProjects || [])];

        set(projectsAtom, newProjects);
        set(setStateAtom([MANAGER_CENTER]), { value: READY });
        set(setSnackbarAtom, {
          title: 'Project copied',
          body: 'Project copied successfully',
          type: SUCCESS,
        });
      },
      (error) => {
        set(setSnackbarAtom, {
          title: 'Error',
          body: error.response?.data.message,
          type: ERROR,
        });
        set(setStateAtom([MANAGER_CENTER]), { value: READY });
      }
    );
  }
);

export const deleteProjectAtom = atom(null, (get, set, projectId: number) => {
  set(setStateAtom([MANAGER_CENTER]), { value: LOADING });

  deleteProject(
    axiosInstance,
    projectId,
    () => {
      set(projectAtom, null);

      const oldProjects = get(projectsAtom);
      const newProjects =
        oldProjects?.filter((project) => project.id !== projectId) || [];

      set(projectsAtom, newProjects);
      set(setStateAtom([MANAGER_CENTER]), { value: READY });
      set(setSnackbarAtom, {
        title: 'Project deleted',
        body: 'Project deleted successfully',
        type: SUCCESS,
      });
    },
    (error) => {
      set(setSnackbarAtom, {
        title: 'Error',
        body: error.response?.data.message,
        type: ERROR,
      });
      set(setStateAtom([MANAGER_CENTER]), { value: READY });
    }
  );
});

export const changeProjectsSortAtom = atom(
  null,
  (get, set, data: ProjectsSort) => {
    set(sortProjectsAtom, data);
  }
);

export const updateProjectAtom = atom(
  null,
  (get, set, { projectId, data }: UpdateProject) => {
    set(setStateAtom([MANAGER_CENTER, MANAGER_RIGHT]), {
      value: LOADING,
    });

    updateProject(
      axiosInstance,
      projectId,
      data,
      (res) => {
        const oldProjects = get(projectsAtom) || [];

        set(
          projectsAtom,
          oldProjects.map((p) => (p.id === projectId ? res.data : p))
        );
        set(projectAtom, res.data);
        set(setStateAtom([MANAGER_CENTER, MANAGER_RIGHT]), {
          value: READY,
        });
        set(setSnackbarAtom, {
          title: 'Project data updated',
          body: 'Project data updated successfully',
          type: SUCCESS,
        });
      },
      (error) => {
        set(setSnackbarAtom, {
          title: 'Error',
          body: error.response?.data.message,
          type: ERROR,
        });
        set(setStateAtom([MANAGER_CENTER, MANAGER_RIGHT]), {
          value: READY,
        });
      }
    );
  }
);

export const getProjectUsersAtom = atom(null, (get, set, projectId: number) => {
  set(setStateAtom([MANAGER_RIGHT]), { value: LOADING });
  set(errorAtom, '');

  getProjectUsers(
    axiosInstance,
    projectId,
    (res) => {
      set(projectUsersAtom, res.data);
      set(setStateAtom([MANAGER_RIGHT]), { value: READY });
    },
    (error) => {
      set(errorAtom, error.response?.data.message);
      set(setStateAtom([MANAGER_RIGHT]), { value: READY });
    }
  );
});

export const createProjectUserAtom = atom(
  null,
  (get, set, { projectId, newUser, onSuccessCallback }: NewProjectUser) => {
    set(setStateAtom([MANAGER_RIGHT]), { value: LOADING });

    createProjectUser(
      axiosInstance,
      projectId,
      newUser,
      (res) => {
        const users = get(projectUsersAtom) || [];
        const newUsers = [...users, res.data];

        set(projectUsersAtom, newUsers);
        set(setSnackbarAtom, {
          title: 'User added',
          body: 'New user was added to project',
          type: SUCCESS,
        });

        onSuccessCallback();
        set(setStateAtom([MANAGER_RIGHT]), { value: READY });
      },
      (error) => {
        set(setSnackbarAtom, {
          title: 'Error',
          body: error.response?.data.message,
          type: ERROR,
        });
        set(setStateAtom([MANAGER_RIGHT]), { value: READY });
      }
    );
  }
);

export const deleteProjectUserAtom = atom(
  null,
  (get, set, { projectId, userId }: DeleteProjectUser) => {
    set(setStateAtom([MANAGER_RIGHT]), { value: LOADING });

    deleteProjectUser(
      axiosInstance,
      projectId,
      userId,
      () => {
        const users = get(projectUsersAtom) || [];
        const notDeletedUsers = users.filter(({ user }) => user.id !== userId);

        set(projectUsersAtom, notDeletedUsers);
        set(setStateAtom([MANAGER_RIGHT]), { value: READY });
        set(setSnackbarAtom, {
          title: 'User was deleted',
          body: 'User deleted successfully',
          type: SUCCESS,
        });
      },
      (error) => {
        set(setSnackbarAtom, {
          title: 'Error',
          body: error.response?.data.message,
          type: ERROR,
        });
        set(setStateAtom([MANAGER_RIGHT]), { value: READY });
      }
    );
  }
);

export const updateProjectUserAtom = atom(
  null,
  (
    get,
    set,
    { projectId, userId, updatedUser, successCallback }: UpdateProjectUser
  ) => {
    set(setStateAtom([MANAGER_RIGHT]), { value: LOADING });

    updateProjectUser(
      axiosInstance,
      projectId,
      updatedUser,
      userId,
      (res) => {
        const unchangedUsers =
          get(projectUsersAtom)?.filter(({ user }) => user.id !== userId) || [];

        const newUsers = [...unchangedUsers, res.data];

        set(projectUsersAtom, newUsers);
        successCallback();
        set(setStateAtom([MANAGER_RIGHT]), { value: READY });
        set(setSnackbarAtom, {
          title: 'User role updated',
          body: 'User role updated successfully',
          type: SUCCESS,
        });
      },
      (error) => {
        set(setSnackbarAtom, {
          title: 'Error',
          body: error.response?.data.message,
          type: ERROR,
        });
        set(setStateAtom([MANAGER_RIGHT]), { value: READY });
      }
    );
  }
);

export const fillSelectedProjectUserAtom = atom(
  null,
  (get, set, user: ProjectUserResponse | undefined) => {
    set(selectedProjectUserAtom, user);
  }
);

export const closeProjectsAsideAtom = atom(null, (get, set) => {
  set(projectAtom, null);
  set(selectedProjectUserAtom, undefined);
});

export const closeProjectFormTabsAtom = atom(null, (get, set) => {
  const editProjectData = get(isEditProjectDataAtom);
  const editUserRole = get(selectedProjectUserAtom);
  const addUserMode = get(isAddProjectUserModeAtom);

  if (editProjectData) {
    set(isEditProjectDataAtom, (prev) => !prev);
  }

  if (editUserRole !== undefined) {
    set(selectedProjectUserAtom, undefined);
  }

  if (addUserMode) {
    set(isAddProjectUserModeAtom, (prev) => !prev);
  }
});

export const clearProjectsAtom = atom(null, (get, set) => {
  set(projectsAtom, []);
  set(areAllProjectsFetchedAtom, false);
  set(offsetAtom, 0);
});

export const refetchProjectsAtom = atom(null, (get, set) => {
  const currentOffset = get(offsetAtom);
  const areAllProjectsFetched = get(areAllProjectsFetchedAtom);
  const isLoading = get(getStateByScopeAtom(MANAGER_CENTER));
  const sortBy = get(sortProjectsAtom);

  if (!areAllProjectsFetched && !isLoading) {
    set(setStateAtom([MANAGER_CENTER]), { value: LOADING });
    set(offsetAtom, currentOffset + PROJECTS_FETCH_LIMIT);

    set(getProjectsAtom, {
      sort: sortBy,
      limit: PROJECTS_FETCH_LIMIT,
      offset: get(offsetAtom),
    });
  }
});

export const importProjectProgressAtom = atom<number>(0);

export const importProjectAtom = atom(null, async (get, set, update: File) => {
  const file = update;
  if (
    (file.type === 'application/zip' && file.name.includes('zip')) ||
    file.name.includes('c3s')
  ) {
    const zip = await JSZip.loadAsync(file);
    if (Object.keys(zip.files).some((file) => file === 'project.json')) {
      try {
        set(setStateAtom([IMPORT]), {
          value: LOADING,
        });
        const workspaceId = get(workspaceIdAtom);
        if (workspaceId) {
          const response = await importProject(
            axiosInstance,
            workspaceId,
            file,
            (progress) => {
              set(
                importProjectProgressAtom,
                (prev) => (progress.loaded / progress.total) * 100
              );
            }
          );

          set(projectsAtom, (projects) => [...projects, response.data]);
        }

        set(setStateAtom([IMPORT]), {
          value: READY,
        });
      } catch (error: any) {
        set(setSnackbarAtom, {
          title: 'Import Project Error',
          body: error.response.data.message,
          type: ERROR,
        });
        set(setStateAtom([IMPORT]), {
          value: READY,
        });
      }
    } else {
      set(setSnackbarAtom, {
        title: 'Import error',
        body: 'Zip file does not contains project.json file!',
        type: ERROR,
      });
    }
  } else {
    set(setSnackbarAtom, {
      title: 'Import error',
      body: 'Only zip file are allowed!',
      type: ERROR,
    });
  }
});
