import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

import { OrganizationRoles } from 'models/organization';

import { IUserResponse } from 'services/users';

const PATH = 'organizations';

export interface IOrganizationSuperadminResponse {
  id: number;
  organizationId: number;
  userId: number;
  role: 'SUPERADMIN';
  createdAt: string;
  updatedAt: string;
  email: string;
  user: IUserResponse;
}

export const getOrganizationUsers = (
  axios: AxiosInstance,
  organizationId: number,
  responseHandler: (
    response: AxiosResponse<IOrganizationSuperadminResponse[]>
  ) => void,
  errorHandler: (error: AxiosError) => void
): void => {
  axios
    .get(`${PATH}/${organizationId}/superadmins`)
    .then(responseHandler)
    .catch(errorHandler);
};

export const deleteOrganizationUser = (
  axios: AxiosInstance,
  organizationId: number,
  superadminId: number,
  responseHandler: (
    response: AxiosResponse<IOrganizationSuperadminResponse>
  ) => void,
  errorHandler: (error: AxiosError) => void
): void => {
  axios
    .delete(`${PATH}/${organizationId}/superadmins/${superadminId}`)
    .then(responseHandler)
    .catch(errorHandler);
};

export interface INewUserDataRequest {
  email: string;
  role: OrganizationRoles;
}

export const createOrganizationUser = (
  axios: AxiosInstance,
  organizationId: number,
  newAdminData: INewUserDataRequest,
  responseHandler: (
    response: AxiosResponse<IOrganizationSuperadminResponse>
  ) => void,
  errorHandler: (error: AxiosError) => void
): void => {
  axios
    .post(`${PATH}/${organizationId}/superadmins`, newAdminData)
    .then(responseHandler)
    .catch(errorHandler);
};
