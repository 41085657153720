// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Label-module__root--Ool8d {\n  font-size: var(--labelFontSize);\n  font-weight: 400;\n  letter-spacing: 0.4px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/Label/Label.module.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,gBAAgB;EAChB,qBAAqB;AACvB","sourcesContent":[".root {\n  font-size: var(--labelFontSize);\n  font-weight: 400;\n  letter-spacing: 0.4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Label-module__root--Ool8d"
};
export default ___CSS_LOADER_EXPORT___;
