import { atom, WritableAtom } from 'jotai';

import { LoadingStatus, StateTypes } from 'models';
import SnackbarType from 'models/snackbars/snackbarType';
import { AuthenticationStatus } from 'models/user/AuthenticationStatus.enum';
import { SignedInUserDataResponse } from 'models/user/signedInUserDataResponse.model';
import { TotpUserSignIn } from 'models/user/totpUserSignIn.model';
import { UserSignIn } from 'models/user/userSignIn.model';

import { axiosInstance } from 'services/axios';
import {
  activateAccount,
  currentUserData,
  deleteAccount,
  initiatePasswordReset,
  IResetPasswordRequest,
  ISignUpUserRequest,
  IUpdateUserRequest,
  resetPasswordReset,
  signIn,
  signOut,
  signUp,
  totpSignIn,
  updateUser,
} from 'services/users';
import { getWorkspaceLimitsUsage } from 'services/workspaces.service';

import { isAuthenticatedAtom, signInAtom, signOutAtom } from 'atoms/auth';
import { getLimitsWithErrorMessages } from 'atoms/helpers/manager/workspaceLimits-helpers';
import { setSnackbarAtom } from 'atoms/snackbars/snackbars.atom';
import { setStateAtom } from 'atoms/state';

import { organizationsDetailsAtom } from './organization';
import {
  getWorkspacesAtom,
  selectedWorkspaceIdAtom,
  workspaceIdAtom,
  workspacesAtom,
} from './workspaces.atom';

export const userNameKey = 'userName';

const { SUCCESS, ERROR, WARNING } = SnackbarType;
const { LOADING, READY } = LoadingStatus;

export const userDataAtom = atom<SignedInUserDataResponse | null>(null);
export const userJwtTokenAtom: WritableAtom<string | null, string | null> =
  atom(null, (_get, set, newToken: string | null) =>
    set(userJwtTokenAtom, newToken)
  );

const { AUTH_FORM, USER, MANAGER_CENTER } = StateTypes;

export const getUserDataAtom = atom(null, (get, set) => {
  set(setStateAtom([USER]), { value: LOADING });
  currentUserData(
    axiosInstance,
    (res) => {
      set(signInAtom);
      set(userDataAtom, res.data);
      set(setStateAtom([USER]), { value: READY });
    },
    (error) => {
      console.log(error);
      set(signOutAtom);
      set(userJwtTokenAtom, null);
      set(userDataAtom, null);
      set(setStateAtom([USER]), { value: READY });
    }
  );
});

export const userSignInAtom = atom(
  null,
  async (get, set, { values, onSuccessCallback }: UserSignIn) => {
    set(setStateAtom([AUTH_FORM]), { value: LOADING });

    const handleLogin = async () => {
      try {
        const response = await signIn(axiosInstance, values);
        if (response.status === 201 && response.data.token) {
          set(userJwtTokenAtom, response.data.token);
        }
        set(setStateAtom([AUTH_FORM]), { value: READY });

        onSuccessCallback();
      } catch (error) {
        set(setSnackbarAtom, {
          title: 'Invalid email or password',
          body: 'Please check if provided credentials are correct',
          type: ERROR,
        });
        set(setStateAtom([AUTH_FORM]), { value: READY });
        throw error;
      }
    };

    handleLogin();
  }
);

export const userTotpSignInAtom = atom(
  null,
  async (get, set, { values, onSuccessCallback }: TotpUserSignIn) => {
    set(setStateAtom([AUTH_FORM]), { value: LOADING });

    const handleLogin = async () => {
      try {
        const userJwtToken = get(userJwtTokenAtom);
        const response = await totpSignIn(axiosInstance, values, userJwtToken);
        set(userDataAtom, response.data);
        await set(getWorkspacesAtom);
        set(signInAtom);
        localStorage.setItem(userNameKey, response.data.user.name);
        set(setStateAtom([AUTH_FORM]), { value: READY });

        onSuccessCallback();
      } catch (error) {
        if (error.response.status === 403) {
          set(setSnackbarAtom, {
            title: 'Lack of authorization',
            body: 'Please start the two-factor authentication process from the beginning',
            type: ERROR,
          });
        } else {
          set(setSnackbarAtom, {
            title: 'Invalid verification code',
            body: 'Please check if the verification code from your email was entered correctly',
            type: ERROR,
          });
        }
        set(setStateAtom([AUTH_FORM]), { value: READY });
        throw error;
      }
    };

    // TODO: Remove this after workspaces limits summary endpoint will be ready
    const handleLimits = async () => {
      const workspaces = get(workspacesAtom) ?? [];
      set(workspaceIdAtom, workspaces[0].id);

      const requestList =
        workspaces?.map(async (workspace) => {
          try {
            const response = await getWorkspaceLimitsUsage(
              axiosInstance,
              workspace.id
            );

            return getLimitsWithErrorMessages(response.data);
          } catch (error: any) {
            console.log(error);
          }
        }) ?? [];

      const filteredResponses = (await Promise.all(requestList)).filter(
        (response) => response && response?.length > 0
      );
      if (filteredResponses.length > 0) {
        set(setSnackbarAtom, {
          title: 'Limits Warning!',
          body: 'You are close to exceed limits in one of your workspaces. \nRemember to update your plan!',
          type: WARNING,
        });
      }
    };

    handleLogin().then(handleLimits).catch();
  }
);

interface IUserSignUp {
  values: ISignUpUserRequest;
  successCallback: () => void;
}

export const userSignUpAtom = atom(
  null,
  (get, set, { values, successCallback }: IUserSignUp) => {
    set(setStateAtom([AUTH_FORM]), { value: LOADING });

    signUp(
      axiosInstance,
      values,
      () => {
        set(setStateAtom([AUTH_FORM]), { value: READY });
        set(setSnackbarAtom, {
          title: 'Account creation',
          body: 'Account created successfully',
          type: SnackbarType.SUCCESS,
        });
        successCallback();
      },
      (error) => {
        set(setSnackbarAtom, {
          title: 'Error',
          body: error.response?.data.message || 'There was an error',
          type: ERROR,
        });
        set(setStateAtom([AUTH_FORM]), { value: READY });
      }
    );
  }
);

export const userSignOutAtom = atom(null, (get, set) => {
  set(setStateAtom([AUTH_FORM]), { value: LOADING });

  signOut(
    axiosInstance,
    () => {
      set(userDataAtom, null);
      set(userJwtTokenAtom, null);
      set(organizationsDetailsAtom, null);
      set(selectedWorkspaceIdAtom, null);
      localStorage.removeItem(userNameKey);
      set(setStateAtom([AUTH_FORM]), { value: READY });
    },
    (error) => {
      set(setSnackbarAtom, {
        title: 'Error',
        body: error.response?.data.message || 'There was an error',
        type: ERROR,
      });
      set(setStateAtom([AUTH_FORM]), { value: READY });
    }
  );
});

export const deleteUserAtom = atom(
  null,
  async (get, set, onSuccessCallback: () => void) => {
    set(setStateAtom([MANAGER_CENTER]), { value: LOADING });

    try {
      const result = await deleteAccount(axiosInstance);
      if (result.status === 204) {
        set(setStateAtom([MANAGER_CENTER]), { value: READY });
        onSuccessCallback();
      }
    } catch (error: any) {
      set(setSnackbarAtom, {
        title: 'Delete account error',
        body: error.response.data.message,
        type: ERROR,
      });
      set(setStateAtom([MANAGER_CENTER]), { value: READY });
    }
  }
);

export const userUpdateAtom = atom(
  null,
  (get, set, data: IUpdateUserRequest) => {
    set(setStateAtom([MANAGER_CENTER]), { value: LOADING });
    if (get(isAuthenticatedAtom) === AuthenticationStatus.NOT_AUTHENTICATED) {
      set(setSnackbarAtom, {
        title: 'Error',
        body: 'User not logged in',
        type: ERROR,
      });

      return;
    }
    const userData = get(userDataAtom) as SignedInUserDataResponse;
    const { user } = userData;
    updateUser(
      axiosInstance,
      userData.user.id,
      data,
      (res) => {
        const updatedUser = res.data;
        set(userDataAtom, {
          ...userData,
          user: {
            ...user,
            ...updatedUser,
          },
        });
        set(setSnackbarAtom, {
          title: 'User data updated',
          body: 'Successfully updated user data',
          type: SUCCESS,
        });
        set(setStateAtom([MANAGER_CENTER]), { value: READY });
      },
      (error) => {
        set(setSnackbarAtom, {
          title: 'Error',
          body: error.response?.data?.message,
          type: ERROR,
        });
        set(setStateAtom([MANAGER_CENTER]), { value: READY });
      }
    );
  }
);

interface IUserRestPassword {
  values: IResetPasswordRequest;
  successCallback: () => void;
}

export const resetPasswordAtom = atom(
  null,
  async (_, set, { values, successCallback }: IUserRestPassword) => {
    set(setStateAtom([MANAGER_CENTER]), { value: LOADING });

    try {
      const result = await resetPasswordReset(axiosInstance, values);
      if (result.status === 201) {
        set(setStateAtom([MANAGER_CENTER]), { value: READY });
        set(setSnackbarAtom, {
          title: 'Password reset',
          body: 'Reset password successfully',
          type: SnackbarType.SUCCESS,
        });
        successCallback();
      }
    } catch (error: any) {
      set(setSnackbarAtom, {
        title: 'Reset password error',
        body: error.response.data.message,
        type: ERROR,
      });
      set(setStateAtom([MANAGER_CENTER]), { value: READY });
    }
  }
);

export const isUserActivateAtom = atom<null | boolean>(null);

export const activateUserAtom = atom(
  null,
  async (get, set, activationToken: string) => {
    set(setStateAtom([MANAGER_CENTER]), { value: LOADING });

    try {
      const result = await activateAccount(axiosInstance, { activationToken });
      if (result.status === 201) {
        set(isUserActivateAtom, true);
        set(setStateAtom([MANAGER_CENTER]), { value: READY });
      }
    } catch (error: any) {
      set(isUserActivateAtom, false);
      set(setSnackbarAtom, {
        title: 'Account activation error',
        body: error.response.data.message,
        type: ERROR,
      });
      set(setStateAtom([MANAGER_CENTER]), { value: READY });
    }
  }
);

export const initiatePasswordResetAtom = atom(
  null,
  async (get, set, email: string) => {
    set(setStateAtom([MANAGER_CENTER]), { value: LOADING });

    try {
      const result = await initiatePasswordReset(axiosInstance, { email });
      if (result.status === 201) {
        set(setStateAtom([MANAGER_CENTER]), { value: READY });
        set(setSnackbarAtom, {
          title: 'Email was sent',
          body: 'Check your email box',
          type: SUCCESS,
        });
      }
    } catch (error: any) {
      set(setSnackbarAtom, {
        title: 'Cannot initiate reset password',
        body: error.response.data.message,
        type: ERROR,
      });
      set(setStateAtom([MANAGER_CENTER]), { value: READY });
    }
  }
);
