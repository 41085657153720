import { CherryKey } from '@metavrse-inc/metavrse-lib';

import {
  ENTITIES_FILENAME,
  HTML_HUD_TREE_FILENAME,
  SCENES_FOLDER,
  TREE_FILENAME,
  WORLD_FILENAME,
} from 'configConstants';

import { fsp } from 'services/fs.service';

export const createSceneFolder = async (
  key: CherryKey
): Promise<void | null> => {
  try {
    await fsp.mkdir(`/${SCENES_FOLDER}/${key}`);
  } catch (error) {
    console.log('ERROR createSceneFolder: ', error);
    return null;
  }
};

export const removeSceneFiles = async (key: string): Promise<void> => {
  try {
    await fsp.unlink(`/${SCENES_FOLDER}/${key}/${WORLD_FILENAME}`);
    await fsp.unlink(`/${SCENES_FOLDER}/${key}/${TREE_FILENAME}`);
    await fsp.unlink(`/${SCENES_FOLDER}/${key}/${ENTITIES_FILENAME}`);
    await fsp.unlink(`/${SCENES_FOLDER}/${key}/${HTML_HUD_TREE_FILENAME}`);
    await fsp.rmdir(`/${SCENES_FOLDER}/${key}`);
  } catch (error) {
    console.log('ERROR removeSceneFiles', error);
  }
};
