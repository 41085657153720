import {
  Asset,
  AssetType,
  CherryKey,
  CherryViewer,
  NODE_TYPES,
  TreeNodeType,
} from '@metavrse-inc/metavrse-lib';
import produce from 'immer';

import copyString from 'utils/copyString';

import {
  AR_TOGGLE_OFF_CONTROLLER_NAME,
  AR_TOGGLE_ON_CONTROLLER_NAME,
  AR_WORLD_CONTROLLER_NAME,
  ASSETS_FOLDER,
  BASE_PATH,
  BLANK_CSS_NAME,
  MEGA_CONTROLLER_NAME,
  PROJECT_MANAGER_PATH,
  SCENE_SELECT_CONTROLLER_NAME,
  WORLD_CONTROLLER_NAME,
} from 'configConstants';

import { PrimitiveType } from 'models/asset';
import { uploadableAssetTypes } from 'models/editor/assets/assetsTypes/uploadableAssetTypes';
import { ITargetPath } from 'models/entity/common';
import { AssetTemplateName } from 'models/scripts';

import { createAssetFile, getFileBuffer } from 'services/fileSystem/assets';
import { fsp } from 'services/fs.service';

import { getIncrementalId } from 'stores/editor';

import { FlattenedAsset } from 'atoms/assets/searchAssets.atom';

import arToggleOffController from 'temp/scripts/ARToggleOffController';
import arToggleOnController from 'temp/scripts/ARToggleOnController';
import arWorldController from 'temp/scripts/ARWorldController';
import megaController from 'temp/scripts/MegaController';
import sceneSelectController from 'temp/scripts/SceneSelectController';
import worldController from 'temp/scripts/WorldController';

export type AssetIntrinsics = {
  originalHeight?: number;
  originalWidth: number;
  currentHeight?: number;
  currentWidth?: number;
};

const getAssetTemplateFileContent = (name: AssetTemplateName): string => {
  switch (name) {
    case WORLD_CONTROLLER_NAME:
      return worldController;
    case AR_TOGGLE_OFF_CONTROLLER_NAME:
      return arToggleOffController;
    case MEGA_CONTROLLER_NAME:
      return megaController;
    case AR_WORLD_CONTROLLER_NAME:
      return arWorldController;
    case AR_TOGGLE_ON_CONTROLLER_NAME:
      return arToggleOnController;
    case SCENE_SELECT_CONTROLLER_NAME:
      return sceneSelectController;
    case BLANK_CSS_NAME:
    default:
      return 'body {}';
  }
};

const getPrimitives = produce<Asset[]>((draft) => {
  const primitivesIndex = 0;
  return [...draft[primitivesIndex].children];
});

const flattenNodes = (node: FlattenedAsset): FlattenedAsset[] => {
  if (node.children?.length > 0) {
    const children = node.children as FlattenedAsset[];
    return [node, ...children.flatMap(flattenNodes)];
  }

  return [node];
};

const appendIndexesTo = (
  nodes: FlattenedAsset[],
  indexes: number[]
): FlattenedAsset[] =>
  nodes.map((node, index) => {
    const newNode = {
      ...node,
      indexes: [...indexes, index],
    };

    if (node.children?.length > 0) {
      newNode.children = appendIndexesTo(
        node.children as FlattenedAsset[],
        newNode.indexes
      );
    }

    return newNode;
  });

type KeyValuePairs = {
  key: CherryKey;
  title: string;
};

const getKeyValuePairsFrom = (
  tree: Asset[],
  targetPath: ITargetPath
): KeyValuePairs[] => {
  const values: KeyValuePairs[] = [];
  produce(tree, (draft) => {
    if (!targetPath.length) {
      return [];
    }

    let result = draft;
    for (let i = 0; i < targetPath.length; i++) {
      const index = targetPath[i];
      const element = result[index];

      if (element) {
        values.push({
          key: element.key,
          title: element.title,
        });

        result = [...element.children] || [];
      }
    }
  });

  return values;
};

const getChildrenFrom = produce<Asset[], [ITargetPath]>((draft, targetPath) => {
  if (!draft.length) {
    return draft;
  }

  let result: Asset[] = draft;

  for (let i = 0; i < targetPath.length; i++) {
    try {
      const index = targetPath[i];
      const element = result[index];
      result = element?.children || [];
    } catch (error) {
      console.error(error);
    }
  }

  return result;
});

const flattenAssets = (
  assets: Asset[]
): { key: string; title: string; type: AssetType }[] =>
  assets.flatMap(({ key, type, title, children }) =>
    type !== NODE_TYPES.folder ? { key, title, type } : flattenAssets(children)
  );

const getFileAssetType = (file: File) => {
  const { name, type } = file;
  const extensionRegex = /(?:\.([^.]+))?$/;
  const extension = extensionRegex.exec(name)[1];
  return name.includes(extension)
    ? (uploadableAssetTypes[extension] as AssetType)
    : type.includes('image')
    ? 'image'
    : NODE_TYPES.folder;
};

const createAssetsFiles = async (
  files: File[],
  createFSFile: (key: CherryKey, file: Uint8Array) => void,
  isLevelOfDetail?: boolean,
  intrinsics?: AssetIntrinsics
): Promise<Asset[]> => {
  const paths = files.map(({ name, type }) => ({ name, type }));
  const assets: Asset[] = [];
  const level: any = { result: assets };

  paths.forEach((path) => {
    path.name.split('/').reduce((acc, name) => {
      if (!acc[name]) {
        const key = getIncrementalId();
        const extensionRegex = /(?:\.([^.]+))?$/;
        const extension = extensionRegex.exec(name)[1];
        acc[name] = { result: [] };
        let newAsset: Asset = {
          key,
          type: name.includes(extension)
            ? (uploadableAssetTypes[extension] as AssetType)
            : path.type.includes('image')
            ? 'image'
            : NODE_TYPES.folder,
          title: name,
          children: acc[name].result,
        };

        if (isLevelOfDetail && intrinsics) {
          newAsset = { ...newAsset, intrinsics: intrinsics };
        }

        acc.result.push(newAsset);
      }
      return acc[name];
    }, level);
  });

  const flattenedAssets = flattenAssets(assets);

  await Promise.all(
    files.map(async (file) =>
      Promise.all(
        flattenedAssets.map(async ({ key, title }) => {
          if (file.name.includes(title)) {
            // TODO: MET-2586: Correct checking file type
            let type =
              uploadableAssetTypes[title.slice(title.lastIndexOf('.') + 1)];
            if (isLevelOfDetail) {
              type = 'image';
            }
            const fsFile = await createAssetFile(key, file, type);
            if (fsFile) {
              createFSFile(key, fsFile);
            }
          }
        })
      )
    )
  );

  return assets;
};

const titleNameFrom = (
  primitiveType: PrimitiveType | undefined,
  type: TreeNodeType | AssetType
): string => {
  return primitiveType ? `${primitiveType.toLowerCase()}.c3b` : `New ${type}`;
};

const getPrimitiveKey = (primitives: Asset[], title: string): string => {
  const foundPrimitive = primitives.find((p) => p.title === title);
  if (!foundPrimitive) {
    return '-1';
  }

  return foundPrimitive.key;
};

const convertIntArrayToAssetFile = async (
  assetKey: string,
  assetType: AssetType,
  assetTitle: string
): Promise<File | undefined> => {
  const assetFileAsUintArray = await getFileBuffer(
    `/${ASSETS_FOLDER}/${assetKey}`
  );

  if (assetFileAsUintArray) {
    const assetFileAsBlob = new Blob([assetFileAsUintArray], {
      type: `text/${assetType}`,
    });

    const assetFile = new File(
      [assetFileAsBlob],
      copyString(assetTitle, true),
      {
        type: `text/${assetType}`,
      }
    );

    return assetFile;
  }
};

const createNewAssets = async (
  viewer: CherryViewer,
  files: File[],
  isLevelOfDetail?: boolean,
  intrinsics?: AssetIntrinsics
): Promise<Asset[]> => {
  const createFSFile = (key: CherryKey, file: Uint8Array) => {
    viewer.FS.writeFile(`${viewer.ProjectManager.path}${key}`, file);
    viewer.ProjectManager.addAsset(key, { key });
  };
  const newAssets = await createAssetsFiles(
    files,
    createFSFile,
    isLevelOfDetail,
    intrinsics
  );

  return newAssets;
};

const checkIfFilesSizeBetweenGitRepositoryAndViewerAreSame = async (
  viewer: CherryViewer,
  folder: string,
  key: string
): Promise<boolean> => {
  const { size: repositoryFileSize } = await fsp.stat(
    `${BASE_PATH}${folder}/${key}`
  );

  const { size: localFileSize } = viewer.FS.stat(PROJECT_MANAGER_PATH + key);

  if (repositoryFileSize !== localFileSize) {
    // console.log('FileSize', folder, key, repositoryFileSize, localFileSize);
  }

  return repositoryFileSize === localFileSize;
};

export default {
  flattenAssets,
  getKeyValuePairsFrom,
  getChildrenFrom,
  flattenNodes,
  appendIndexesTo,
  getPrimitives,
  getAssetTemplateFileContent,
  createAssetsFiles,
  titleNameFrom,
  getPrimitiveKey,
  convertIntArrayToAssetFile,
  createNewAssets,
  checkIfFilesSizeBetweenGitRepositoryAndViewerAreSame,
  getFileAssetType,
};
