import { CSSProperties, FC, MouseEvent, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { classMerge } from 'utils/classMerge';

import Spinner from 'common/Spinner/Spinner';

import classes from './Button.module.css';

type Props = {
  variant: 'primary' | 'secondary' | 'danger' | 'outlined' | 'accent' | 'text';
  size?: 'small' | 'medium' | 'large' | 'small-auto' | 'medium-auto' | 'large-auto';
  children: ReactNode | ReactNode[];
  to?: string;
  type?: 'button' | 'submit';
  disabled?: boolean;
  isLoading?: boolean;
  onClick?: (e?: MouseEvent<HTMLButtonElement>) => void;
};

const Button: FC<Props> = ({
  variant,
  children,
  onClick,
  to,
  size = 'large',
  type = 'button',
  disabled,
  isLoading,
  ...rest
}) => {
  const buttonClasses = `${classMerge(classes[variant], classes[size], {
    [classes.disabled]: disabled,
  })}`;

  if (to) {
    return (
      <Link
        to={to}
        className={classMerge({
          [classes.disabled]: disabled,
        })}
      >
        <button
          className={buttonClasses}
          onClick={onClick}
          type={type}
          {...rest}
        >
          {children}
        </button>
      </Link>
    );
  }

  return (
    <button
      className={buttonClasses}
      onClick={onClick}
      disabled={disabled}
      type={type}
      {...rest}
    >
      {isLoading ? <Spinner size="normal" color="white" /> : children}
    </button>
  );
};

export default Button;
