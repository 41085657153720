export const BASE_PATH = '/';

export const THUMBNAILS_FOLDER = 'thumbnails';
export const ASSETS_FOLDER = 'files';
export const SCENES_FOLDER = 'scenes';
export const PROJECT_FOLDER = 'project';
export const PROJECT_MANAGER_PATH = `/${PROJECT_FOLDER}/${ASSETS_FOLDER}/`;

export const PROJECT_FILENAME = 'project.json';
export const ASSETS_FILENAME = 'assets.json';
export const TREE_FILENAME = 'tree.json';
export const HTML_HUD_TREE_FILENAME = `hud-tree.json`;
export const ENTITIES_FILENAME = 'entities.json';
export const WORLD_FILENAME = 'world.json';
export const CONFIGURATIONS_FILENAME = 'configurations.json';

export const FULL_DATE_AND_TIME = 'yyyy-MM-dd, hh:mm a';
export const PROJECTS_FETCH_LIMIT = 20;

export const WORLD_CONTROLLER_NAME = 'WorldController.js';
export const AR_TOGGLE_OFF_CONTROLLER_NAME = 'ArToggleOffController.js';
export const MEGA_CONTROLLER_NAME = 'MegaController.js';
export const AR_WORLD_CONTROLLER_NAME = 'ArWorldController.js';
export const AR_TOGGLE_ON_CONTROLLER_NAME = 'ArToggleOnController.js';
export const SCENE_SELECT_CONTROLLER_NAME = 'SceneSelectController.js';
export const BLANK_CSS_NAME = 'BlankStylesheet.css';

export const SELECTED_WORKSPACE_ID = 'selectedWorkspaceId';
export const AUTOSAVE_TOGGLE = 'autosaveToggle';
export const EDITOR_ZOOM = 'editorZoom';

export const DEFAULT_AUTOSAVE_INTERVAL_IN_MS = 5 * 60 * 1000;
export const AUTOSAVE_SHIFT_IN_SECONDS = 20;

export const IMAGE_WORKER_PATH = './imageFactoryWorker.js';
export const CHERRY_IMAGE_WORKER_PATH = './CherryWW.js';
export const PUSH_WORKER_PATH = './pushWorker.js';

export const DEFAULT_DROPDOWN_VALUE = '';
export const TO_FIXED = 2;

export const SNACKBAR_FAILED_PROGRESS = -1;

export const GIT_OPERATIONS_UNIQUE_OBJECTS = 5;

export const ASSET_TYPES_ALWAYS_GIT_ADD = ['javascript', 'stylesheet'];
