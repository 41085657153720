import { IconComponent } from 'models/icons';
import SnackbarType from 'models/snackbars/snackbarType';

import { IconError, IconInfo, IconSuccess, IconWarning } from 'icons';

const SNACKBAR_ICONS = {
  INFO: IconInfo,
  ERROR: IconError,
  WARNING: IconWarning,
  SUCCESS: IconSuccess,
};

const getSnackbarIconBy = (type: SnackbarType): IconComponent =>
  SNACKBAR_ICONS[type];

export default getSnackbarIconBy;
