import { SnackbarMessage } from 'models/snackbars/snackbarMessage';
import { SnackbarProgress } from 'models/snackbars/snackbarProgress.model';

export const removeFirstElementFrom = (
  snackbarsQueue: SnackbarMessage[]
): [SnackbarMessage, SnackbarMessage[]] => {
  const firstElementInQueue = snackbarsQueue[0];

  const filteredQueue = snackbarsQueue.filter(
    (snackbars) => snackbars.key !== firstElementInQueue.key
  );

  return [firstElementInQueue, filteredQueue];
};

export const setNewSnackbarProgress = (
  snackbarProgress: SnackbarProgress[],
  progress: number,
  snackbarToUpdate: SnackbarMessage
): SnackbarProgress[] =>
  snackbarProgress.map((snackbar) => {
    const { key, progressValue } = snackbar;
    const newProgressValue =
      key === snackbarToUpdate.key ? progress : progressValue;

    return { ...snackbar, progressValue: newProgressValue };
  });
