import { TreeNode } from '@metavrse-inc/metavrse-lib';

import { BASE_PATH, SCENES_FOLDER, TREE_FILENAME } from 'configConstants';

import { fsp } from 'services/fs.service';
import { convertToJSON } from 'services/helpers';

export const readTreeFile = async (key: string): Promise<TreeNode[] | null> => {
  try {
    const data = await fsp.readFile(
      `${BASE_PATH}${SCENES_FOLDER}/${key}/${TREE_FILENAME}`
    );

    if (typeof data === 'string') {
      return null;
    }

    return convertToJSON<TreeNode[]>(data);
  } catch (error) {
    console.log('ERROR readTreeFile: ', error);
    return null;
  }
};

export const writeTreeFile = async (
  key: string,
  data: TreeNode[]
): Promise<void | null> => {
  try {
    const files = await fsp.readdir(`${BASE_PATH}${SCENES_FOLDER}`);
    const sceneFolderExists = files.some((f) => f === key);
    if (!sceneFolderExists) {
      await fsp.mkdir(`${BASE_PATH}${SCENES_FOLDER}/${key}/`);
    }
    await fsp.writeFile(
      `${BASE_PATH}${SCENES_FOLDER}/${key}/${TREE_FILENAME}`,
      new TextEncoder().encode(JSON.stringify(data))
    );
  } catch (error) {
    console.log('ERROR writeTreeFile: ', error);
    return null;
  }
};
