export enum ShortcutName {
  SAVE_PROJECT = 'SAVE_PROJECT',
  SAVE_CODE_EDITOR_CONTENT = 'SAVE_CODE_EDITOR_CONTENT',
  SHOW_SCALE = 'SHOW_SCALE',
  SCALE_ONE_AXIS = 'SCALE_ONE_AXIS',
  ZOOM_DEFAULT = 'ZOOM_DEFAULT',
  ZOOM_INCREASE = 'ZOOM_INCREASE',
  ZOOM_DECREASE = 'ZOOM_DECREASE',
  ZOOM_TO_OBJECT = 'ZOOM_TO_OBJECT',
  COPY = 'COPY',
  PASTE = 'PASTE',
  ADD_FROM_SCENE_TO_CONFIG = 'ADD_FROM_SCENE_TO_CONFIG',
}
