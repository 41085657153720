// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SnackbarsWrapper-module__root--dUJnJ {\n  position: absolute;\n  bottom: var(--spacing4);\n  right: 0;\n  overflow-x: hidden;\n  z-index: var(--zIndexSnackbar);\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/SnackbarsWrapper/SnackbarsWrapper.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,uBAAuB;EACvB,QAAQ;EACR,kBAAkB;EAClB,8BAA8B;AAChC","sourcesContent":[".root {\n  position: absolute;\n  bottom: var(--spacing4);\n  right: 0;\n  overflow-x: hidden;\n  z-index: var(--zIndexSnackbar);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "SnackbarsWrapper-module__root--dUJnJ"
};
export default ___CSS_LOADER_EXPORT___;
