// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InputCharacterCounter-module__root--AOIEF {\n  position: absolute;\n  top: 50%;\n  right: var(--spacing6);\n  display: flex;\n  transform: translateY(-50%);\n  pointer-events: none;\n}\n\n.InputCharacterCounter-module__lengthColor--LsyXu {\n  color: rgba(255, 255, 255, 0.35);\n}\n\n.InputCharacterCounter-module__maxLengthColor--NPGs8 {\n  color: rgba(255, 255, 255, 0.7);\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/InputCharacterCounter/InputCharacterCounter.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,QAAQ;EACR,sBAAsB;EACtB,aAAa;EACb,2BAA2B;EAC3B,oBAAoB;AACtB;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,+BAA+B;AACjC","sourcesContent":[".root {\n  position: absolute;\n  top: 50%;\n  right: var(--spacing6);\n  display: flex;\n  transform: translateY(-50%);\n  pointer-events: none;\n}\n\n.lengthColor {\n  color: rgba(255, 255, 255, 0.35);\n}\n\n.maxLengthColor {\n  color: rgba(255, 255, 255, 0.7);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "InputCharacterCounter-module__root--AOIEF",
	"lengthColor": "InputCharacterCounter-module__lengthColor--LsyXu",
	"maxLengthColor": "InputCharacterCounter-module__maxLengthColor--NPGs8"
};
export default ___CSS_LOADER_EXPORT___;
