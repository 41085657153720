import { useCallback, useEffect } from 'react';
import { useAtomValue } from 'jotai/utils';

import { shortcutListAtom } from 'hooks/shortcutManager/atoms/shortcuts.atom';
import {
  shouldTriggerDownShortcut,
  shouldTriggerUpShortcut,
} from 'hooks/shortcutManager/utils/shouldTriggerShortcut.utils';

export const useShortcutListener = (): void => {
  const shortcutsList = useAtomValue(shortcutListAtom);

  const keyDownEventListener = useCallback(
    (event: KeyboardEvent) => {
      const activeElement = document.activeElement as any;
      const inputs = ['input', 'textarea'];
      if (
        activeElement &&
        (inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1 || activeElement.isContentEditable) &&
        !(event.key === 's' && (event.metaKey || event.ctrlKey))
      ) {
        return;
      }

      const { ctrlKey, metaKey, key } = event;
      if (
        (ctrlKey === true &&
          (key == '0' || key === '+' || key === '=' || key === '-')) ||
        (metaKey === true &&
          (key == '0' || key === '+' || key === '=' || key === '-'))
      ) {
        event.preventDefault();
      }
      shortcutsList.forEach((shortcut) => {
        if (shouldTriggerDownShortcut(event, shortcut)) {
          shortcut.keyDownCb(event);
        }
      });
    },
    [shortcutsList]
  );

  const keyUpEventListener = useCallback(
    (event: KeyboardEvent) => {
      const activeElement = document.activeElement as any;
      const inputs = ['input', 'textarea'];

      if (
        activeElement &&
        (inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1 || activeElement.isContentEditable)
      ) {
        return;
      }

      shortcutsList.forEach((shortcut) => {
        if (shouldTriggerUpShortcut(event, shortcut)) {
          shortcut.keyUpCb && shortcut.keyUpCb(event);
        }
      });
    },
    [shortcutsList]
  );

  useEffect(() => {
    window.addEventListener('keydown', keyDownEventListener);
    window.addEventListener('keyup', keyUpEventListener);

    return () => {
      window.removeEventListener('keydown', keyDownEventListener);
      window.removeEventListener('keyup', keyUpEventListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shortcutsList]);
};
