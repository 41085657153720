import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

import {
  NewProjectUserRequest,
  ProjectUserResponse,
  UpdateProjectUserRequest,
} from 'models/manager/project-user.model';

const PATH = 'projects';

export const getProjectUsers = (
  axios: AxiosInstance,
  projectId: number,
  responseHandler: (response: AxiosResponse<ProjectUserResponse[]>) => void,
  errorHandler: (error: AxiosError) => void
): void => {
  axios
    .get(`${PATH}/${projectId}/users`)
    .then(responseHandler)
    .catch(errorHandler);
};

export const createProjectUser = (
  axios: AxiosInstance,
  projectId: number,
  newUser: NewProjectUserRequest,
  responseHandler: (response: AxiosResponse<ProjectUserResponse>) => void,
  errorHandler: (error: AxiosError) => void
): void => {
  axios
    .post(`${PATH}/${projectId}/users`, newUser)
    .then(responseHandler)
    .catch(errorHandler);
};

export const deleteProjectUser = (
  axios: AxiosInstance,
  projectId: number,
  userId: number,
  responseHandler: (response: AxiosResponse<ProjectUserResponse>) => void,
  errorHandler: (error: AxiosError) => void
): void => {
  axios
    .delete(`${PATH}/${projectId}/users/${userId}`)
    .then(responseHandler)
    .catch(errorHandler);
};

export const updateProjectUser = (
  axios: AxiosInstance,
  projectId: number,
  updatedUser: UpdateProjectUserRequest,
  userId: number,
  responseHandler: (response: AxiosResponse<ProjectUserResponse>) => void,
  errorHandler: (error: AxiosError) => void
): void => {
  axios
    .patch(`${PATH}/${projectId}/users/${userId}`, updatedUser)
    .then(responseHandler)
    .catch(errorHandler);
};

export const getUserProjectRole = async (
  axios: AxiosInstance,
  projectId: number,
  userId: number
): Promise<AxiosResponse<ProjectUserResponse>> => {
  return await axios.get(`${PATH}/${projectId}/users/${userId}`);
};
