import { FC } from 'react';

import { IconMetavrseLogo } from 'icons';

const Logo: FC = () => {
  return (
    <IconMetavrseLogo
      svgElementProps={{
        style: {
          width: 'var(--logoWidth)',
          height: 'var(--logoHeight)',
        },
      }}
    />
  );
};

export default Logo;
