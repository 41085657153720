import { KeyboardEvent } from 'react';

const clearInputValue = (
  handleClearInputValue: () => void,
  evt: KeyboardEvent<HTMLInputElement>
): void => {
  if (evt.key === 'Escape') {
    handleClearInputValue();
  }
};

export default clearInputValue;
