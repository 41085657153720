import { Entities } from '@metavrse-inc/metavrse-lib';
import { atom, Getter, Setter } from 'jotai';

import { entitiesAtom } from './entities.atom';

type Arguments = [Getter, Setter, Entities];

const setMethod = (...args: Arguments): void => {
  const [get, set, update] = args;
  set(entitiesAtom, update);
};

export const fillEntitiesAtom = atom(null, setMethod);
