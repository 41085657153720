import { FC } from 'react';
import { useAtomValue } from 'jotai';

import { modalDataAtom } from 'atoms/modal';

import classes from './Modal.module.css';

const Modal: FC = () => {
  const modalData = useAtomValue(modalDataAtom);

  if (!modalData) {
    return null;
  }

  return (
    <div className={classes.root}>
      <div className={classes.overlay} />
      <div className={classes.wrapper}>
        <div className={classes.content}>{modalData.content}</div>
      </div>
    </div>
  );
};

export default Modal;
