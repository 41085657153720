import { TreeNodeType } from '@metavrse-inc/metavrse-lib';

import { deepClone } from 'utils/deepCopy';

// TODO: [MET-1665] Add immer here
// eslint-disable-next-line @typescript-eslint/ban-types
type ChildType<T extends Record<string, unknown> = {}> = T & {
  title: string;
  children?: ChildType[];
  uiHighlighted?: boolean;
  uiVisible?: boolean;
  visible?: boolean;
  type: TreeNodeType;
  isParentHighlighted?: boolean;
};

type DeepSearch = <S extends ChildType = ChildType>(
  nodes: S[]
) => [S[], boolean];

export const searchTreeForActive = <T extends ChildType>(tree: T[]): T[] => {
  let filteredTreeData = [];

  const filterTree = (array: T[]): T[] => {
    const deepSearch: DeepSearch = (nodes) => {
      let found = false;
      const result = [];

      for (const node of nodes) {
        if (node.visible && node.type === 'configuration') {
          if (node.children.length) {
            const [nodes] = deepSearch(node.children);
            found = true;

            const newValue = {
              ...node,
              uiVisible: true,
              uiHighlighted: true,
              children: nodes,
            };

            result.push(newValue);
          } else {
            found = true;

            const newValue = {
              ...node,
              uiVisible: true,
              uiHighlighted: true,
            };

            result.push(newValue);
          }
        } else {
          if (node.children.length) {
            const [nodes, show] = deepSearch(node.children);
            found = show;
            const isAnyChildVisible = nodes.some((node) => node.uiVisible);
            const newValue = {
              ...node,
              uiVisible: isAnyChildVisible,
              children: nodes,
            };

            result.push(newValue);
          } else {
            const newValue = {
              ...node,
              uiVisible: false,
              uiHighlighted: false,
            };

            result.push(newValue);
          }
        }
      }
      return [result, found];
    };

    const [data] = deepSearch(array);

    return data;
  };

  filteredTreeData = filterTree(deepClone(tree));
  return filteredTreeData;
};

export const searchTree = <T extends ChildType>(
  tree: T[],
  filterText: string
): T[] => {
  let filteredTreeData = [];
  if (filterText) {
    const filterTree = (array: T[], text: string): T[] => {
      const regexName = new RegExp(text, 'gi');

      const deepSearch: DeepSearch = (nodes) => {
        let found = false;
        const result = [];

        for (const node of nodes) {
          if (node.title.match(regexName)) {
            if (node.children?.length) {
              const children = node.children.map((child) => ({
                ...child,
                isParentHighlighted: true,
              }));

              let [nodes] = deepSearch(children);
              found = true;
              nodes = nodes.map((node) => {
                return {
                  ...node,
                  uiVisible: true,
                  isParentHighlighted: true,
                };
              });

              const newValue = {
                ...node,
                uiVisible: true,
                uiHighlighted: true,
                children: nodes,
              };

              result.push(newValue);
            } else {
              found = true;

              const newValue = {
                ...node,
                uiVisible: true,
                uiHighlighted: true,
              };

              result.push(newValue);
            }
          } else {
            if (node.children?.length) {
              let children = node.children;
              if (node.isParentHighlighted) {
                children = node.children.map((child) => ({
                  ...child,
                  isParentHighlighted: true,
                }));
              }

              const [nodes, show] = deepSearch(children);
              found = show;
              const isAnyChildUiHighlighted = nodes.some(
                (node) => node.uiHighlighted
              );
              const isAnyChildVisible = nodes.some((node) => node.uiVisible);
              const shouldShow =
                isAnyChildUiHighlighted ||
                isAnyChildVisible ||
                show ||
                node.isParentHighlighted;

              const newValue = {
                ...node,
                uiVisible: shouldShow ?? false,
                children: nodes,
              };

              result.push(newValue);
            } else {
              const newValue = {
                ...node,
                uiVisible: node.isParentHighlighted ?? false,
                uiHighlighted: false,
              };

              result.push(newValue);
            }
          }
        }

        return [result, found];
      };

      const [data] = deepSearch(array);

      return data;
    };

    filteredTreeData = filterTree(deepClone(tree), filterText);
  } else {
    filteredTreeData = tree;
  }

  return filteredTreeData;
};
