// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PageNotFound-module__root--MnHCy {\n  --titleFontSize: 90px;\n\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n}\n\n.PageNotFound-module__code--a2kIt {\n  font-size: var(--titleFontSize);\n}\n\n.PageNotFound-module__message--LTB9k {\n  opacity: 0.7;\n  margin-bottom: var(--spacing4);\n}\n", "",{"version":3,"sources":["webpack://./src/components/_manager/PageNotFound/PageNotFound.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;;EAErB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,YAAY;EACZ,8BAA8B;AAChC","sourcesContent":[".root {\n  --titleFontSize: 90px;\n\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n}\n\n.code {\n  font-size: var(--titleFontSize);\n}\n\n.message {\n  opacity: 0.7;\n  margin-bottom: var(--spacing4);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "PageNotFound-module__root--MnHCy",
	"code": "PageNotFound-module__code--a2kIt",
	"message": "PageNotFound-module__message--LTB9k"
};
export default ___CSS_LOADER_EXPORT___;
