import FS from '@isomorphic-git/lightning-fs';
import { AxiosError } from 'axios';
import { isBefore } from 'date-fns';
import git from 'isomorphic-git';

import http from 'utils/isomorphic-git/isomorphic-git-http';

import {
  ASSETS_FOLDER,
  GIT_OPERATIONS_UNIQUE_OBJECTS,
  THUMBNAILS_FOLDER,
} from 'configConstants';

import { fs } from 'services/fs.service';
import { getProjectHistory } from 'services/projects.service';

import { axiosInstance } from './axios';

const BASE_PATH = '/';
const REPO_URL = `${process.env.API_BASE_URL}/projects/`;
const BRANCH = 'master';

export const listAllFilesAndDirectories = async (): Promise<string[]> =>
  git.walk({
    fs,
    dir: BASE_PATH,
    trees: [git.STAGE()],
    map: async (filepath: string): Promise<any> => {
      return filepath;
    },
  });

export const assetsFileNamesReducer = (
  acc: string[],
  path: string
): string[] => {
  if (path.includes(`${ASSETS_FOLDER}/`)) {
    const assetKey = path.split(`${ASSETS_FOLDER}/`)[1];
    acc.push(assetKey);
  }

  return acc;
};

export const thumbnailsFileNamesReducer = (
  acc: string[],
  path: string
): string[] => {
  if (path.includes(`${THUMBNAILS_FOLDER}/`)) {
    const thumbnailKey = path.split(`${THUMBNAILS_FOLDER}/`)[1];
    acc.push(thumbnailKey);
  }

  return acc;
};

export const cloneRepository = async (
  fs: FS,
  projectId: number,
  username: string,
  startCountingProgressValue: number,
  updateProgress: (progress: number, content: string) => void,
  onError: (error: any) => void
): Promise<void> => {
  let cloneProgress = startCountingProgressValue;

  try {
    await git.clone({
      fs,
      http,
      dir: BASE_PATH,
      url: `${REPO_URL}${projectId}/repo`,
      ref: BRANCH,
      singleBranch: true,
      depth: 1,
      onProgress: (evt) => {
        if (evt.total) {
          cloneProgress +=
            evt.loaded / evt.total / (GIT_OPERATIONS_UNIQUE_OBJECTS * 10);

          updateProgress(cloneProgress, evt.phase);
        }
      },
    });
    await git.setConfig({
      fs,
      dir: BASE_PATH,
      path: 'user.name',
      value: username,
    });
  } catch (error) {
    console.log('ERROR initRepository: ', error);
    onError(error);
  }
};

export const needUpdate = async (
  fs: FS,
  projectId: number
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    getProjectHistory(
      axiosInstance,
      projectId,
      async (response) => {
        const gitLog = await git.log({ fs, dir: BASE_PATH });
        const [firstRemoteCommitId] = response.data;
        const [firstLocalCommitId] = gitLog;

        if (firstLocalCommitId.oid !== firstRemoteCommitId.oid) {
          const localCommitterTimestamp =
            firstLocalCommitId.commit.committer.timestamp;
          const remoteCommitterTimestamp =
            firstRemoteCommitId.commit.committer.timestamp;

          const isAhead = isBefore(
            localCommitterTimestamp,
            remoteCommitterTimestamp
          );

          if (isAhead) {
            resolve(true);
          }
        } else {
          resolve(false);
        }
      },
      (error: AxiosError) => {
        reject(error);
      }
    );
  });
};

export const pullChanges = async (
  fs: FS,
  projectId: number,
  startCountingProgressValue: number,
  updateProgress: (progress: number, content: string) => void,
  onError: (error: any) => void
): Promise<void> => {
  let pullProgress = startCountingProgressValue;

  try {
    await git.pull({
      fs,
      http,
      dir: BASE_PATH,
      url: `${REPO_URL}${projectId}/repo`,
      ref: BRANCH,
      singleBranch: true,
      onProgress: (evt) => {
        if (evt.total) {
          pullProgress +=
            evt.loaded / evt.total / (GIT_OPERATIONS_UNIQUE_OBJECTS * 10);

          updateProgress(pullProgress, evt.phase);
        }
      },
    });
  } catch (error) {
    console.log('ERROR pullChanges: ', error);
    onError(error);
  }
};

export const addFile = async (filePath: string): Promise<void | null> => {
  try {
    await git.add({
      fs,
      dir: BASE_PATH,
      filepath: filePath,
    });
  } catch (error) {
    console.log('ERROR addFiles: ', error);
    return null;
  }
};

export const removeFile = async (filePath: string): Promise<void | null> => {
  try {
    await git.remove({
      fs,
      dir: BASE_PATH,
      filepath: filePath,
    });
  } catch (error) {
    console.log('ERROR removeFiles: ', error);
    return null;
  }
};
