import { useAtomDevtools } from 'jotai/devtools';

import { assetsAtom } from 'atoms/assets/assets.atom';
import { areHintsVisibleAtom } from 'atoms/editor/hints';
import { generateProjectDataAtom, projectAtom } from 'atoms/editor/project';
import { scenesAtom } from 'atoms/editor/scenes';
import { worldAtom } from 'atoms/editor/world';
import { entitiesAtom, entitiesCopyAtom } from 'atoms/entities/entities.atom';
import {
  objectGroupsAtom,
  objectMaterialsAtom,
  objectMeshesAtom,
  selectedMeshesAtom,
} from 'atoms/inspector/meshes.atom';
import { treeAtom, treeCopyAtom } from 'atoms/tree/tree.atom';

import { configurationsTreeAtom } from '_configurations/atoms/configurations.atom';

export const useDebugger = (): void => {
  useAtomDevtools(projectAtom, { name: 'project.json' });
  useAtomDevtools(treeAtom, { name: 'tree.json' });
  useAtomDevtools(assetsAtom, { name: 'assets.json' });
  useAtomDevtools(entitiesAtom, { name: 'entities.json' });
  useAtomDevtools(worldAtom, { name: 'world.json' });
  useAtomDevtools(scenesAtom, { name: 'scenes' });
  useAtomDevtools(generateProjectDataAtom, { name: 'projectData' });
  useAtomDevtools(areHintsVisibleAtom, { name: 'hints' });
  useAtomDevtools(treeCopyAtom, { name: 'treeCopy' });
  useAtomDevtools(entitiesCopyAtom, { name: 'entitiesCopy' });
  useAtomDevtools(selectedMeshesAtom, { name: 'selectedMeshes' });
  useAtomDevtools(objectMeshesAtom, { name: 'objectMeshes' });
  useAtomDevtools(objectMaterialsAtom, { name: 'objectMaterials' });
  useAtomDevtools(objectGroupsAtom, { name: 'objectGroups' });
  useAtomDevtools(configurationsTreeAtom, { name: 'configurationsTree' });
};
