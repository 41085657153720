import { FC } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Form, Formik } from 'formik';
import { useUpdateAtom } from 'jotai/utils';

import { resetPasswordAtom } from 'atoms/manager/users';

import Button from 'common/Button/Button';
import FormInput from 'common/FormInput/FormInput';
import Subtitle2 from 'common/Subtitle2/Subtitle2';

import { resetPasswordSchema } from 'validations/schemas/resetPasswordSchema';

import AuthBox from '_account/AuthBox/AuthBox';

import classes from './ResetPassword.module.css';

const ResetPassword: FC = () => {
  const resetPassword = useUpdateAtom(resetPasswordAtom);
  const history = useHistory();
  const { search } = useLocation();

  const passwordResetToken = search.slice(
    search.indexOf('=') + 1,
    search.length
  );

  const handleSendResetPasswordEmail = (values: { password: string }) => {
    const successCallback = () => {
      setTimeout(() => {
        history.push('/account');
      }, 2000);
    };

    resetPassword({
      values: {
        newPassword: values.password,
        passwordResetToken,
      },
      successCallback,
    });
  };

  return (
    <AuthBox heading="Reset password">
      <div className={classes.wrapper}>
        <div className={classes.heading}>
          <Subtitle2>Provide new password</Subtitle2>
        </div>
        <Formik
          initialValues={{
            password: '',
            passwordConfirmation: '',
          }}
          validationSchema={resetPasswordSchema}
          validateOnMount={false}
          onSubmit={handleSendResetPasswordEmail}
        >
          {({ handleSubmit }) => (
            <Form className={classes.form} onSubmit={handleSubmit}>
              <FormInput name="password" label="Password" type="password" />
              <FormInput
                name="passwordConfirmation"
                label="Repeat password"
                type="password"
              />
              <div className={classes.actions}>
                <Button variant="primary" type="submit">
                  Send
                </Button>
                <Button variant="secondary" to="/account" type="button">
                  Back to Login
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </AuthBox>
  );
};

export default ResetPassword;
