import {
  CherryKey,
  ConfigurationNode,
  Entity,
} from '@metavrse-inc/metavrse-lib';
import { atom } from 'jotai';
import { atomWithReset, RESET } from 'jotai/utils';

import { ITargetPath } from 'models/entity/common';

import { entitiesAtom } from 'atoms/entities/entities.atom';
import {
  searchTree,
  searchTreeForActive,
} from 'atoms/helpers/common/searchTree';
import commonHelpers from 'atoms/helpers/common-helpers';

type ConfigMode = {
  key: CherryKey | null;
};

export const configurationKeyAtom = atomWithReset<CherryKey | null>(null);
export const configurationsFlattenAtom = atomWithReset<ConfigurationNode[]>([]);
export const configurationsTreeAtom = atomWithReset<ConfigurationNode[]>([]);
export const configurationsSearchTermAtom = atomWithReset('');
export const configModeAtom = atomWithReset<ConfigMode>({ key: null });
export const shouldAddFromSceneToConfigAtom = atom(false);

export const configurationsTreeCopyPathAtom = atomWithReset<ITargetPath>([]);

export const selectedConfigurationEntityAtom = atom<
  Entity | null,
  CherryKey | typeof RESET
>(
  (get) => {
    const entities = get(entitiesAtom);
    const key = get(configurationKeyAtom);

    return key ? entities[key] : null;
  },
  (get, set, key) => {
    set(configurationKeyAtom, key);
  }
);

export const activeConfigAtom = atom((get) => {
  const configurations = get(configurationsTreeAtom);
  const configMode = get(configModeEntityAtom);
  return configMode.key
    ? commonHelpers.getNodeByKey(configMode.key, configurations)
    : null;
});

export const configModeEntityAtom = atom<ConfigMode, ConfigMode>(
  (get) => {
    const configMode = get(configModeAtom);
    return configMode;
  },
  (_get, set, config) => {
    set(configModeAtom, config);
  }
);

type CreateGroupPayload = { newNode: any; targetPath?: ITargetPath };
/**
 * This atom is used to update the `configurationsGroupAtom` with new node.
 */
export const createConfigurationGroupAtom = atom<null, CreateGroupPayload>(
  null,
  (get, set, { newNode, targetPath = [] }) => {
    const configurations = get(configurationsTreeAtom);
    const newConfigurations = commonHelpers.addTo(
      configurations,
      newNode,
      targetPath
    );

    set(configurationsTreeAtom, newConfigurations);
  }
);

type CreatePayload = { newNode: ConfigurationNode; targetPath?: ITargetPath };
/**
 * This atom is used to update the `configurationsAtom` with new node.
 */
export const createConfigurationAtom = atom<null, CreatePayload>(
  null,
  (get, set, { newNode, targetPath = [] }) => {
    const configurations = get(configurationsTreeAtom);
    const newConfigurations = commonHelpers.addTo(
      configurations,
      newNode,
      targetPath
    );

    set(configurationsTreeAtom, newConfigurations);
  }
);

type UpdatePayload = { targetPath: ITargetPath; node: ConfigurationNode };
/**
 * This atom is used to update the `configurationsAtom` with updated node.
 */
export const updateConfigurationAtom = atom<null, UpdatePayload>(
  null,
  (get, set, { targetPath, node }) => {
    const tree = get(configurationsTreeAtom);
    const newTree = commonHelpers.updateNodeBy(targetPath, node, tree);

    set(configurationsTreeAtom, newTree);
  }
);

type RemoveGroupPayload = any;
/**
 * This atom is used to remove a node from the `configurationsAtom`.
 */
export const removeConfigurationGroupAtom = atom<null, RemoveGroupPayload>(
  null,
  (get, set, update) => {
    const tree = get(configurationsTreeAtom);
    const targetPath = commonHelpers.getPathBy(update.key, tree);
    const nodeToRemove = commonHelpers.getNodeBy(targetPath, tree);

    if (nodeToRemove) {
      const newTree = commonHelpers.removeNode(targetPath, tree);

      set(configurationsTreeAtom, newTree);
    }
  }
);

type RemovePayload = ConfigurationNode;
/**
 * This atom is used to remove a node from the `configurationsAtom`.
 */
export const removeConfigurationAtom = atom<null, RemovePayload>(
  null,
  (get, set, update) => {
    const tree = get(configurationsTreeAtom);
    const targetPath = commonHelpers.getPathBy(update.key, tree);
    const nodeToRemove = commonHelpers.getNodeBy(targetPath, tree);

    if (nodeToRemove) {
      const newTree = commonHelpers.removeNode(targetPath, tree);

      set(configurationsTreeAtom, newTree);
    }
  }
);

/**
 * This atom is used to search in the `configurationsAtom`.
 */
export const searchConfigurationResultAtom = atom((get) => {
  const configurations = get(configurationsTreeAtom);
  const filterText = get(configurationsSearchTermAtom);

  if (filterText === '@onc') {
    return searchTreeForActive(configurations);
  } else {
    return searchTree(configurations, filterText);
  }
});

export const resetConfigurationsAtom = atom(null, (get, set) => {
  set(configurationKeyAtom, RESET);
  set(configurationsFlattenAtom, RESET);
  set(configurationsTreeAtom, RESET);
  set(configurationsSearchTermAtom, RESET);
  set(configurationsTreeCopyPathAtom, RESET);
});
