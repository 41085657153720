import {
  CherryKey,
  CherryObjectByPixel,
  CherryProjectData,
  CherryProjectManagerObject,
  CherrySurfaceScene,
  CherrySurfaceSceneObject,
  CherryViewer,
  createViewer3DInstance as cv3,
  GroupMat,
  RGB,
  Vector3,
} from '@metavrse-inc/metavrse-lib';
import axios from 'axios';

import { PROJECT_MANAGER_PATH } from 'configConstants';

import { ChangeListenerEvent } from 'models/viewer';

const fetchAssetsBy = async (name: string): Promise<Uint8Array | null> => {
  let result = null;
  try {
    const res = await axios.get(`/${name}`, {
      responseType: 'arraybuffer',
    });

    if (res.status === 200) {
      result = new Uint8Array(res.data);
    }
  } catch (error) {
    result = null;
  }

  return result;
};

const getScene = (viewer: CherryViewer): CherrySurfaceScene =>
  viewer.getSurface().getScene();

const reset = (viewer: CherryViewer): void => {
  viewer.ProjectManager.reset();
};

const resetControls = (viewer: CherryViewer): void => {
  viewer.pixelDensity = devicePixelRatio;
  viewer.controls.distance = 5;
  viewer.controls.target = [0, 0, 0];
  viewer.controls.position = [0, 1, 2];
  setDirty(viewer, true);
};

const loadScene = (
  viewer: CherryViewer,
  projectData: CherryProjectData,
  shouldDisplayGrid: boolean,
  shouldLaunch = false
): void => {
  reset(viewer);
  viewer.ProjectManager.loadScene(projectData, shouldLaunch);
  resetControls(viewer);
  toggleGrid(viewer, shouldDisplayGrid);
};

const setProperty = (
  viewer: CherryViewer,
  key: CherryKey,
  propertyName: string,
  value: RGB | Vector3 | number | string | boolean | GroupMat
): void => {
  const object = getObject(viewer, key);
  if (object?.setProperty) {
    object.setProperty(propertyName, value, key);
  }
};

const toggleGrid = (viewer: CherryViewer, shouldDisplayGrid: boolean): void => {
  getScene(viewer).showRulerGrid(shouldDisplayGrid);
  setDirty(viewer, true);
};

const convertTextureToUint8Array = (data: string): Uint8Array =>
  Uint8Array.from(atob(data), (character) => character.charCodeAt(0));

const setDirty = (viewer: CherryViewer, isDirty: boolean): void => {
  viewer.ProjectManager.isDirty = isDirty;
};

const getObject = (
  viewer: CherryViewer,
  key: string
): CherryProjectManagerObject => {
  return viewer.ProjectManager.getObject(key);
};

const createViewer3DInstance = async (
  canvas: HTMLCanvasElement,
  changeListener?: (event: ChangeListenerEvent) => void
): Promise<CherryViewer> => {
  const published_url = `${process.env.API_BASE_URL}/file-server/publications`;
  const viewer = await cv3(canvas);
  // const viewer = await Viewer3D({
  //   noInitialRun: true,
  //   canvas,
  //   Handlers: {
  //     resetCamera: () => {
  //       return true;
  //     },
  //     onRender: () => {
  //       return true;
  //     },
  //   },
  //   logReadFiles: true,
  // });

  // const facade = cherryFacade(viewer);
  // await facade.loadAssetsAndRun(scripts);

  window.Module = viewer;
  viewer.ProjectManager.path = PROJECT_MANAGER_PATH;
  viewer.ProjectManager.published_url = published_url;
  viewer.ProjectManager.addChangeListener((event: ChangeListenerEvent) => {
    changeListener && changeListener(event);
  });

  return viewer;
};

const getObjectByPixels = (
  viewer: CherryViewer,
  x: number,
  y: number
): CherryObjectByPixel => {
  const scene = getScene(viewer);
  return scene.getObjectByPixel(x, y);
};

const getObjectData = (
  viewer: CherryViewer,
  key: string
): CherryProjectManagerObject => {
  return viewer.ProjectManager.getObject(key);
};

const getObjectPtr = (object: CherryObjectByPixel): null | number => {
  return object.object.object_ptr()?.$$.ptr;
};

const getElementPtr = (element: CherrySurfaceSceneObject): null | number => {
  return element.$$.ptr;
};

export default {
  setProperty,
  getScene,
  loadScene,
  reset,
  resetControls,
  fetchAssetsBy,
  toggleGrid,
  convertTextureToUint8Array,
  setDirty,
  getObject,
  createViewer3DInstance,
  getObjectByPixels,
  getObjectData,
  getObjectPtr,
  getElementPtr,
};
