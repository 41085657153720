import { CherryKey, Entities } from '@metavrse-inc/metavrse-lib';

import { GenericNode } from '../common-helpers';

type Copy<T> = [Entities, T[] | null, { [key: string]: CherryKey }];

const copy = <T extends GenericNode<T>>(...args: Copy<T>): Entities => {
  const [entities, tree, keyPairs] = args;

  if (!tree) {
    return {};
  }
  const newEntities: Entities = {};

  const createCopies = (nodes: T[]) => {
    for (let i = 0; i < nodes.length; i++) {
      const n = nodes[i];
      const key = keyPairs[n.key];
      newEntities[n.key] = { ...entities[key], key: n.key };
      if (n.children) {
        createCopies(n.children);
      }
    }
  };

  createCopies(tree);

  return newEntities;
};

export default copy;
