import { SnackbarError } from './snackbar.error';

export class NoHeroShotError extends Error {
  constructor() {
    super('No hero shot found');
    this.name = 'NoHeroShotError';
  }
}

export class GetHeroShotError extends SnackbarError {
  constructor() {
    super('Server error', 'There was an error while downloading the hero shot');
    this.name = 'GetHeroShotError';
  }
}

export class DeleteHeroShotError extends SnackbarError {
  constructor() {
    super('Server error', 'There was an error while deleting the hero shot');
    this.name = 'DeleteHeroShotError';
  }
}

export class UploadHeroShotError extends SnackbarError {
  constructor() {
    super('Server error', 'There was an error while uploading the hero shot');
    this.name = 'UploadHeroShotError';
  }
}

export class FileFormatError extends SnackbarError {
  constructor(format: string) {
    super('Incorrect file format', `Please choose a ${format} file`);
    this.name = 'FileFormatError';
  }
}

export class CropImageError extends SnackbarError {
  constructor() {
    super(
      'Internal error',
      'Unfortunately we were not able to crop your image.\nPlease try again.'
    );
    this.name = 'CropImageError';
  }
}
