import { AxiosError } from 'axios';

import { HeroShot } from 'models/heroshots/HeroShot.model';

import {
  DeleteHeroShotError,
  GetHeroShotError,
  NoHeroShotError,
  UploadHeroShotError,
} from 'errors/heroshots.error';

import { axiosInstance } from './axios';

const PATH = 'projects';

/**
 * GET
 * projects/:id/image
 * @description Returns a given project's hero shot in base64 format with 'data:image/png;base64,' prefix
 * @docs {@link http://localhost:8000/api/v0/projects/swagger/#/default/ProjectImagesController_getProjectImage}
 */
export const getProjectHeroShot = async (
  /**
   * project id
   */
  id: number
): Promise<string> => {
  const {
    data: { encodedImage },
    status,
  } = await axiosInstance
    .get<HeroShot>(`${PATH}/${id}/image`)
    .catch((err: AxiosError) => {
      throw new GetHeroShotError();
    });

  if (status === 204) {
    throw new NoHeroShotError();
  }

  return `data:image/png;base64,${encodedImage}`;
};

/**
 * GET
 * projects/publications/:id/image
 * @description Returns a given project's hero shot in base64 format with 'data:image/png;base64,' prefix
 */
export const getPublishHeroShot = async (
  /**
   * publish id
   */
  id: string
): Promise<string | null> => {
  const {
    data: { encodedImage },
    status,
  } = await axiosInstance
    .get<HeroShot>(`${PATH}/publications/${id}/image`)
    .catch((err: AxiosError) => {
      throw new GetHeroShotError();
    });

  if (status === 204) {
    return null;
  }

  return `data:image/png;base64,${encodedImage}`;
};

/**
 * PUT
 * projects/:id/image
 * @description Creates or replaces the hero shot of a given project
 * @docs {@link http://localhost:8000/api/v0/projects/swagger/#/default/ProjectImagesController_putProjectImage}
 */
export const updateProjectHeroShot = <T = HeroShot>(
  /**
   * project id
   */
  id: number,
  /**
   * hero shot in base64
   */
  imageBase64: string
): Promise<T> =>
  axiosInstance
    .put<T>(`${PATH}/${id}/image`, {
      imageBase64,
    })
    .then((res) => res.data)
    .catch(() => {
      throw new UploadHeroShotError();
    });

/**
 * DELETE
 * projects/:id/image
 * @description Deletes a given project's hero shot
 * @docs {@link http://localhost:8000/api/v0/projects/swagger/#/default/ProjectImagesController_deleteProjectImage}
 */
export const deleteProjectHeroShot = <T = Omit<HeroShot, 'id'>>(
  /**
   * project id
   */
  id: number
): Promise<T> =>
  axiosInstance
    .delete<T>(`${PATH}/${id}/image`)
    .then((res) => res.data)
    .catch(() => {
      throw new DeleteHeroShotError();
    });
