import React, { useEffect } from 'react';
import { Route, RouteProps } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';

import { AuthenticationStatus } from 'models/user/AuthenticationStatus.enum';

import { isAuthenticatedAtom } from 'atoms/auth';
import { getUserDataAtom } from 'atoms/manager/users';

const { NOT_AUTHENTICATED, AUTHENTICATED } = AuthenticationStatus;

export const ProtectedRoute: React.FC<RouteProps> = (props) => {
  const userAuthStatus = useAtomValue(isAuthenticatedAtom);
  const getUserData = useUpdateAtom(getUserDataAtom);

  const history = useHistory();

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  useEffect(() => {
    if (userAuthStatus === NOT_AUTHENTICATED) {
      history.push('/account');
    }
  }, [history, userAuthStatus]);

  if (userAuthStatus !== AUTHENTICATED) {
    return null;
  }

  return <Route {...props}>{props.children}</Route>;
};
