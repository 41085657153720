import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

import {
  CreateProjectRequest,
  ProjectResponse,
  UpdateProjectRequest,
  UserIdResponse,
} from 'models/manager/project.model';
import { ProjectsSort } from 'models/projectsSort';

const PATH = 'projects';

/*
  GET
  projects/
*/

export const getProjects = async (
  axios: AxiosInstance,
  sort: ProjectsSort,
  limit: number,
  offset: number,
  workspaceId: number
): Promise<AxiosResponse<ProjectResponse[]>> => {
  return await axios.get(
    `${PATH}?limit=${limit}&offset=${offset}&workspaceId=${workspaceId}`
  );
};

/*
  GET
  projects/:id
*/
export const getProject = async (
  axios: AxiosInstance,
  id: number
): Promise<AxiosResponse<ProjectResponse>> => {
  return await axios.get(`${PATH}/${id}?join=workspace`);
};

/*
  POST
  projects/
*/

export const createProject = async (
  axios: AxiosInstance,
  data: CreateProjectRequest
): Promise<AxiosResponse<ProjectResponse>> => {
  return await axios.post(`${PATH}`, data);
};
/*
  PATCH
  projects/:id
*/

export const updateProject = (
  axios: AxiosInstance,
  id: number,
  data: UpdateProjectRequest,
  responseHandler: (response: AxiosResponse<ProjectResponse>) => void,
  errorHandler: (error: AxiosError) => void
): void => {
  axios
    .patch(`${PATH}/${id}?join=workspace`, data)
    .then(responseHandler)
    .catch(errorHandler);
};

/*
  DELETE
  projects/:id
*/
export const deleteProject = (
  axios: AxiosInstance,
  id: number,
  responseHandler: (response: AxiosResponse<ProjectResponse>) => void,
  errorHandler: (error: AxiosError) => void
): void => {
  axios.delete(`${PATH}/${id}`).then(responseHandler).catch(errorHandler);
};

/*
  GET
  projects/:id/userId
*/

export const fetchUserIdByProjectId = (
  axios: AxiosInstance,
  id: number,
  responseHandler: (response: AxiosResponse<UserIdResponse>) => void,
  errorHandler: (error: AxiosError) => void
): void => {
  axios.get(`${PATH}/${id}/userId`).then(responseHandler).catch(errorHandler);
};

/*
  POST
  projects/:id/duplicate
*/
export const duplicateProject = (
  axios: AxiosInstance,
  id: number,
  responseHandler: (response: AxiosResponse<ProjectResponse>) => void,
  errorHandler: (error: AxiosError) => void
): void => {
  axios
    .post(`${PATH}/${id}/duplicate`)
    .then(responseHandler)
    .catch(errorHandler);
};

type Commit = {
  oid: string;
  commit: {
    message: string;
    parent: string[];
    tree: string;
    author: {
      name: string;
      email: string;
      timestamp: number;
      timezoneOffset: number;
    };
    committer: {
      name: string;
      email: string;
      timestamp: number;
      timezoneOffset: number;
    };
  };
};

/*
  GET
  projects/:id/history
*/
export const getProjectHistory = (
  axios: AxiosInstance,
  projectId: number,
  responseHandler: (response: AxiosResponse<Commit[]>) => void,
  errorHandler: (error: AxiosError) => void
): void => {
  axios
    .get(`${PATH}/${projectId}/history`)
    .then(responseHandler)
    .catch(errorHandler);
};

/*
  POST
  projects/import
*/
export const importProject = async (
  axios: AxiosInstance,
  workspaceId: number,
  file: File,
  onUploadProgress: (progress: ProgressEvent) => void
): Promise<AxiosResponse<ProjectResponse>> => {
  const formData = new FormData();
  formData.append('data', file);

  return await axios.post(
    `${PATH}/import?workspaceId=${workspaceId}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    }
  );
};
