import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';

import { StateTypes } from 'models';
import { SignInUserRequest } from 'models/user/signInUserRequest.model';

import { userSignInAtom } from 'atoms/manager/users';
import { getStateByScopeAtom } from 'atoms/state';

import { IconAvatar } from 'icons';

import Button from 'common/Button/Button';
import FormInput from 'common/FormInput/FormInput';

import { loginSchema } from 'validations/schemas/loginSchema';

import AuthBox from '_account/AuthBox/AuthBox';

import classes from './Login.module.css';

const Login: FC = () => {
  const history = useHistory();
  const isLoading = useAtomValue(getStateByScopeAtom(StateTypes.AUTH_FORM));
  const signIn = useUpdateAtom(userSignInAtom);

  const onSuccessCallback = () => {
    history.push('/account/totp');
  };

  const formikConfig = {
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginSchema,
    validateOnMount: false,
    onSubmit: (values: SignInUserRequest) => {
      signIn({ values, onSuccessCallback });
    },
  };

  const handleCreateNewAccount = () => {
    history.push('/account/create');
  };

  const handleForgotPassword = () => {
    history.push('/account/forgot-password');
  };

  return (
    <AuthBox heading="The most powerful web based 3D editor">
      <div className={classes.avatar}>
        <IconAvatar size={200} />
      </div>
      <Formik {...formikConfig}>
        <Form className={classes.form}>
          <FormInput name="email" label="Email" />
          <FormInput name="password" label="Password" type="password" />
          <div className={classes.actions}>
            <Button variant="primary" disabled={isLoading} type="submit">
              Sign In
            </Button>
            <Button
              variant="secondary"
              onClick={handleCreateNewAccount}
              disabled={isLoading}
            >
              Create new account
            </Button>
            <Button
              variant="text"
              onClick={handleForgotPassword}
              disabled={isLoading}
            >
              Forgot Password?
            </Button>
          </div>
        </Form>
      </Formik>
    </AuthBox>
  );
};

export default Login;
