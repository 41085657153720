import { Entities } from '@metavrse-inc/metavrse-lib';

import { BASE_PATH, ENTITIES_FILENAME, SCENES_FOLDER } from 'configConstants';

import { fsp } from 'services/fs.service';
import { convertToJSON } from 'services/helpers';

export const readEntitiesFile = async (
  key: string
): Promise<Entities | null> => {
  try {
    const data = await fsp.readFile(
      `${BASE_PATH}${SCENES_FOLDER}/${key}/${ENTITIES_FILENAME}`
    );

    if (typeof data === 'string') {
      return null;
    }

    return convertToJSON<Entities>(data);
  } catch (error) {
    console.log('ERROR readDataFile: ', error);
    return null;
  }
};

export const writeEntitiesFile = async (
  key: string,
  data: Entities
): Promise<void | null> => {
  try {
    await fsp.writeFile(
      `/${SCENES_FOLDER}/${key}/${ENTITIES_FILENAME}`,
      new TextEncoder().encode(JSON.stringify(data))
    );
  } catch (error) {
    console.log('ERROR writeDataFile: ', error);
    return null;
  }
};
