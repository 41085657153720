import * as yup from 'yup';

export const MAX_NAME_LENGTH = 50;

export const nameValidation = yup
  .string()
  .max(MAX_NAME_LENGTH, `Name can't be longer than 50 characters`)
  .required('Name is required')
  .matches(/[a-zA-Z0-9]/, 'You can use only numbers and letters');

export const userNameValidation = yup.object().shape({
  name: nameValidation,
});
