import {
  Asset,
  AssetType,
  CherryKey,
  NODE_TYPES,
} from '@metavrse-inc/metavrse-lib';

import {
  ASSETS_FILENAME,
  ASSETS_FOLDER,
  BASE_PATH,
  THUMBNAILS_FOLDER,
} from 'configConstants';

import { fsp } from 'services/fs.service';
import { convertToJSON } from 'services/helpers';

import helpers from '../helpers/fileSystemHelpers';

import { generateThumbnailFromBuffer } from './imageFactory';

export const readAssetsJsonFile = async (): Promise<Asset[] | null> => {
  try {
    const data = await fsp.readFile(`${BASE_PATH}${ASSETS_FILENAME}`);

    if (typeof data === 'string') {
      return null;
    }

    return convertToJSON<Asset[]>(data);
  } catch (error) {
    console.log('ERROR readAssetsFile: ', error);
    return null;
  }
};

export const readAssetFile = async (
  key: CherryKey
): Promise<Uint8Array | null | string> => {
  try {
    return await fsp.readFile(`${BASE_PATH}${ASSETS_FOLDER}/${key}`);
  } catch (error) {
    console.log('ERROR readAssetsFile: ', error);
    return null;
  }
};

export const writeAssetsJsonFile = async (
  data: Asset[]
): Promise<void | null> => {
  try {
    await fsp.writeFile(
      `${BASE_PATH}${ASSETS_FILENAME}`,
      new TextEncoder().encode(JSON.stringify(data))
    );
  } catch (error) {
    console.log('ERROR writeAssetsFile: ', error);
    return null;
  }
};

export const writeAssetFile = async (
  key: CherryKey,
  data: Uint8Array
): Promise<void | null> => {
  try {
    await fsp.writeFile(`/${ASSETS_FOLDER}/${key}`, data);
  } catch (error) {
    console.error('ERROR writeAssetFile: ', error);
    return null;
  }
};

export const createAssetFile = async (
  key: CherryKey,
  file: File,
  type: AssetType
): Promise<Uint8Array | null> => {
  try {
    const allowedTypes = [
      NODE_TYPES.image,
      NODE_TYPES.object,
      NODE_TYPES.video,
      NODE_TYPES.javascript,
      NODE_TYPES.stylesheet,
    ];

    if (allowedTypes.some((t: AssetType) => t === type)) {
      const fileToWrite = await helpers.readFileFormArrayBuffer(file, type);

      if (fileToWrite) {
        const uInt8ArrayFile = new Uint8Array(fileToWrite);
        if (type === NODE_TYPES.image) {
          // await createThumbnailFile(key, uInt8ArrayFile);
        }
        await writeAssetFile(key, uInt8ArrayFile);
        return uInt8ArrayFile;
      }
    }
    return null;
  } catch (error) {
    console.log('ERROR createAssetFile: ', error);
    return null;
  }
};

export const moveAssetFile = async (key: CherryKey): Promise<void> => {
  const assetsPath = `${BASE_PATH}${ASSETS_FOLDER}`;
  const copyPath = `${BASE_PATH}${key}`;
  const currentPath = `${assetsPath}/${key}`;

  const copy = await fsp.readFile(copyPath);
  await fsp.writeFile(currentPath, copy);
  await fsp.unlink(copyPath);
};

export const getThumbnailsFolderContent = async (): Promise<string[]> => {
  const mainFolderFiles = await fsp.readdir('/');
  const thumbnailsFolderExists = mainFolderFiles.some(
    (f) => f === THUMBNAILS_FOLDER
  );
  if (!thumbnailsFolderExists) {
    await fsp.mkdir(`${BASE_PATH}${THUMBNAILS_FOLDER}`);
    return [];
  }
  return fsp.readdir(`${BASE_PATH}${THUMBNAILS_FOLDER}`);
};

export const createThumbnailFile = async (
  key: CherryKey,
  file: Uint8Array
): Promise<void> => {
  const files = await fsp.readdir('/');
  const thumbnailsFolderExists = files.some((f) => f === THUMBNAILS_FOLDER);
  if (!thumbnailsFolderExists) {
    await fsp.mkdir(`${BASE_PATH}${THUMBNAILS_FOLDER}`);
  }
  const thumbnail = await generateThumbnailFromBuffer(file);
  await fsp.writeFile(`${BASE_PATH}${THUMBNAILS_FOLDER}/${key}`, thumbnail);
};

export const removeThumbnailFile = async (key: CherryKey): Promise<void> => {
  try {
    const files = await fsp.readdir('/');
    const thumbnailsFolderExists = files.some((f) => f === THUMBNAILS_FOLDER);
    if (thumbnailsFolderExists) {
      await fsp.unlink(`${BASE_PATH}${THUMBNAILS_FOLDER}/${key}`);
    }
  } catch (error) {
    console.log('ERROR removeThumbnailFile: ', error);
  }
};

export const removeAssetFile = async (key: string): Promise<void | null> => {
  try {
    await fsp.unlink(`${BASE_PATH}${ASSETS_FOLDER}/${key}`);
  } catch (error) {
    console.log('ERROR removeAssetFile: ', error);
    return null;
  }
};

export const getFileBuffer = (path: string): Promise<Uint8Array | null> => {
  const MAX_NUMBER_OF_RETRY = 4;
  const BASE_RETRY_TIME = 10;
  const loadFile = async (retryNumber: number): Promise<Uint8Array | null> => {
    try {
      const data = await fsp.readFile(path);

      if (typeof data === 'string') {
        return null;
      }

      return data;
    } catch (e) {
      if (retryNumber <= MAX_NUMBER_OF_RETRY) {
        setTimeout(() => {
          return loadFile(retryNumber + 1);
        }, BASE_RETRY_TIME * retryNumber);
      } else {
        // throw e;
        return null;
      }
    }

    return null;
    // throw Error('Unable to read given file');
  };
  return loadFile(0);
};
