// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ForgotPassword-module__wrapper--i2Nia {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: var(--formWidth);\n  margin: 0 auto;\n}\n\n.ForgotPassword-module__heading--RmR42 {\n  color: var(--onPrimary);\n  opacity: 0.5;\n  margin-bottom: var(--spacing6);\n}\n\n.ForgotPassword-module__form--N7pRY {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n\n.ForgotPassword-module__actions--aiQCQ {\n  margin-top: var(--spacing6);\n  display: grid;\n}\n", "",{"version":3,"sources":["webpack://./src/components/_account/ForgotPassword/ForgotPassword.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,uBAAuB;EACvB,cAAc;AAChB;;AAEA;EACE,uBAAuB;EACvB,YAAY;EACZ,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,2BAA2B;EAC3B,aAAa;AACf","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: var(--formWidth);\n  margin: 0 auto;\n}\n\n.heading {\n  color: var(--onPrimary);\n  opacity: 0.5;\n  margin-bottom: var(--spacing6);\n}\n\n.form {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n\n.actions {\n  margin-top: var(--spacing6);\n  display: grid;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "ForgotPassword-module__wrapper--i2Nia",
	"heading": "ForgotPassword-module__heading--RmR42",
	"form": "ForgotPassword-module__form--N7pRY",
	"actions": "ForgotPassword-module__actions--aiQCQ"
};
export default ___CSS_LOADER_EXPORT___;
