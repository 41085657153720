// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ResetPassword-module__wrapper--dTu36 {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: var(--formWidth);\n  margin: 0 auto;\n}\n\n.ResetPassword-module__heading--jWOrJ {\n  color: var(--onPrimary);\n  opacity: 0.5;\n  margin-bottom: var(--spacing6);\n}\n\n.ResetPassword-module__form--HfXl6 {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n\n.ResetPassword-module__actions--HB_uU {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  column-gap: 20px;\n  margin-top: 20px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/_account/ResetPassword/ResetPassword.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,uBAAuB;EACvB,cAAc;AAChB;;AAEA;EACE,uBAAuB;EACvB,YAAY;EACZ,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,qCAAqC;EACrC,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: var(--formWidth);\n  margin: 0 auto;\n}\n\n.heading {\n  color: var(--onPrimary);\n  opacity: 0.5;\n  margin-bottom: var(--spacing6);\n}\n\n.form {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n\n.actions {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  column-gap: 20px;\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "ResetPassword-module__wrapper--dTu36",
	"heading": "ResetPassword-module__heading--jWOrJ",
	"form": "ResetPassword-module__form--HfXl6",
	"actions": "ResetPassword-module__actions--HB_uU"
};
export default ___CSS_LOADER_EXPORT___;
