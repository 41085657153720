import { FC } from 'react';
import { ErrorMessage, Field, FieldProps, useField } from 'formik';

import { classMerge } from 'utils/classMerge';

import { Option } from 'models/dropdown';

import Body2 from 'common/Body2/Body2';
import Dropdown from 'common/Dropdown/Dropdown';
import Label from 'common/Label/Label';

import classes from './FormSelect.module.css';

type Props = {
  label: string;
  options: Option[];
  defaultOption?: boolean;
  name: string;
};

const FormSelect: FC<Props> = ({ label, options, defaultOption, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className={classes.root}>
      <div
        className={classMerge(classes.label, {
          [classes.labelError]: meta.touched && meta.error,
        })}
      >
        <Label name={field.name}>{label}</Label>
      </div>
      <Field name={field.name}>
        {({ form }: FieldProps) => (
          <>
            <Dropdown
              label={label}
              name={field.name}
              options={options}
              defaultOption={defaultOption}
              value={field.value}
              {...form}
            />
            <ErrorMessage
              name={field.name}
              render={(msg) => (
                <div className={classes.error}>
                  <Body2>{msg}</Body2>
                </div>
              )}
            />
          </>
        )}
      </Field>
    </div>
  );
};

export default FormSelect;
