import { FC, MouseEvent, ReactNode } from 'react';

import { classMerge } from 'utils/classMerge';

import classes from './IconButton.module.css';

type Props = {
  children: ReactNode;
  variant?: 'normal' | 'bright' | 'rectangle';
  round?: boolean;
  active?: boolean;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  tooltip?: string;
};

const IconButton: FC<Props> = ({
  children,
  variant = 'normal',
  round,
  active,
  onClick,
  disabled,
  tooltip = '',
}) => {
  return (
    <button
      type="button"
      className={classMerge(classes[variant], {
        [classes.round]: round,
        [classes.active]: active,
      })}
      onClick={onClick}
      disabled={disabled}
      title={tooltip}
    >
      {children}
    </button>
  );
};

export default IconButton;
