import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

import { OrganizationRoles } from 'models/organization';

const PATH = 'organizations';

export interface IOrganizationDetailsResponse {
  createdAt: string;
  updatedAt: string;
  id: number;
  name: string;
}

export interface IUserOrganizationResponse {
  id: number;
  organizationId: number;
  userId: number;
  role: OrganizationRoles;
  createdAt: string;
  updatedAt: string;
  organization: IOrganizationDetailsResponse;
}

interface IGetOrganizationRequest {
  userId: number;
}

export const getOrganizations = (
  axios: AxiosInstance,
  { userId }: IGetOrganizationRequest,
  responseHandler: (
    response: AxiosResponse<IUserOrganizationResponse[]>
  ) => void,
  errorHandler: (error: AxiosError) => void
): void => {
  axios
    .get(`${PATH}/?userId=${userId}`)
    .then(responseHandler)
    .catch(errorHandler);
};

export const getOrganization = async (
  axios: AxiosInstance,
  organizationId: number
): Promise<AxiosResponse<IOrganizationDetailsResponse>> => {
  return await axios.get(`${PATH}/${organizationId}`);
};

export interface IUpdateOrganizationDetailsRequest {
  name: string;
}

export const updateOrganization = (
  axios: AxiosInstance,
  organizationId: number,
  organizationDetails: IUpdateOrganizationDetailsRequest,
  responseHandler: (
    response: AxiosResponse<IOrganizationDetailsResponse>
  ) => void,
  errorHandler: (error: AxiosError) => void
): void => {
  axios
    .patch(`${PATH}/${organizationId}`, organizationDetails)
    .then(responseHandler)
    .catch(errorHandler);
};
