import * as yup from 'yup';

export const emailValidation = yup
  .string()
  .max(254)
  .email('Email is invalid')
  .required('Email is required');

export const userEmailSchema = yup.object().shape({
  email: emailValidation,
});

export const emailConfirmationValidation = yup
  .string()
  .oneOf([yup.ref('email'), null], 'Email should equal to your account email')
  .required('Email confirmation is required');
