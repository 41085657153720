import produce from 'immer';

import addErrorMessage from 'utils/workspaceLimits/addErrorMessage';

import { WorkspaceLimitsErrors } from 'models/manager/workspaces/workspaceLimitsErrors.model';
import { WorkspaceLimitsTypes } from 'models/manager/workspaces/workspaceLimitsTypes.enum';
import { WorkspaceLimitsUsageResponse } from 'models/manager/workspaces/workspaceLimitsUsageResponse.model';

const { PROJECTS, SEATS, STORAGE, VIEWS } = WorkspaceLimitsTypes;

const SEATS_WARNING_PERCENTAGE = 90;
const PROJECTS_WARNING_PERCENTAGE = 80;
const STORAGE_WARNING_PERCENTAGE = 80;
const VIEWS_WARNING_PERCENTAGE = 75;

export const getWorkspaceLimitsByType = (
  type: WorkspaceLimitsTypes,
  limits: WorkspaceLimitsUsageResponse[]
): WorkspaceLimitsUsageResponse =>
  limits.find((limit) => limit.type === type) as WorkspaceLimitsUsageResponse;

export const getLimitsWithErrorMessages = (
  limits: WorkspaceLimitsUsageResponse[],
  type?: WorkspaceLimitsTypes
): WorkspaceLimitsErrors[] =>
  produce(limits, (draft) => {
    return draft
      .map((limit) => {
        const usage = (limit.amountUsed / limit.amountAvailable) * 100;
        switch (limit.type) {
          case PROJECTS:
            return addErrorMessage(
              usage,
              limit,
              PROJECTS_WARNING_PERCENTAGE,
              'projects'
            );
          case SEATS:
            return addErrorMessage(
              usage,
              limit,
              SEATS_WARNING_PERCENTAGE,
              'seats'
            );
          case STORAGE:
            return addErrorMessage(
              usage,
              limit,
              STORAGE_WARNING_PERCENTAGE,
              'storage'
            );
          case VIEWS:
            return addErrorMessage(
              usage,
              limit,
              VIEWS_WARNING_PERCENTAGE,
              'views'
            );
        }
      })
      .filter((element) =>
        type ? element?.type === type : element !== undefined
      ) as WorkspaceLimitsErrors[];
  }) as WorkspaceLimitsErrors[];
