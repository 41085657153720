// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormSelect-module__root--D_Rqr {\n  --errorFontSize: var(--labelFontSize);\n  --borderWidth: 1px;\n  grid-template-rows: 1fr 43px minmax(16px, auto);\n}\n\n.FormSelect-module__label--wtrRa {\n  display: inline-block;\n  position: inherit;\n  height: auto;\n  color: var(--onSurface);\n}\n\n.FormSelect-module__labelError--cUJIG {\n  color: var(--error);\n}\n\n.FormSelect-module__error--FDnhG {\n  margin: var(--spacing1) calc(var(--spacing6) + var(--borderWidth));\n  font-size: var(--errorFontSize);\n  word-break: break-all;\n  text-align: justify;\n  color: var(--error);\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/FormSelect/FormSelect.module.css"],"names":[],"mappings":"AAAA;EACE,qCAAqC;EACrC,kBAAkB;EAClB,+CAA+C;AACjD;;AAEA;EACE,qBAAqB;EACrB,iBAAiB;EACjB,YAAY;EACZ,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kEAAkE;EAClE,+BAA+B;EAC/B,qBAAqB;EACrB,mBAAmB;EACnB,mBAAmB;AACrB","sourcesContent":[".root {\n  --errorFontSize: var(--labelFontSize);\n  --borderWidth: 1px;\n  grid-template-rows: 1fr 43px minmax(16px, auto);\n}\n\n.label {\n  display: inline-block;\n  position: inherit;\n  height: auto;\n  color: var(--onSurface);\n}\n\n.labelError {\n  color: var(--error);\n}\n\n.error {\n  margin: var(--spacing1) calc(var(--spacing6) + var(--borderWidth));\n  font-size: var(--errorFontSize);\n  word-break: break-all;\n  text-align: justify;\n  color: var(--error);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "FormSelect-module__root--D_Rqr",
	"label": "FormSelect-module__label--wtrRa",
	"labelError": "FormSelect-module__labelError--cUJIG",
	"error": "FormSelect-module__error--FDnhG"
};
export default ___CSS_LOADER_EXPORT___;
