import {
  CherryObjectByPixel,
  CherrySurfaceSceneObject,
  CherryViewer,
  SelectedObject,
} from '@metavrse-inc/metavrse-lib';
import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

export const hasFilesLoadedAtom = atom(false);
export const selectedObjectAtom = atomWithReset<SelectedObject>({
  object: null,
  meshId: null,
  key: null,
});
export const viewerAtom = atom<CherryViewer | null>(null);
export const previewAtom = atom<CherryViewer | null>(null);
export const isHoverLockedAtom = atom(false);
export const hoverObjAtom = atom<CherrySurfaceSceneObject | null>(null);
export const hoverMeshIDAtom = atom<number | null>(null);

export const isViewerSelectedAtom = atom<boolean>(false);
export const selectedObjectCherryInfoAtom = atom<{
  sceneObject: CherrySurfaceSceneObject;
  objectByPixel: CherryObjectByPixel;
} | null>(null);
