// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CreateNewAccount-module__avatar--s0XiV {\n  margin-right: 50px;\n  width: 40%;\n  max-width: 200px;\n}\n\n.CreateNewAccount-module__form--Icp6k {\n  display: grid;\n  grid-template-rows: repeat(3, minmax(43px, auto));\n  width: 60%;\n}\n\n.CreateNewAccount-module__actions--J5691 {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  column-gap: 20px;\n  margin-top: 20px;\n}\n\n.CreateNewAccount-module__error--dSyHA {\n  min-height: 16px;\n  color: var(--error);\n  margin-top: var(--spacing2);\n}\n", "",{"version":3,"sources":["webpack://./src/components/_account/CreateNewAccount/CreateNewAccount.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,iDAAiD;EACjD,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,qCAAqC;EACrC,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,2BAA2B;AAC7B","sourcesContent":[".avatar {\n  margin-right: 50px;\n  width: 40%;\n  max-width: 200px;\n}\n\n.form {\n  display: grid;\n  grid-template-rows: repeat(3, minmax(43px, auto));\n  width: 60%;\n}\n\n.actions {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  column-gap: 20px;\n  margin-top: 20px;\n}\n\n.error {\n  min-height: 16px;\n  color: var(--error);\n  margin-top: var(--spacing2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatar": "CreateNewAccount-module__avatar--s0XiV",
	"form": "CreateNewAccount-module__form--Icp6k",
	"actions": "CreateNewAccount-module__actions--J5691",
	"error": "CreateNewAccount-module__error--dSyHA"
};
export default ___CSS_LOADER_EXPORT___;
