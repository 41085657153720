export const INDUSTRY_OPTIONS = [
  {
    label: 'Architecture, Engineering and Construction',
    value: '1',
  },
  {
    label: 'Arts, Entertainment and Travel',
    value: '2',
  },
  {
    label: 'Defense and Military',
    value: '3',
  },
  {
    label: 'Education & Training',
    value: '4',
  },
  {
    label: 'Energy',
    value: '5',
  },
  {
    label: 'Finance',
    value: '6',
  },
  {
    label: 'Health and Medical',
    value: '7',
  },
  {
    label: 'Real Estate',
    value: '8',
  },
  {
    label: 'Retail',
    value: '9',
  },
  {
    label: 'Technology',
    value: '10',
  },
  {
    label: 'Telecommunications',
    value: '11',
  },
  {
    label: 'Transportation',
    value: '12',
  },
  {
    label: 'Other',
    value: '13',
  },
];
