import { atom } from 'jotai';

import { SnackbarKey, SnackbarMessage } from 'models/snackbars/snackbarMessage';
import { SnackbarProgress } from 'models/snackbars/snackbarProgress.model';
import { UpdateSnackbarProgress } from 'models/snackbars/updateSnackbarProgress.model';

import {
  removeFirstElementFrom,
  setNewSnackbarProgress,
} from 'atoms/helpers/snackbars-helpers';

const HIDE_SNACKBAR_DELAY_TIME = 5000;
const MAX_VISIBLE_SNACKBARS = 4;

export const snackbarAtom = atom<SnackbarMessage[]>([]);
export const snackbarQueueAtom = atom<SnackbarMessage[]>([]);
export const snackbarProgressAtom = atom<SnackbarProgress[]>([]);
export const snackbarTimeoutAtom = atom<
  ReturnType<typeof setTimeout> | undefined
>(undefined);

export const setSnackbarAtom = atom(
  null,
  (get, set, message: SnackbarMessage) => {
    const snackbars = get(snackbarAtom);
    const { timer } = message;
    const key = message.key ? message.key : Date.now();

    if (snackbars.length < MAX_VISIBLE_SNACKBARS) {
      set(snackbarAtom, (prev) => [...prev, { ...message, key }]);
    } else {
      set(snackbarQueueAtom, (prev) => [...prev, { ...message, key }]);
    }

    if (message.isProgress && message.progressValue === 0) {
      set(snackbarProgressAtom, [
        { key: message.key as number, progressValue: message.progressValue },
      ]);
    } else {
      set(
        snackbarTimeoutAtom,
        setTimeout(
          () => set(removeSnackbarAtom, key),
          timer !== undefined ? timer : HIDE_SNACKBAR_DELAY_TIME
        )
      );
    }
  }
);

export const removeSnackbarAtom = atom(
  null,
  (get, set, update: SnackbarKey) => {
    const snackbars = get(snackbarAtom);
    const snackbarsQueue = get(snackbarQueueAtom);
    const snackbarsProgress = get(snackbarProgressAtom);
    const [firstQueueElement, queue] = removeFirstElementFrom(snackbarsQueue);

    set(
      snackbarAtom,
      snackbars.filter((snackbar) => snackbar.key !== update)
    );

    set(
      snackbarProgressAtom,
      snackbarsProgress.filter((bar) => bar.key !== update)
    );

    if (snackbarsQueue.length) {
      set(snackbarQueueAtom, queue);
      set(setSnackbarAtom, firstQueueElement);
    }
  }
);

export const closeSnackbarAtom = atom(null, (get, set, index: number) => {
  const snackbars = get(snackbarAtom);
  const snackbarTimeout = get(snackbarTimeoutAtom);

  set(removeSnackbarAtom, snackbars[index].key as number);

  if (snackbarTimeout) {
    clearTimeout(snackbarTimeout);
    set(snackbarTimeoutAtom, undefined);
  }
});

export const updateProgressSnackbarAtom = atom(
  null,
  (get, set, update: UpdateSnackbarProgress) => {
    const {
      snackbarKey,
      newMessage,
      progress,
      newTitle,
      newType,
      frozen,
      isVisible,
      timer,
    } = update;

    const snackbar = get(snackbarAtom);
    const snackbarProgress = get(snackbarProgressAtom);

    const snackbarToUpdate = snackbar.find(({ key }) => key === snackbarKey);
    const isSnackbarProgressAdded = !snackbarProgress.filter(
      (progress) => progress.key === snackbarKey
    ).length;

    if (snackbarToUpdate) {
      snackbarToUpdate.body = newMessage ?? snackbarToUpdate.body;
      snackbarToUpdate.progressValue =
        progress ?? snackbarToUpdate.progressValue;
      snackbarToUpdate.title = newTitle ?? snackbarToUpdate.title;
      snackbarToUpdate.type = newType ?? snackbarToUpdate.type;
      snackbarToUpdate.frozen = frozen ?? snackbarToUpdate.frozen;
      snackbarToUpdate.isVisible = isVisible ?? snackbarToUpdate.isVisible;

      set(
        snackbarAtom,
        snackbar.map((snackbar) =>
          snackbar.key === snackbarToUpdate.key ? snackbarToUpdate : snackbar
        )
      );

      if (progress) {
        if (isSnackbarProgressAdded) {
          set(snackbarProgressAtom, (prev) => [
            ...prev,
            { key: snackbarKey, progressValue: progress },
          ]);
        } else {
          set(snackbarProgressAtom, (prev) =>
            setNewSnackbarProgress(prev, progress, snackbarToUpdate)
          );
        }
      }

      if (progress === 100 && !frozen) {
        set(
          snackbarTimeoutAtom,
          setTimeout(
            () => set(removeSnackbarAtom, snackbarKey),
            timer !== undefined ? timer : HIDE_SNACKBAR_DELAY_TIME
          )
        );
      }
    }
  }
);
