import { FC } from 'react';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';

import { SnackbarKey } from 'models/snackbars/snackbarMessage';
import SnackbarType from 'models/snackbars/snackbarType';

import {
  closeSnackbarAtom,
  snackbarProgressAtom,
} from 'atoms/snackbars/snackbars.atom';

import { IconRemove } from 'icons';

import Body1 from 'common/Body1/Body1';
import Body2 from 'common/Body2/Body2';
import IconButton from 'common/IconButton/IconButton';

import getSnackbarIconBy from './helpers/getSnackbarIconBy';
import classes from './Snackbar.module.css';

type Props = {
  snackbarKey: SnackbarKey;
  title: string;
  type: SnackbarType;
  body: string;
  index: number;
  isProgress?: boolean;
};

const Snackbar: FC<Props> = ({
  snackbarKey,
  title,
  type,
  body,
  index,
  isProgress,
}) => {
  const progress = useAtomValue(snackbarProgressAtom);
  const closeSnackbar = useUpdateAtom(closeSnackbarAtom);
  const AppropriateIcon = getSnackbarIconBy(type);

  const snackbarProgress =
    progress[progress.findIndex(({ key }) => key === snackbarKey)];

  return (
    <div className={classes.root}>
      <div className={classes.iconContainer}>
        <AppropriateIcon size={18} color={`var(--${type.toLowerCase()})`} />
      </div>
      <div className={classes.textContainer}>
        <div className={classes.title}>
          <Body1>{title}</Body1>
        </div>
        {isProgress && !!snackbarProgress ? (
          <div className={classes.progressBar}>
            <div className={classes.bodyInProgressBar}>
              <Body2>{body}</Body2>
              {snackbarProgress.progressValue >= 0 && (
                <Body2>{snackbarProgress.progressValue}%</Body2>
              )}
            </div>
            <div className={classes.progressBarInner}>
              <div
                className={classes.progressBarTotal}
                style={{
                  width: `${snackbarProgress.progressValue}%`,
                }}
              />
            </div>
          </div>
        ) : (
          <div className={classes.body}>
            <Body2>{body}</Body2>
          </div>
        )}
      </div>
      <div className={classes.removeIconContainer}>
        <IconButton onClick={() => closeSnackbar(index)}>
          <IconRemove size={18} color="var(--on-surface600)" />
        </IconButton>
      </div>
    </div>
  );
};

export default Snackbar;
