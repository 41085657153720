import {
  CherryMesh,
  CherryMeshGroup,
  CherryMeshMaterial,
} from '@metavrse-inc/metavrse-lib';
import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

import { ExpandedMeshes } from 'models/atoms/inspector/expandedMeshes.model';
import { MeshSelection } from 'models/atoms/inspector/MeshSelection.model';
import { MeshesTabTypes } from 'models/atoms/inspector/Tabs.enum';

export const tabSelectedAtom = atom<MeshesTabTypes>(MeshesTabTypes.meshes);
export const selectedMeshesAtom = atom<MeshSelection | null>(null);

export const objectMeshesAtom = atom<CherryMesh[]>([]);
export const objectMaterialsAtom = atom<CherryMeshMaterial[]>([]);
export const objectGroupsAtom = atom<CherryMeshGroup[]>([]);

export const searchMeshesTermAtom = atomWithReset('');
export const expandedMeshesAtom = atomWithReset<ExpandedMeshes[]>([]);
