// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ExceededLimitsModal-module__root--S82ly {\n  --buttonsGap: var(--spacing4);\n  --messageGap: var(--spacing1);\n\n  display: flex;\n  flex-wrap: wrap;\n  text-align: center;\n}\n\n.ExceededLimitsModal-module__heading--W9hFY,\n.ExceededLimitsModal-module__message--h6qji {\n  flex-basis: 100%;\n}\n\n.ExceededLimitsModal-module__heading--W9hFY {\n  padding: var(--spacing7) 0 0;\n}\n\n.ExceededLimitsModal-module__message--h6qji {\n  display: flex;\n  flex-direction: column;\n  gap: var(--messageGap);\n  margin: var(--spacing10) 0;\n}\n\n.ExceededLimitsModal-module__buttons--ep1gV {\n  display: flex;\n  flex-basis: 40%;\n  flex-direction: column;\n  gap: var(--buttonsGap);\n  margin: 0 auto var(--spacing7);\n}\n", "",{"version":3,"sources":["webpack://./src/components/_manager/_forms/NewProjectForm/components/ExceededLimitsModal.module.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,6BAA6B;;EAE7B,aAAa;EACb,eAAe;EACf,kBAAkB;AACpB;;AAEA;;EAEE,gBAAgB;AAClB;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,sBAAsB;EACtB,0BAA0B;AAC5B;;AAEA;EACE,aAAa;EACb,eAAe;EACf,sBAAsB;EACtB,sBAAsB;EACtB,8BAA8B;AAChC","sourcesContent":[".root {\n  --buttonsGap: var(--spacing4);\n  --messageGap: var(--spacing1);\n\n  display: flex;\n  flex-wrap: wrap;\n  text-align: center;\n}\n\n.heading,\n.message {\n  flex-basis: 100%;\n}\n\n.heading {\n  padding: var(--spacing7) 0 0;\n}\n\n.message {\n  display: flex;\n  flex-direction: column;\n  gap: var(--messageGap);\n  margin: var(--spacing10) 0;\n}\n\n.buttons {\n  display: flex;\n  flex-basis: 40%;\n  flex-direction: column;\n  gap: var(--buttonsGap);\n  margin: 0 auto var(--spacing7);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "ExceededLimitsModal-module__root--S82ly",
	"heading": "ExceededLimitsModal-module__heading--W9hFY",
	"message": "ExceededLimitsModal-module__message--h6qji",
	"buttons": "ExceededLimitsModal-module__buttons--ep1gV"
};
export default ___CSS_LOADER_EXPORT___;
