export enum StateTypes {
  ASSETS = 'ASSETS',
  CONFIGURATIONS = 'CONFIGURATIONS',
  CONSOLE = 'CONSOLE',
  EDITOR = 'EDITOR',
  HEADER = 'HEADER',
  INSPECTOR = 'INSPECTOR',
  SCENES = 'SCENES',
  SCENES_CHANGE = 'SCENES_CHANGE',
  SCRIPT_EDITOR = 'SCRIPT_EDITOR',
  VIEWER = 'VIEWER',
  MANAGER_RIGHT = 'MANAGER_RIGHT',
  MANAGER_CENTER = 'MANAGER_CENTER',
  AUTH_FORM = 'AUTH_FORM',
  HERO_SHOT = 'HERO_SHOT',
  MODAL = 'MODAL',
  USER = 'USER',
  SUBSCRIPTION = 'SUBSCRIPTION',
  IMPORT = 'IMPORT',
}

export type StateObject = Record<StateTypes, boolean>;

export enum LoadingStatus {
  LOADING = 'LOADING',
  READY = 'READY',
}
