import { TreeNode } from '@metavrse-inc/metavrse-lib';
import { atom, Getter, Setter } from 'jotai';

import { treeAtom } from './tree.atom';

type Arguments = [Getter, Setter, TreeNode[]];

const setMethod = (...args: Arguments): void => {
  const [get, set, update] = args;
  const newTree = update;

  set(treeAtom, newTree);
};

export const fillNodeAtom = atom(null, setMethod);
