import { FC, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';

import CreateNewAccount from '_account/CreateNewAccount/CreateNewAccount';
import ForgotPassword from '_account/ForgotPassword/ForgotPassword';
import Login from '_account/Login/Login';
import ResetPassword from '_account/ResetPassword/ResetPassword';
import TotpLogin from '_account/TotpLogin/TotpLogin';

const AccountActivation = lazy(
  () => import('_account/AccountActivation/AccountActivation')
);

const AccountRoute: FC = () => {
  return (
    <>
      <Switch>
        <Route path="/account/activate">
          <AccountActivation />
        </Route>
        <Route path="/account/create">
          <CreateNewAccount />
        </Route>
        <Route path="/account/forgot-password">
          <ForgotPassword />
        </Route>
        <Route path="/account/reset-password">
          <ResetPassword />
        </Route>
        <Route path="/account/totp">
          <TotpLogin />
        </Route>
        <Route exact path="/account">
          <Login />
        </Route>
      </Switch>
    </>
  );
};

export default AccountRoute;
