import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

import WorkspaceActiveLicence from 'models/manager/subscriptions/workspaceActiveLicence.model';
import { WorkspaceResponse } from 'models/manager/workspaces.model';
import { WorkspaceLimitsUsageResponse } from 'models/manager/workspaces/workspaceLimitsUsageResponse.model';

const PATH = 'workspaces';

export const getWorkspaces = async (
  axios: AxiosInstance,
  userId: number
): Promise<AxiosResponse<WorkspaceResponse[]>> => {
  return await axios.get(`${PATH}?userId=${userId}&join=organization`);
};

export const getWorkspaceLimitsUsage = async (
  axios: AxiosInstance,
  workspaceId: number
): Promise<AxiosResponse<WorkspaceLimitsUsageResponse[]>> => {
  return await axios.get(`${PATH}/${workspaceId}/limits/usage`);
};

export const deleteWorkspace = (
  axios: AxiosInstance,
  workspaceId: number,
  responseHandler: (response: AxiosResponse<WorkspaceResponse>) => void,
  errorHandler: (error: AxiosError) => void
): void => {
  axios
    .delete(`${PATH}/${workspaceId}`)
    .then(responseHandler)
    .catch(errorHandler);
};

export const updateWorkspace = (
  axios: AxiosInstance,
  workspaceId: number,
  name: string,
  responseHandler: (response: AxiosResponse<WorkspaceResponse>) => void,
  errorHandler: (error: AxiosError) => void
): void => {
  axios
    .patch(`${PATH}/${workspaceId}`, { name })
    .then(responseHandler)
    .catch(errorHandler);
};

export const getWorkspaceActiveLicence = async (
  axios: AxiosInstance,
  selectedWorkspaceId: number
): Promise<AxiosResponse<WorkspaceActiveLicence>> => {
  return await axios.get(`${PATH}/${selectedWorkspaceId}/license`);
};
