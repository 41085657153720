import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';

import { DEFAULT_DROPDOWN_VALUE } from 'configConstants';

import { StateTypes } from 'models';

import { ISignUpUserRequest } from 'services/users';

import { userSignUpAtom } from 'atoms/manager/users';
import { getStateByScopeAtom } from 'atoms/state';

import { IconAvatar } from 'icons';

import Button from 'common/Button/Button';
import FormInput from 'common/FormInput/FormInput';
import FormSelect from 'common/FormSelect/FormSelect';

import { signupSchema } from 'validations/schemas/signupSchema';

import AuthBox from '_account/AuthBox/AuthBox';

import classes from './CreateNewAccount.module.css';
import { INDUSTRY_OPTIONS } from './data';

const CreateNewAccount: FC = () => {
  const isLoading = useAtomValue(getStateByScopeAtom(StateTypes.AUTH_FORM));
  const signUp = useUpdateAtom(userSignUpAtom);

  const history = useHistory();

  const successCallback = () => {
    history.push('/account');
  };

  const formikConfig = {
    initialValues: {
      name: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      industry: DEFAULT_DROPDOWN_VALUE,
    },
    validationSchema: signupSchema,
    validateOnMount: false,
    onSubmit: (values: ISignUpUserRequest) => {
      //TODO: [MET-830] Go back to sending whole values object once BE will be fixed
      const { passwordConfirmation, ...newValues } = values;

      signUp({ values: newValues, successCallback });
    },
  };

  return (
    <AuthBox heading="Create new account">
      <div className={classes.avatar}>
        <IconAvatar size={200} />
      </div>
      <Formik {...formikConfig}>
        <Form className={classes.form}>
          <FormInput name="name" label="Name" />
          <FormInput name="email" label="Email" />
          <FormInput name="password" label="Password" type="password" />
          <FormInput
            name="passwordConfirmation"
            label="Repeat password"
            type="password"
          />
          <FormSelect
            name="industry"
            label="Industry"
            options={INDUSTRY_OPTIONS}
          />

          <div className={classes.actions}>
            <Button
              variant="secondary"
              to="/account"
              type="button"
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button variant="primary" disabled={isLoading} type="submit">
              Create
            </Button>
          </div>
        </Form>
      </Formik>
    </AuthBox>
  );
};

export default CreateNewAccount;
