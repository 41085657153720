import {
  CherryKey,
  Entities,
  Entity,
  ShaderParameterType,
} from '@metavrse-inc/metavrse-lib';
import { atom } from 'jotai';
import { atomWithReset, RESET } from 'jotai/utils';

export const entitiesAtom = atomWithReset<Entities>({});
export const entitiesCopyAtom = atomWithReset<Entities | null>(null);

export const selectedEntityKeyAtom = atomWithReset<CherryKey | null>(null);

export const selectedEntityTypeAtom = atom<string | null>((get) => {
  const entities = get(entitiesAtom);
  const key = get(selectedEntityKeyAtom);
  return entities[key] ? entities[key].type : null;
});

export const selectedEntityAtom = atom<Entity | null, CherryKey | typeof RESET>(
  (get) => {
    const entities = get(entitiesAtom);
    const key = get(selectedEntityKeyAtom);

    return key ? entities[key] : null;
  },
  (get, set, key) => {
    set(selectedEntityKeyAtom, key);
  }
);

export const materialAtom = atomWithReset<Record<
  ShaderParameterType,
  any
> | null>(null);

/**
 * @deprecated please use updated `selectedEntityAtom` instead
 */
export const entityAtom = selectedEntityAtom;

export const resetEntitiesAtom = atom(null, (get, set) => {
  set(entitiesAtom, RESET);
  set(entitiesCopyAtom, RESET);
  set(selectedEntityKeyAtom, RESET);
  set(materialAtom, RESET);
});
