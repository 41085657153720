import { FC, ReactNode } from 'react';

import classes from './Label.module.css';

type Props = {
  children: ReactNode | ReactNode[];
  name: string;
};

const Label: FC<Props> = ({ children, name }) => {
  return (
    <label className={classes.root} htmlFor={name}>
      {children}
    </label>
  );
};

export default Label;
