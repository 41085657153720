import { CHERRY_IMAGE_WORKER_PATH } from 'configConstants';

// concat ...n buffers
// buffer type, ...buffers
function concatenate(resultConstructor, ...arrays) {
  let totalLength = 0;
  for (const arr of arrays) {
    totalLength += arr.length;
  }
  const result = new resultConstructor(totalLength);
  let offset = 0;
  for (const arr of arrays) {
    result.set(arr, offset);
    offset += arr.length;
  }
  return result;
}

// worker manager
// holds actual worker and callbacks generated
const WorkerManager = {
  worker: new Worker(CHERRY_IMAGE_WORKER_PATH),
  ids: 0,
  callbacks: new Map(),
};

// set max dimension to 60px nearest to pow2
const number = 60;
const byte1 = 0xff & number;
const byte2 = 0xff & (number >> 8);
const byte3 = 0xff & (number >> 16);
const byte4 = 0xff & (number >> 24);

const int8array = new Uint8Array([byte1, byte2, byte3, byte4]);
WorkerManager.worker.postMessage({
  funcName: 'set_maximum_dimension',
  callbackId: 0,
  data: int8array,
});
////

WorkerManager.worker.onerror = (e) => {
  console.error(e);
};

WorkerManager.worker.onmessage = function info_worker_onmessage(msg) {
  const callbackId = msg.data['callbackId'];
  const callbackInfo = WorkerManager.callbacks.get(callbackId);
  if (!callbackInfo) return;

  WorkerManager.callbacks.delete(callbackId);

  // data is in PNG form
  let data = msg.data['data'];

  if (data) {
    if (!data.byteLength) {
      data = new Uint8Array(data);
    }
    callbackInfo.resolve(data);
  } else {
    callbackInfo.reject();
  }
};

export const generateThumbnailFromBuffer = (
  buffer: Uint8Array
): Promise<Uint8Array> => {
  return new Promise((resolve, reject) => {
    if (buffer && WorkerManager.worker) {
      const LOD_LEVEL = 0; //max quality
      const callbackId = ++WorkerManager.ids; //increment return ids

      const transferObject = {
        funcName: 'image_load_1',
        callbackId: callbackId,
        data: concatenate(Uint8Array, buffer, Uint8Array.of(LOD_LEVEL)), // append LOD level (needed for unpacking)
      };

      WorkerManager.callbacks.set(callbackId, {
        resolve,
        reject,
      });

      WorkerManager.worker.postMessage(transferObject, [
        transferObject.data.buffer,
      ]);
    } else {
      reject();
    }
  });
};
