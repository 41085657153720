import { atom } from 'jotai';
import { atomFamily, atomWithReset, selectAtom } from 'jotai/utils';

import { LoadingStatus, StateObject, StateTypes } from 'models';

export const stateObjectAtom = atomWithReset({
  ASSETS: false,
  CONFIGURATIONS: false,
  CONSOLE: false,
  /**
   * This should be `true` by default in order to prevent accidental editor rendering until data is loaded.
   */
  EDITOR: true,
  HEADER: false,
  INSPECTOR: false,
  SCENES: false,
  SCENES_CHANGE: false,
  SCRIPT_EDITOR: false,
  VIEWER: false,
  MANAGER_RIGHT: false,
  MANAGER_CENTER: false,
  AUTH_FORM: false,
  HERO_SHOT: false,
  MODAL: false,
  USER: false,
  SUBSCRIPTION: false,
  IMPORT: false,
});

export const setStateAtom = atomFamily((scopes: StateTypes[]) => {
  return atom(null, (get, set, update: { value: LoadingStatus }) => {
    const { value } = update;

    set(stateObjectAtom, (values) => ({
      ...values,
      ...scopes.reduce(
        (p, n) => ({ ...p, [n]: value === LoadingStatus.LOADING }),
        {}
      ),
    }));
  });
});

export const getStateByScopeAtom = atomFamily((scope: StateTypes) =>
  selectAtom(stateObjectAtom, (values: StateObject) => values[scope])
);
