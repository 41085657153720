import { ShortcutConfig } from 'models/shortcut/shortcutConfig.model';

export const shouldTriggerDownShortcut = (
  event: KeyboardEvent,
  shortcut: ShortcutConfig
): boolean =>
  event.key.toLowerCase() === shortcut.key.toLowerCase() &&
  event.ctrlKey === shortcut.ctrlKey &&
  event.altKey === shortcut.altKey &&
  event.shiftKey === shortcut.shiftKey &&
  event.metaKey === shortcut.metaKey;

export const shouldTriggerUpShortcut = (
  event: KeyboardEvent,
  shortcut: ShortcutConfig
): boolean => event.key.toLowerCase() === shortcut.key.toLowerCase();
