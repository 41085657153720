import { FC } from 'react';
import { Link } from 'react-router-dom';

import Heading2 from 'common/Heading2/Heading2';

import classes from './PageNotFound.module.css';

const PageNotFound: FC = () => {
  return (
    <div className={classes.root}>
      <div className={classes.code}>404</div>
      <div className={classes.message}>
        <Heading2>Page not found!</Heading2>
      </div>

      <Link to="/manager">Go to Homepage</Link>
    </div>
  );
};

export default PageNotFound;
