import clsx from 'clsx';

/**
 * This function should be used to combine multiple classes
 * in a component.
 *
 * @example
 * ```ts
 * classMerge(
 *   'foo',
 *   {
 *     'bar': someTruthyValue,
 *     'xyz': someFalsyValue,
 *    }
 * )
 * // returns "foo bar"
 * ```
 *
 * You can also use it with CSS modules classes:
 *
 * @example
 * ```ts
 * classMerge(
 *   classes.foo,
 *   {
 *     [classes.bar]: someTruthyValue,
 *     [classes.xyz]: someFalsyValue,
 *    }
 * )
 * // returns "foo-12hdaj3 bar-c1jadj2"
 * ```
 */
export const classMerge = clsx;
