import { ReactNode } from 'react';
import { atom, WritableAtom } from 'jotai';

import ExceededLimitsModal from '_manager/_forms/NewProjectForm/components/ExceededLimitsModal';

export type ModalData = {
  content?: string | ReactNode;
  isOpen: boolean;
  key?: 'exceededLimits';
};

const atomWithToggle = (
  initialValue: ModalData | null
): WritableAtom<ModalData | null, ModalData | null> => {
  const anAtom = atom(initialValue, (get, set, nextValue: ModalData | null) => {
    let modalData = {
      ...nextValue,
    };

    if (nextValue?.key === 'exceededLimits') {
      modalData = {
        ...nextValue,
        content: <ExceededLimitsModal />,
      };
    }

    set(anAtom, modalData as ModalData | null);
  });

  return anAtom;
};

export const modalDataAtom = atomWithToggle(null);

export const isImportModalOpenAtom = atom(false);
export const isUpdateModalOpenAtom = atom(false);
export const isLodModalOpenAtom = atom(false);
export const isSaveAndRefreshModalOpenAtom = atom(false);
export const isDefineLodModalOpenAtom = atom(false);
