import * as yup from 'yup';

import { DEFAULT_DROPDOWN_VALUE } from 'configConstants';

export const industryValidation = yup
  .string()
  .required('Industry is required')
  .test(
    'is-industry-selected',
    'Industry is required',
    (value) => value !== DEFAULT_DROPDOWN_VALUE
  );
