import { FC } from 'react';

import { classMerge } from 'utils/classMerge';

import classes from './Spinner.module.css';

type Props = {
  size?: 'large' | 'normal' | 'small' | 'xsmall';
  color?: 'default' | 'white';
};

const Spinner: FC<Props> = ({ size = 'large', color = 'default' }) => {
  return (
    <div className={classMerge(classes.root, classes[size], classes[color])}>
      <div className={classes.inner} />
      <div className={classes.inner} />
      <div className={classes.inner} />
      <div className={classes.inner} />
    </div>
  );
};

export default Spinner;
