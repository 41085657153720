// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Body1-module__root--mNA3y {\n  font-size: var(--body1FontSize);\n  font-weight: 400;\n  letter-spacing: 0.5px;\n\n  overflow: inherit;\n  text-overflow: inherit;\n}\n\n.Body1-module__ellipsis--k6_NL {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n\n.Body1-module__capitalize--VEw_X {\n  text-transform: capitalize;\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/Body1/Body1.module.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,gBAAgB;EAChB,qBAAqB;;EAErB,iBAAiB;EACjB,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".root {\n  font-size: var(--body1FontSize);\n  font-weight: 400;\n  letter-spacing: 0.5px;\n\n  overflow: inherit;\n  text-overflow: inherit;\n}\n\n.ellipsis {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n\n.capitalize {\n  text-transform: capitalize;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Body1-module__root--mNA3y",
	"ellipsis": "Body1-module__ellipsis--k6_NL",
	"capitalize": "Body1-module__capitalize--VEw_X"
};
export default ___CSS_LOADER_EXPORT___;
