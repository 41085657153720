// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Body2-module__root--SFywt {\n  font-size: var(--body2FontSize);\n  font-weight: 400;\n  letter-spacing: 0.25px;\n\n  overflow: inherit;\n  text-overflow: inherit;\n}\n\n.Body2-module__ellipsis--gMISR {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/Body2/Body2.module.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,gBAAgB;EAChB,sBAAsB;;EAEtB,iBAAiB;EACjB,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".root {\n  font-size: var(--body2FontSize);\n  font-weight: 400;\n  letter-spacing: 0.25px;\n\n  overflow: inherit;\n  text-overflow: inherit;\n}\n\n.ellipsis {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Body2-module__root--SFywt",
	"ellipsis": "Body2-module__ellipsis--gMISR"
};
export default ___CSS_LOADER_EXPORT___;
