// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Modal-module__root--QJ7be {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.Modal-module__overlay--Jg7aZ {\n  content: '';\n  position: absolute;\n  left: 0;\n  top: 0;\n  width: 100%;\n  height: 100%;\n  background-color: var(--overlayBackground);\n  z-index: var(--zIndexModal);\n}\n\n.Modal-module__wrapper--H_Hr5 {\n  width: var(--formWrapperWidth);\n  margin: 0 auto;\n  border-radius: var(--borderRadius2);\n  overflow: hidden;\n  color: var(--onPrimary);\n  z-index: var(--zIndexModal);\n  background: var(--surface200);\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/Modal/Modal.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,OAAO;EACP,MAAM;EACN,WAAW;EACX,YAAY;EACZ,0CAA0C;EAC1C,2BAA2B;AAC7B;;AAEA;EACE,8BAA8B;EAC9B,cAAc;EACd,mCAAmC;EACnC,gBAAgB;EAChB,uBAAuB;EACvB,2BAA2B;EAC3B,6BAA6B;AAC/B","sourcesContent":[".root {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.overlay {\n  content: '';\n  position: absolute;\n  left: 0;\n  top: 0;\n  width: 100%;\n  height: 100%;\n  background-color: var(--overlayBackground);\n  z-index: var(--zIndexModal);\n}\n\n.wrapper {\n  width: var(--formWrapperWidth);\n  margin: 0 auto;\n  border-radius: var(--borderRadius2);\n  overflow: hidden;\n  color: var(--onPrimary);\n  z-index: var(--zIndexModal);\n  background: var(--surface200);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Modal-module__root--QJ7be",
	"overlay": "Modal-module__overlay--Jg7aZ",
	"wrapper": "Modal-module__wrapper--H_Hr5"
};
export default ___CSS_LOADER_EXPORT___;
