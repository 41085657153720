import {
  Asset,
  CherryProjectData,
  ConfigurationNode,
  Entities,
  HTMLHudNode,
  mergeConfigurationsIntoTree,
  ProjectData,
  TreeNode,
  World,
} from '@metavrse-inc/metavrse-lib';
import { atom } from 'jotai';
import { atomWithReset, RESET } from 'jotai/utils';

import { deepClone } from 'utils/deepCopy';

import { OpenProjectSections } from 'models/editor/openProjectProgress/openProjectSections.enum';
import { ProgressBarSectionContent } from 'models/progressBar/progressBarSectionContent.model';
import { ProgressBarSections } from 'models/progressBar/progressBarSections.model';

import { assetsAtom } from 'atoms/assets/assets.atom';
import { worldAtom } from 'atoms/editor/world';
import { entitiesAtom } from 'atoms/entities/entities.atom';
import { treeAtom } from 'atoms/tree/tree.atom';

import { configurationsTreeAtom } from '_configurations/atoms/configurations.atom';

import { htmlHudTreeAtom } from '_html-hud/atoms/htmlHudTree.atom';

export const initSectionsValues: ProgressBarSections<OpenProjectSections> = {
  LAZY_LOAD: {
    content: 'Loading editor...',
    finishedSectionProgress: 20,
  },
  CHECK_PROJECT: {
    content: 'Checking if project exists...',
    finishedSectionProgress: 40,
  },
  LOAD_PROJECT: {
    content: 'Loading project...',
    finishedSectionProgress: 50,
  },
  OPENING_PROJECT: {
    content: 'Opening project...',
    finishedSectionProgress: 60,
    innerSectionProgressValue: 50,
  },
  VERSIONING: {
    content: 'Checking project version...',
    finishedSectionProgress: 85,
  },
  RESTRUCTURE: {
    content: 'Loading project data...',
    finishedSectionProgress: 95,
  },
  PROJECT_LOADED: {
    content: 'Project loaded!',
    finishedSectionProgress: 100,
  },
};

export const projectAtom = atomWithReset<ProjectData>({
  id: '',
  incrementalId: 0,
  version: '0.0.0',
  title: '',
  createDate: '',
  updateDate: '',
  startingScene: '',
  selectedScene: '',
  scenes: [],
});
export const projectDataAtom = atomWithReset<CherryProjectData | null>(null);
export const projectLoadSectionAtom = atomWithReset<ProgressBarSectionContent>(
  initSectionsValues.LAZY_LOAD
);

export const resetProjectAtom = atom(null, (get, set) => {
  set(projectAtom, RESET);
  set(projectDataAtom, RESET);
  set(projectLoadSectionAtom, RESET);
});

type UpdateSection = {
  finished: OpenProjectSections;
  start?: OpenProjectSections;
  currentSection?: OpenProjectSections;
  innerSectionProgress?: number;
  innerSectionContent?: string;
};

export const updateLoadingSectionAtom = atom(
  null,
  (get, set, update: UpdateSection) => {
    const {
      finished,
      start,
      innerSectionContent,
      innerSectionProgress,
      currentSection,
    } = update;

    const initSectionsCopy = deepClone(initSectionsValues);

    if (currentSection && innerSectionContent && innerSectionProgress) {
      set(projectLoadSectionAtom, {
        ...initSectionsCopy[currentSection],
        content: innerSectionContent,
        innerSectionProgressValue: Math.floor(innerSectionProgress),
      });
    }

    if (finished && start) {
      set(projectLoadSectionAtom, {
        ...initSectionsCopy[start],
      });
    }
  }
);

const buildData = (
  project: ProjectData,
  tree: TreeNode[],
  configurationsTree: ConfigurationNode[],
  htmlHudTree: HTMLHudNode[],
  entities: Entities,
  world: World,
  assets: Asset[]
): CherryProjectData => {
  const data = {
    id: project.id,
    userid: 5,
    title: project.title,
    data: {
      version: project.version,
      title: project.title,
      scene: {
        [project.startingScene]: {
          tree: [
            ...mergeConfigurationsIntoTree(tree, configurationsTree),
            ...htmlHudTree,
            {
              key: 'world',
              title: 'World',
              type: 'world',
              visible: false,
              children: [],
            },
          ],
          data: {
            ...entities,
            world,
          },
        },
      },
      starting_scene: project.startingScene,
      assets: {
        tree: [...assets],
        data: {},
      },
      selected_scene: project.selectedScene,
    },
    deleted: false,
    createdAt: '2021-08-18T10:04:15.020Z',
    updatedAt: '2021-08-18T11:13:24.850Z',
  };

  return data as CherryProjectData;
};

export const generateProjectDataAtom = atom(
  (get) => {
    const project = get(projectAtom);
    const tree = get(treeAtom);
    const world = get(worldAtom);
    const entities = get(entitiesAtom);
    const assets = get(assetsAtom);
    const htmlHudTree = get(htmlHudTreeAtom);
    const configurationsTree = get(configurationsTreeAtom);

    return buildData(
      project,
      tree,
      configurationsTree,
      htmlHudTree,
      entities,
      world,
      assets
    );
  },
  (get, set) => {
    const project = get(projectAtom);
    const tree = get(treeAtom);
    const world = get(worldAtom);
    const entities = get(entitiesAtom);
    const assets = get(assetsAtom);
    const htmlHudTree = get(htmlHudTreeAtom);
    const configurationsTree = get(configurationsTreeAtom);

    set(
      projectDataAtom,
      buildData(
        project,
        tree,
        configurationsTree,
        htmlHudTree,
        entities,
        world,
        assets
      )
    );
  }
);

generateProjectDataAtom.onMount = (generateProjectData) => {
  generateProjectData();
};
