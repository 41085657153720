import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

import {
  NewWorkspaceUserRequest,
  WorkspaceUserResponse,
} from 'models/manager/workspace-users.model';
import { WorkspaceRoles } from 'models/workspaces';

const PATH = 'workspaces';

export const getWorkspaceUsers = (
  axios: AxiosInstance,
  workspaceId: number,
  responseHandler: (response: AxiosResponse<WorkspaceUserResponse[]>) => void,
  errorHandler: (error: AxiosError) => void
): void => {
  axios
    .get(`${PATH}/${workspaceId}/users`)
    .then(responseHandler)
    .catch(errorHandler);
};

export const createWorkspaceUser = (
  axios: AxiosInstance,
  workspaceId: number,
  newWorkspaceUser: NewWorkspaceUserRequest,
  responseHandler: (response: AxiosResponse<WorkspaceUserResponse>) => void,
  errorHandler: (error: AxiosError) => void
): void => {
  axios
    .post(`${PATH}/${workspaceId}/users`, newWorkspaceUser)
    .then(responseHandler)
    .catch(errorHandler);
};

export const deleteWorkspaceUser = (
  axios: AxiosInstance,
  workspaceId: number,
  userId: number,
  responseHandler: (response: AxiosResponse<WorkspaceUserResponse>) => void,
  errorHandler: (error: AxiosError) => void
): void => {
  axios
    .delete(`${PATH}/${workspaceId}/users/${userId}`)
    .then(responseHandler)
    .catch(errorHandler);
};

export const updateWorkspaceUser = (
  axios: AxiosInstance,
  workspaceId: number,
  userId: number,
  newRole: WorkspaceRoles,
  responseHandler: (response: AxiosResponse<WorkspaceUserResponse>) => void,
  errorHandler: (error: AxiosError) => void
): void => {
  axios
    .patch(`${PATH}/${workspaceId}/users/${userId}`, { role: newRole })
    .then(responseHandler)
    .catch(errorHandler);
};

export const getWorkspaceUsersWithoutProject = async (
  axios: AxiosInstance,
  workspaceId: number,
  projectId: number
): Promise<AxiosResponse<WorkspaceUserResponse[]>> => {
  return await axios.get(
    `${PATH}/${workspaceId}/users?projectId.not=${projectId}`
  );
};
