import * as yup from 'yup';

import { industryValidation } from 'validations/industryValidation';

import { emailValidation } from '../emailValidation';
import { nameValidation } from '../nameValidation';
import {
  passwordConfirmationValidation,
  passwordValidation,
} from '../passwordValidation';

export const signupSchema = yup.object().shape({
  name: nameValidation,
  email: emailValidation,
  password: passwordValidation,
  passwordConfirmation: passwordConfirmationValidation,
  industry: industryValidation,
});
