import { FC, KeyboardEvent, useState } from 'react';
import { ErrorMessage, Field, useField } from 'formik';

import { classMerge } from 'utils/classMerge';
import clearInputValue from 'utils/clearInputValue';

import { IconNoVisibility, IconVisibility } from 'icons';

import Body2 from 'common/Body2/Body2';
import IconButton from 'common/IconButton/IconButton';
import InputCharacterCounter from 'common/InputCharacterCounter/InputCharacterCounter';
import Label from 'common/Label/Label';

import classes from './FormInput.module.css';

type Props = {
  label: string;
  name: string;
  type?: string;
  rightAdornment?: string | number;
  maxLength?: number;
  additionalText?: string;
  onBlur?: () => void;
};

// TODO: Add handling asterisk if field is required
const FormInput: FC<Props> = ({
  label,
  type = 'text',
  rightAdornment,
  maxLength,
  onBlur,
  additionalText,
  ...props
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [field, meta, helpers] = useField(props);

  const handleClearInputValue = () => helpers.setValue('');
  const togglePasswordVisibility = () => setIsPasswordVisible((prev) => !prev);

  return (
    <div className={classes.root}>
      <div
        className={classMerge(classes.label, {
          [classes.labelError]: meta.touched && meta.error,
        })}
      >
        <Label name={field.name}>{label}</Label>
      </div>
      <div className={classes.wrapper}>
        <Field
          className={classMerge(classes.input, {
            [classes.inputError]: meta.touched && meta.error,
          })}
          type={type === 'password' && isPasswordVisible ? 'text' : type}
          name={field.name}
          placeholder={label}
          maxLength={maxLength}
          onBlur={onBlur}
          onKeyDown={(evt: KeyboardEvent<HTMLInputElement>) =>
            clearInputValue(handleClearInputValue, evt)
          }
        />
        {rightAdornment && (
          <div className={classes.rightAdornment}>{rightAdornment}</div>
        )}
        {!rightAdornment && maxLength && type !== 'password' && (
          <InputCharacterCounter
            length={Number(field.value.length)}
            maxLength={maxLength}
          />
        )}
        {type === 'password' && (
          <div className={classes.passwordEye}>
            <IconButton onClick={togglePasswordVisibility}>
              {!isPasswordVisible ? (
                <IconVisibility size={24} color="var(--onSurface100)" />
              ) : (
                <IconNoVisibility size={24} color="var(--onSurface100)" />
              )}
            </IconButton>
          </div>
        )}
      </div>
      {!(meta.error && meta.touched) && (
        <div className={classes.additionalText}>
          <Body2>{additionalText}</Body2>
        </div>
      )}
      <ErrorMessage
        name={field.name}
        render={(msg) => (
          <div className={classes.error}>
            <Body2>{msg}</Body2>
          </div>
        )}
      />
    </div>
  );
};

export default FormInput;
