import { WorkspaceLimitsErrors } from 'models/manager/workspaces/workspaceLimitsErrors.model';
import { WorkspaceLimitsUsageResponse } from 'models/manager/workspaces/workspaceLimitsUsageResponse.model';

const addErrorMessage = (
  usage: number,
  limit: WorkspaceLimitsUsageResponse,
  percentageTypeValue: number,
  warningType: string
): WorkspaceLimitsErrors | undefined => {
  if (usage >= percentageTypeValue) {
    return {
      ...limit,
      usageErrorMessage: `You are using more than ${percentageTypeValue}% available ${warningType}`,
    } as WorkspaceLimitsErrors;
  }
};

export default addErrorMessage;
