import { ConfigurationNode } from '@metavrse-inc/metavrse-lib';

import {
  BASE_PATH,
  CONFIGURATIONS_FILENAME,
  SCENES_FOLDER,
} from 'configConstants';

import { fsp } from 'services/fs.service';
import { convertToJSON } from 'services/helpers';

export const readConfigurationsFile = async (
  key: string
): Promise<ConfigurationNode[] | null> => {
  try {
    const data = await fsp.readFile(
      `${BASE_PATH}${SCENES_FOLDER}/${key}/${CONFIGURATIONS_FILENAME}`
    );

    if (typeof data === 'string') {
      return null;
    }

    return convertToJSON<ConfigurationNode[]>(data);
  } catch (error) {
    console.log('ERROR readConfigurationsFile: ', error);
    return null;
  }
};

export const writeConfigurationsFile = async (
  key: string,
  data: ConfigurationNode[]
): Promise<void | null> => {
  try {
    const files = await fsp.readdir(`${BASE_PATH}${SCENES_FOLDER}`);
    const sceneFolderExists = files.some((f) => f === key);
    if (!sceneFolderExists) {
      await fsp.mkdir(`${BASE_PATH}${SCENES_FOLDER}/${key}/`);
    }
    await fsp.writeFile(
      `${BASE_PATH}${SCENES_FOLDER}/${key}/${CONFIGURATIONS_FILENAME}`,
      new TextEncoder().encode(JSON.stringify(data))
    );
  } catch (error) {
    console.log('ERROR writeConfigurationsFile: ', error);
    return null;
  }
};
