// import './wdyr';

import ReactDOM from 'react-dom';
import { ErrorBoundary, Provider as RollbarProvider } from '@rollbar/react';
import { Provider as AtomProvider } from 'jotai';
import Rollbar from 'rollbar';

import './assets/styles/fonts.css';
import './assets/styles/base.css';
import './assets/styles/variables.module.css';
import './assets/styles/typography.module.css';

import App from './App';

const rollbarConfig: Rollbar.Configuration = {
  accessToken: process.env.ROLLBAR_ACCESS_TOKEN,
  enabled: process.env.APP_ENVIRONMENT !== 'development',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.APP_ENVIRONMENT,
    client: {
      javascript: {
        source_map_enabled: true,
      },
    },
  },
};

ReactDOM.render(
  <RollbarProvider config={rollbarConfig}>
    <ErrorBoundary>
      <AtomProvider>
        <App />
      </AtomProvider>
    </ErrorBoundary>
  </RollbarProvider>,
  document.getElementById('root')
);
