import { FC } from 'react';
import { Form, Formik } from 'formik';
import { useUpdateAtom } from 'jotai/utils';

import { initiatePasswordResetAtom } from 'atoms/manager/users';

import Button from 'common/Button/Button';
import FormInput from 'common/FormInput/FormInput';
import Subtitle2 from 'common/Subtitle2/Subtitle2';

import { emailValidation } from 'validations/emailValidation';

import AuthBox from '_account/AuthBox/AuthBox';

import classes from './ForgotPassword.module.css';

const ForgotPassword: FC = () => {
  const initiatePasswordReset = useUpdateAtom(initiatePasswordResetAtom);
  const handleSendResetPasswordEmail = (values: { email: string }) => {
    initiatePasswordReset(values.email);
  };

  return (
    <AuthBox heading="Forgot password?">
      <div className={classes.wrapper}>
        <div className={classes.heading}>
          <Subtitle2>
            You will receive an email with the recovery link
          </Subtitle2>
        </div>
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={emailValidation}
          validateOnMount={false}
          onSubmit={handleSendResetPasswordEmail}
        >
          {({ handleSubmit }) => (
            <Form className={classes.form} onSubmit={handleSubmit}>
              <FormInput name="email" label="Email" />
              <div className={classes.actions}>
                <Button variant="primary" type="submit">
                  Send
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </AuthBox>
  );
};

export default ForgotPassword;
