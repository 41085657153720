import { FC } from 'react';
import { useAtomValue } from 'jotai/utils';

import { snackbarAtom } from 'atoms/snackbars/snackbars.atom';

import Snackbar from 'common/Snackbar/Snackbar';

import classes from './SnackbarsWrapper.module.css';

const SnackbarsWrapper: FC = () => {
  const snackbarData = useAtomValue(snackbarAtom);

  return (
    <div className={classes.root}>
      {snackbarData.map(
        ({ title, body, type, key, isProgress, isVisible = true }, index) =>
          isVisible && (
            <Snackbar
              key={key}
              snackbarKey={key as number}
              title={title}
              body={body}
              type={type}
              index={index}
              isProgress={isProgress ? isProgress : false}
            />
          )
      )}
    </div>
  );
};

export default SnackbarsWrapper;
