import { CherryKey } from '@metavrse-inc/metavrse-lib';
import create from 'zustand';

export const editorStore = create(() => ({ incrementalId: 0 }));

export const setIncrementalId = (id: number): void =>
  editorStore.setState(() => {
    return { incrementalId: id };
  });

export const getIncrementalId = (dummy: string = ""): CherryKey => {
  editorStore.setState(({ incrementalId }) => {
    return { incrementalId: incrementalId + 1 };
  });
  const id = editorStore.getState().incrementalId;
  return `${id}`;
};
