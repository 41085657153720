import { GitHttpRequest, GitHttpResponse } from 'isomorphic-git';

function fromStream(stream: ReadableStream): unknown {
  const reader = stream.getReader();
  return {
    next() {
      return reader.read();
    },
    return() {
      reader.releaseLock();
      return {};
    },
    [Symbol.asyncIterator]() {
      return this;
    },
  };
}

async function collect(iterable: Iterable<any>) {
  let size = 0;
  const buffers = [];

  for await (const value of iterable) {
    buffers.push(value);
    size += value.byteLength;
  }

  const result = new Uint8Array(size);

  let nextIndex = 0;

  for (const buffer of buffers) {
    result.set(buffer, nextIndex);
    nextIndex += buffer.byteLength;
  }
  return result;
}

export async function request(
  request: GitHttpRequest
): Promise<GitHttpResponse> {
  let body;
  if (request.body) {
    body = await collect(request.body);
  }
  const response = await fetch(request.url, {
    credentials: 'include',
    method: request.method,
    headers: request.headers,
    body,
  });

  const iter =
    response.body && response.body.getReader
      ? fromStream(response.body)
      : [new Uint8Array(await response.arrayBuffer())];

  const headers: { [key: string]: string } = {};

  response.headers.forEach((value: string, key: string): void => {
    headers[key] = value;
  });

  return {
    url: response.url,
    method: request.method,
    statusCode: response.status,
    statusMessage: response.statusText,
    body: iter,
    headers: headers,
  };
}

export default { request };
