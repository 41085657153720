import { FC } from 'react';

import { classMerge } from 'utils/classMerge';

import classes from './Body1.module.css';

type Props = {
  ellipsis?: boolean;
  capitalize?: boolean;
};
const Body1: FC<Props> = ({ children, ellipsis, capitalize }) => {
  return (
    <div
      className={classMerge(classes.root, {
        [classes.ellipsis]: ellipsis,
        [classes.capitalize]: capitalize,
      })}
    >
      {children}
    </div>
  );
};

export default Body1;
