import { FC } from 'react';

import { classMerge } from 'utils/classMerge';

import classes from './Subtitle2.module.css';

type Props = {
  bold?: boolean;
};

const Subtitle2: FC<Props> = ({ children, bold }) => {
  return (
    <div
      className={classMerge(classes.root, {
        [classes.bold]: bold,
      })}
    >
      {children}
    </div>
  );
};

export default Subtitle2;
