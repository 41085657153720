import { Asset, CherryKey } from '@metavrse-inc/metavrse-lib';
import { atom } from 'jotai';
import { atomWithReset, RESET } from 'jotai/utils';

import { deepClone } from 'utils/deepCopy';

import { ITargetPath } from 'models/entity/common';

import assetsHelpers from 'atoms/helpers/assets-helpers';
import { searchTree } from 'atoms/helpers/common/searchTree';
import commonHelpers from 'atoms/helpers/common-helpers';

import { LevelOfDetail } from '_assets/DefineLevelsOfDetail/DefineLevelsOfDetail';

import { FlattenedAsset } from './searchAssets.atom';

export const assetsAtom = atomWithReset<Asset[]>([
  {
    key: '0',
    title: '.primitives',
    type: 'folder',
    hidden: true,
    children: [],
  },
]);
export const assetPathAtom = atomWithReset<ITargetPath>([]);
export const previousAssetsAtom = atomWithReset<Asset[]>([]);
export const removedAssetKeysAtom = atomWithReset<string[]>([]);

export const assetToUpdateKeyAtom = atomWithReset<CherryKey | null>(null);
export const assetToAddLodKeyAtom = atomWithReset<CherryKey | null>(null);
export const searchAssetsTermAtom = atomWithReset<string>('');
export const searchViewerAssetsTermAtom = atomWithReset('');
export const editableAssetCopyAtom = atomWithReset<{
  asset: Asset;
  assetFile: File[];
} | null>(null);

export const levelsOfDetailAtom = atom<LevelOfDetail[]>([]);

export const selectedAssetPathAtom = atom<ITargetPath, CherryKey>(
  (get) => {
    return get(assetPathAtom);
  },
  (get, set, update) => {
    const assets = get(assetsAtom);
    const path = commonHelpers.getPathBy(update, assets);
    set(assetPathAtom, path);
  }
);

export const resetAssetsAtom = atom(null, (get, set) => {
  set(assetsAtom, RESET);
  set(previousAssetsAtom, RESET);
  set(assetPathAtom, RESET);
  set(searchAssetsTermAtom, RESET);
  set(searchViewerAssetsTermAtom, RESET);
  set(editableAssetCopyAtom, RESET);
});

export const clearAssetsAtom = atom(null, (get, set) => {
  set(assetsAtom, []);
  set(previousAssetsAtom, []);
  set(assetPathAtom, []);
  set(searchAssetsTermAtom, '');
  set(searchViewerAssetsTermAtom, '');
  set(editableAssetCopyAtom, null);
  set(breadCrumbsIndexesAtom, []);
});

export const searchAssetsTreeAtom = atom<Asset[]>((get) => {
  const tree = get(assetsAtom);
  const filterText = get(searchAssetsTermAtom);

  return searchTree(tree, filterText);
});

export const assetToUpdateAtom = atom<Asset | null, CherryKey | typeof RESET>(
  (get) => {
    const assets = get(assetsAtom);
    const key = get(assetToUpdateKeyAtom);
    const flattenedAssets = assets.flatMap(
      assetsHelpers.flattenNodes as any
    ) as FlattenedAsset[];

    return key ? flattenedAssets.find((asset) => asset.key === key) : null;
  },
  (_get, set, key) => {
    set(assetToUpdateKeyAtom, key);
  }
);

export const assetToAddLodAtom = atom<Asset | null, CherryKey | typeof RESET>(
  (get) => {
    const assets = get(assetsAtom);
    const key = get(assetToAddLodKeyAtom);
    const flattenedAssets = assets.flatMap(
      assetsHelpers.flattenNodes as any
    ) as FlattenedAsset[];

    return key ? flattenedAssets.find((asset) => asset.key === key) : null;
  },
  (_get, set, key) => {
    set(assetToAddLodKeyAtom, key);
  }
);

export const fillAssetsAtom = atom(null, (get, set, update: Asset[]) => {
  set(assetsAtom, update);
});

export const breadCrumbsIndexesAtom = atom<number[]>([]);

export const breadCrumbsAtom = atom((get) => {
  const assets = get(assetsAtom);
  const targetPath = get(breadCrumbsIndexesAtom);
  return assetsHelpers.getKeyValuePairsFrom(assets, targetPath);
});

export const filteredByIndexesAssetsAtom = atom((get) => {
  const assets = get(assetsAtom);
  const targetPath = get(breadCrumbsIndexesAtom);

  const assetsWithIndexes = assetsHelpers.appendIndexesTo(
    deepClone(assets) as FlattenedAsset[],
    []
  );

  return assetsHelpers.getChildrenFrom(assetsWithIndexes, targetPath);
});
