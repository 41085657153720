import { CherryKey, HTMLHudNode } from '@metavrse-inc/metavrse-lib';

import {
  BASE_PATH,
  HTML_HUD_TREE_FILENAME,
  SCENES_FOLDER,
} from 'configConstants';

import { fsp } from 'services/fs.service';
import { convertToJSON } from 'services/helpers';

export const readHTMLHudTreeFile = async (
  key: CherryKey
): Promise<HTMLHudNode[] | null> => {
  try {
    const data = await fsp.readFile(
      `${BASE_PATH}${SCENES_FOLDER}/${key}/${HTML_HUD_TREE_FILENAME}`
    );

    if (typeof data === 'string') {
      return null;
    }

    return convertToJSON<HTMLHudNode[]>(data);
  } catch (error) {
    console.log('ERROR readHTMLHudTreeFile: ', error);
    return null;
  }
};

export const writeHTMLHudTreeFile = async (
  key: CherryKey,
  data: HTMLHudNode[]
): Promise<void | null> => {
  try {
    await fsp.writeFile(
      `${BASE_PATH}${SCENES_FOLDER}/${key}/${HTML_HUD_TREE_FILENAME}`,
      new TextEncoder().encode(JSON.stringify(data))
    );
  } catch (error) {
    console.log('ERROR writeHTMLHudTreeFile: ', error);
    return null;
  }
};
