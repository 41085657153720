import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

import { UserActivation } from 'models/manager/users/userActivation.model';
import { SignedInUserDataResponse } from 'models/user/signedInUserDataResponse.model';
import { SignInUserRequest } from 'models/user/signInUserRequest.model';
import { TotpSignInUserRequest } from 'models/user/totpSignInUserRequest.model';

const PATH = 'users';
const SIGN_UP_RESOURCE = 'sign-up';
const SIGN_IN_RESOURCE = 'sign-in';
const TOTP_SIGN_IN_RESOURCE = 'sign-in-totp';
const SIGN_OUT_RESOURCE = 'sign-out';
const CURRENT_USER_DATA_RESOURCE = 'current-user-data';
const ACTIVATE_RESOURCE = 'activate';
const RESET_PASSWORD_RESOURCE = 'confirm-password-reset';
const INITIATE_PASSWORD_RESET_RESOURCE = 'initiate-password-reset';

/*
 users/sign-up
*/
export interface IUserResponse {
  freeProductStart: number;
  id: number;
  username: string;
  name: string;
  email: string;
  stripeId: string;
  resetPasswordToken: string;
  resetPasswordExpire: number;
  lastLoggedAt: string;
  industry: string;
  createdAt: string;
  updatedAt: string;
}

export interface IApiResponse {
  userId: number;
  expiresAt: string;
  key: string;
}

export interface ISignUpUserRequest {
  name: string;
  email: string;
  password: string;
  industry: string;
  //TODO: [MET-835] Remove optional once BE will be working
  passwordConfirmation?: string;
}

export const signUp = (
  axios: AxiosInstance,
  data: ISignUpUserRequest,
  responseHandler: (response: AxiosResponse<SignedInUserDataResponse>) => void,
  errorHandler: (error: AxiosError) => void
): void => {
  axios
    .post(`${PATH}/${SIGN_UP_RESOURCE}`, data)
    .then(responseHandler)
    .catch(errorHandler);
};

/*
 users/sign-in
*/

export const signIn = async (
  axios: AxiosInstance,
  data: SignInUserRequest
): Promise<AxiosResponse<SignedInUserDataResponse>> => {
  return await axios.post(`${PATH}/${SIGN_IN_RESOURCE}`, data);
};

/*
 users/sign-in-totp
*/
export const totpSignIn = async (
  axios: AxiosInstance,
  data: TotpSignInUserRequest,
  token: string
): Promise<AxiosResponse<SignedInUserDataResponse>> => {
  return await axios.post(`${PATH}/${TOTP_SIGN_IN_RESOURCE}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

/*
 users/sign-out
*/
export const signOut = (
  axios: AxiosInstance,
  responseHandler: () => void,
  errorHandler: (error: AxiosError) => void
): void => {
  axios
    .get(`${PATH}/${SIGN_OUT_RESOURCE}`)
    .then(responseHandler)
    .catch(errorHandler);
};

/*
 users/current-user-data
*/
export const currentUserData = (
  axios: AxiosInstance,
  responseHandler: (response: AxiosResponse<SignedInUserDataResponse>) => void,
  errorHandler: (error: AxiosError) => void
): void => {
  axios
    .get(`${PATH}/${CURRENT_USER_DATA_RESOURCE}`)
    .then(responseHandler)
    .catch(errorHandler);
};

/*
 DELETE users/
*/

export const deleteAccount = async (
  axios: AxiosInstance
): Promise<AxiosResponse<void>> => {
  return await axios.delete(`${PATH}`);
};

/*
  PATCH users/:id/
*/
export interface IUpdateUserRequest {
  name?: string;
  email?: string;
  currentPassword?: string;
  password?: string;
}

export type IUpdateUserNameRequest = Required<Pick<IUpdateUserRequest, 'name'>>;

export type IUpdateUserEmailRequest = Required<
  Pick<IUpdateUserRequest, 'email'>
>;

export type IUpdateUserPasswordRequest = Required<
  Pick<IUpdateUserRequest, 'password' | 'currentPassword'>
>;

export const updateUser = (
  axios: AxiosInstance,
  userId: number,
  data: IUpdateUserRequest,
  responseHandler: (response: AxiosResponse<SignedInUserDataResponse>) => void,
  errorHandler: (error: AxiosError) => void
): void => {
  axios
    .patch(`${PATH}/${userId}`, data)
    .then(responseHandler)
    .catch(errorHandler);
};

/*
  POST users/confirm-password-reset/
*/

export interface IResetPasswordRequest {
  passwordResetToken: string;
  newPassword: string;
}

export const resetPasswordReset = async (
  axios: AxiosInstance,
  data: IResetPasswordRequest
): Promise<AxiosResponse> => {
  return await axios.post(`${PATH}/${RESET_PASSWORD_RESOURCE}`, data);
};

/*
  POST users/activate/
*/
export const activateAccount = async (
  axios: AxiosInstance,
  data: UserActivation
): Promise<AxiosResponse> => {
  return await axios.post(`${PATH}/${ACTIVATE_RESOURCE}`, data);
};

/*
  POST users/initiate-password-reset/
*/

export interface IInitiatePasswordResetRequest {
  email: string;
}

export const initiatePasswordReset = async (
  axios: AxiosInstance,
  data: IInitiatePasswordResetRequest
): Promise<AxiosResponse> => {
  return await axios.post(`${PATH}/${INITIATE_PASSWORD_RESET_RESOURCE}`, data);
};
