import { ShortcutName } from 'models/shortcut/shortcutName.enum';

type Config = { keys: string | string[] };

const isMac = navigator.platform.indexOf('Mac') === 0;

export const SHORTCUT_CONFIG: Record<ShortcutName, Config> = {
  [ShortcutName.SAVE_PROJECT]: {
    keys: ['meta + s', 'ctrl + s'],
  },
  [ShortcutName.SAVE_CODE_EDITOR_CONTENT]: {
    keys: 'shift + e',
  },
  [ShortcutName.SHOW_SCALE]: {
    keys: 'shift',
  },
  [ShortcutName.SCALE_ONE_AXIS]: {
    keys: ['Control + shift + ctrl', 'Shift + ctrl + shift'],
  },
  [ShortcutName.ZOOM_DEFAULT]: {
    keys: ['ctrl + 0', 'meta + 0'],
  },
  [ShortcutName.ZOOM_INCREASE]: {
    keys: ['ctrl + +', 'meta + +', 'meta + =', 'ctrl + ='],
  },
  [ShortcutName.ZOOM_DECREASE]: {
    keys: ['ctrl + -', 'meta + -', 'meta + _', 'ctrl + _'],
  },
  [ShortcutName.ZOOM_TO_OBJECT]: {
    keys: 'f',
  },
  [ShortcutName.COPY]: {
    keys: ['ctrl + c', 'meta + c'],
  },
  [ShortcutName.PASTE]: {
    keys: ['ctrl + v', 'meta + v'],
  },
  [ShortcutName.ADD_FROM_SCENE_TO_CONFIG]: {
    keys: isMac ? 'meta' : 'Control + ctrl',
  },
};

Object.freeze(SHORTCUT_CONFIG);
