import { atom } from 'jotai';

import { LoadingStatus, StateTypes } from 'models';
import { Option } from 'models/dropdown';
import SnackbarType from 'models/snackbars/snackbarType';

import { axiosInstance } from 'services/axios';
import {
  createOrganizationUser,
  deleteOrganizationUser,
  getOrganizationUsers,
  INewUserDataRequest,
  IOrganizationSuperadminResponse,
} from 'services/organization-users';
import {
  getOrganization,
  getOrganizations,
  IOrganizationDetailsResponse,
  IUpdateOrganizationDetailsRequest,
  updateOrganization,
} from 'services/organizations';

import { userDataAtom } from 'atoms/manager/users';
import { setSnackbarAtom } from 'atoms/snackbars/snackbars.atom';
import { setStateAtom } from 'atoms/state';

export const organizationsDetailsAtom = atom<
  IOrganizationDetailsResponse[] | null
>(null);
export const singleOrganizationAtom = atom<IOrganizationDetailsResponse | null>(
  null
);
export const selectedOrganizationAtom =
  atom<IOrganizationDetailsResponse | null>(null);
export const organizationSuperadminsAtom = atom<
  IOrganizationSuperadminResponse[] | null
>(null);

const { MANAGER_RIGHT, MANAGER_CENTER } = StateTypes;
const { LOADING, READY } = LoadingStatus;
const { SUCCESS, ERROR } = SnackbarType;

export const getSingleOrganizationAtom = atom(
  null,
  async (get, set, organizationId: number) => {
    try {
      const response = await getOrganization(axiosInstance, organizationId);
      set(singleOrganizationAtom, response.data);
    } catch (error: any) {
      set(setSnackbarAtom, {
        title: 'Error',
        body: error,
        type: ERROR,
      });
    }
  }
);

export const getOrganizationsDetailsAtom = atom(null, (get, set) => {
  const organizationsDetails = get(organizationsDetailsAtom);
  const userId = get(userDataAtom)?.user.id;

  if (organizationsDetails || !userId) {
    return;
  }

  set(setStateAtom([MANAGER_CENTER]), { value: LOADING });

  getOrganizations(
    axiosInstance,
    { userId },
    (res) => {
      const organizationsData = res.data.map((org) => org.organization);

      set(organizationsDetailsAtom, organizationsData);
      set(setStateAtom([MANAGER_CENTER]), { value: READY });
    },
    () => {
      set(setStateAtom([MANAGER_CENTER]), { value: READY });
    }
  );
});

export const updateOrganizationDetailsAtom = atom(
  null,
  (get, set, organizationDetails: IUpdateOrganizationDetailsRequest) => {
    set(setStateAtom([MANAGER_CENTER, MANAGER_RIGHT]), {
      value: LOADING,
    });

    const id = get(selectedOrganizationAtom)?.id;

    if (!id) {
      set(setStateAtom([MANAGER_CENTER, MANAGER_RIGHT]), {
        value: READY,
      });
      return;
    }

    updateOrganization(
      axiosInstance,
      id,
      organizationDetails,
      (res) => {
        const unchangedOrganizations =
          get(organizationsDetailsAtom)?.filter((org) => org.id !== id) || [];

        set(organizationsDetailsAtom, [...unchangedOrganizations, res.data]);
        set(selectedOrganizationAtom, res.data);

        set(setStateAtom([MANAGER_CENTER, MANAGER_RIGHT]), {
          value: READY,
        });
        set(setSnackbarAtom, {
          title: 'Organization data updated',
          body: "Organization's name was changed successfully",
          type: SUCCESS,
        });
      },
      (error) => {
        set(setStateAtom([MANAGER_CENTER, MANAGER_RIGHT]), {
          value: READY,
        });
        set(setSnackbarAtom, {
          title: 'Error',
          body: error.response?.data.message || 'There was an error',
          type: ERROR,
        });
      }
    );
  }
);

export const deleteSuperadminAtom = atom(
  null,
  (get, set, superadminId: number) => {
    set(setStateAtom([MANAGER_CENTER, MANAGER_RIGHT]), {
      value: LOADING,
    });

    const mainOrganizationId = get(selectedOrganizationAtom)?.id;

    if (!mainOrganizationId) {
      set(setStateAtom([MANAGER_CENTER, MANAGER_RIGHT]), {
        value: READY,
      });
      return;
    }

    deleteOrganizationUser(
      axiosInstance,
      mainOrganizationId,
      superadminId,
      () => {
        const updatedSuperadmins =
          get(organizationSuperadminsAtom)?.filter(
            (admin) => admin.userId !== superadminId
          ) || [];

        set(organizationSuperadminsAtom, updatedSuperadmins);

        set(setStateAtom([MANAGER_CENTER, MANAGER_RIGHT]), {
          value: READY,
        });
        set(setSnackbarAtom, {
          title: 'Delete successful',
          body: 'Superadmin was deleted',
          type: SUCCESS,
        });
      },
      (error) => {
        set(setStateAtom([MANAGER_CENTER, MANAGER_RIGHT]), {
          value: READY,
        });
        set(setSnackbarAtom, {
          title: 'Error',
          body: error.response?.data.message || 'There was an error',
          type: ERROR,
        });
      }
    );
  }
);

export const addSuperadminAtom = atom(
  null,
  (get, set, superadminData: INewUserDataRequest) => {
    set(setStateAtom([MANAGER_CENTER, MANAGER_RIGHT]), {
      value: LOADING,
    });
    const mainOrganizationId = get(selectedOrganizationAtom)?.id;

    if (!mainOrganizationId) {
      set(setStateAtom([MANAGER_CENTER, MANAGER_RIGHT]), {
        value: READY,
      });
      return;
    }

    createOrganizationUser(
      axiosInstance,
      mainOrganizationId,
      superadminData,
      (res) => {
        const existingSuperadmins = get(organizationSuperadminsAtom) || [];
        set(organizationSuperadminsAtom, [...existingSuperadmins, res.data]);

        set(setStateAtom([MANAGER_CENTER, MANAGER_RIGHT]), {
          value: READY,
        });

        set(setSnackbarAtom, {
          title: 'Superadmin added',
          body: 'Superadmin was added',
          type: SUCCESS,
        });
      },
      (error) => {
        set(setStateAtom([MANAGER_CENTER, MANAGER_RIGHT]), {
          value: READY,
        });
        set(setSnackbarAtom, {
          title: 'Error',
          body: error.response?.data.message || 'There was an error',
          type: ERROR,
        });
      }
    );
  }
);

export const organizationsForDropdownAtom = atom<Option[]>((get) => {
  const organizations = get(organizationsDetailsAtom) || [];

  const parsed =
    organizations?.map((org) => ({
      label: org.name,
      value: org.id,
    })) || [];

  return parsed;
});

export const selectOrganizationAtom = atom(
  null,
  (get, set, organization: IOrganizationDetailsResponse | null) => {
    const previousId = get(selectedOrganizationAtom)?.id;

    if (!organization) {
      set(selectedOrganizationAtom, organization);
      return;
    }

    if (previousId === organization.id) {
      return;
    }

    set(setStateAtom([MANAGER_RIGHT]), {
      value: LOADING,
    });
    set(selectedOrganizationAtom, organization);

    getOrganizationUsers(
      axiosInstance,
      organization.id,
      (res) => {
        set(organizationSuperadminsAtom, res.data);

        set(setStateAtom([MANAGER_RIGHT]), {
          value: READY,
        });
      },
      () => {
        set(setStateAtom([MANAGER_RIGHT]), {
          value: READY,
        });
      }
    );
  }
);

export const closeOrganizationAsideAtom = atom(null, (get, set) => {
  set(selectedOrganizationAtom, null);
  set(organizationSuperadminsAtom, null);
});
