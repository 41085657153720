import { AssetType } from '@metavrse-inc/metavrse-lib';

const readFileFormArrayBuffer = (
  file: File,
  type: Omit<AssetType, 'folder'>
): Promise<ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener('loadend', () =>
      resolve(reader.result as ArrayBuffer)
    );
    reader.addEventListener('error', reject);

    reader.readAsArrayBuffer(file);
  });
};

export const generateThumbnail = (buffer: Uint8Array): string => {
  const base64 = Buffer.from(buffer).toString('base64');
  const BASE64_PREFIX = `data:image/png;base64,`;
  return `${BASE64_PREFIX}${base64}`;
};

export default {
  readFileFormArrayBuffer,
};
