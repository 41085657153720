import { atom } from 'jotai';

import { mapShortcutToKeyConfig } from 'utils/shortcut/shortcut.utils';

import { SHORTCUT_CONFIG } from 'shortcutConfig';

import { ShortcutConfig } from 'models/shortcut/shortcutConfig.model';
import { ShortcutName } from 'models/shortcut/shortcutName.enum';

export const shortcutListAtom = atom<ShortcutConfig[]>([]);

type ShortcutInfo = {
  name: ShortcutName;
  keyDownCb: (e: KeyboardEvent) => void;
  keyUpCb?: (e: KeyboardEvent) => void;
};

export const registerShortcutAtom = atom(
  (get) => get(shortcutListAtom),
  (get, set, { name, keyDownCb, keyUpCb }: ShortcutInfo) => {
    const currentList = get(shortcutListAtom);
    const { keys } = SHORTCUT_CONFIG[name];

    if (!Array.isArray(keys)) {
      set(shortcutListAtom, [
        ...currentList,
        { ...mapShortcutToKeyConfig(keys), keyDownCb, name, keyUpCb },
      ]);
    } else {
      for (let i = 0; i < keys.length; i++) {
        set(shortcutListAtom, (prev) => [
          ...prev,
          {
            ...mapShortcutToKeyConfig(keys[i]),
            keyDownCb,
            name,
            keyUpCb,
          },
        ]);
      }
    }
  }
);

export const unregisterShortcutAtom = atom(
  (get) => get(shortcutListAtom),
  (get, set, name: ShortcutName) => {
    const currentList = get(shortcutListAtom);
    set(
      shortcutListAtom,
      currentList.filter((s) => s.name !== name)
    );
  }
);
