import { HTMLHudNode } from '@metavrse-inc/metavrse-lib';
import { atom } from 'jotai';
import { atomWithReset, RESET } from 'jotai/utils';

import { ITargetPath } from 'models/entity/common';

import { searchTree } from 'atoms/helpers/common/searchTree';
import commonHelpers from 'atoms/helpers/common-helpers';

export const htmlHudTreeAtom = atomWithReset<HTMLHudNode[]>([]);
export const selectedHTMLHudPathAtom = atomWithReset<ITargetPath>([]);
export const selectedHTMLElementAtom = atomWithReset<ITargetPath>([]);
export const searchHtmlHudTreeTermAtom = atomWithReset('');
export const htmlHudTreeCopyPath = atomWithReset<ITargetPath | null>(null);

type CreatePayload = { targetPath: ITargetPath; newNode: HTMLHudNode };
/**
 * This atom is used to update the `htmlHudTreeAtom` with new node.
 */
export const createHTMLHudTreeNodeAtom = atom<null, CreatePayload>(
  null,
  (get, set, { targetPath, newNode }) => {
    const hudTree = get(htmlHudTreeAtom);
    const newTree = commonHelpers.addTo(hudTree, newNode, targetPath);

    set(htmlHudTreeAtom, newTree);
  }
);

type UpdatePayload = { targetPath: ITargetPath; node: HTMLHudNode };
/**
 * This atom is used to update the `htmlHudTreeAtom` with updated node.
 */
export const updateHTMLHudTreeNodeAtom = atom<null, UpdatePayload>(
  null,
  (get, set, { targetPath, node }) => {
    const hudTree = get(htmlHudTreeAtom);
    const newTree = commonHelpers.updateNodeBy(targetPath, node, hudTree);

    set(htmlHudTreeAtom, newTree);
  }
);

type RemovePayload = HTMLHudNode;
/**
 * This atom is used to remove a node from the `htmlHudTreeAtom`.
 */
export const removeHTMLHudTreeNodeAtom = atom<null, RemovePayload>(
  null,
  (get, set, update) => {
    const hudTree = get(htmlHudTreeAtom);
    const targetPath = commonHelpers.getPathBy(update.key, hudTree);
    const nodeToRemove = commonHelpers.getNodeBy(targetPath, hudTree);

    if (nodeToRemove) {
      const newTree = commonHelpers.removeNode(targetPath, hudTree);

      set(htmlHudTreeAtom, newTree);
    }
  }
);

export const filteredHtmlHudTreeAtom = atom((get) => {
  const hudTree = get(htmlHudTreeAtom);
  const filterText = get(searchHtmlHudTreeTermAtom);

  return searchTree(hudTree, filterText);
});

export const resetHTMLHudAtom = atom(null, (get, set) => {
  set(htmlHudTreeAtom, RESET);
  set(selectedHTMLHudPathAtom, RESET);
  set(selectedHTMLElementAtom, RESET);
  set(searchHtmlHudTreeTermAtom, RESET);
  set(htmlHudTreeCopyPath, RESET);
});
