import { OldProjectData, ProjectData } from '@metavrse-inc/metavrse-lib';

import { BASE_PATH, PROJECT_FILENAME } from 'configConstants';

import { fsp } from 'services/fs.service';
import { convertToJSON } from 'services/helpers';

export const readProjectFile = async (): Promise<ProjectData | null> => {
  const data = await fsp.readFile(`${BASE_PATH}${PROJECT_FILENAME}`);

  if (typeof data === 'string') {
    return null;
  }

  return convertToJSON<ProjectData>(data);
};

export const readOldProjectFile = async (): Promise<OldProjectData | null> => {
  const data = await fsp.readFile(`${BASE_PATH}${PROJECT_FILENAME}`);

  if (typeof data === 'string') {
    return null;
  }

  return convertToJSON<OldProjectData>(data);
};

export const writeProjectFile = async (
  projectData: ProjectData
): Promise<void | null> => {
  try {
    await fsp.writeFile(
      `/${PROJECT_FILENAME}`,
      new TextEncoder().encode(JSON.stringify(projectData))
    );
  } catch (error) {
    console.log('ERROR writeProjectFile: ', error);
    return null;
  }
};

export const checkDirectorySize = async (
  directory: string
): Promise<number> => {
  let size = 0;

  try {
    size = await fsp.du(directory);
    // On removing files, .git contains deleted files.
    // They will be deleted on sync with git-server
    if (directory === BASE_PATH) {
      const gitFolderSize = await fsp.du(directory + '.git');
      size -= gitFolderSize;
    }
  } catch (error) {
    console.log('ERROR checkDirectorySize: ', error);
    return size;
  }
  return size;
};
