import * as yup from 'yup';

export const passwordValidation = yup
  .string()
  .min(8, 'Password should have at least 8 characters')
  .matches(
    RegExp('(.*[a-z].*)'),
    'Password should have at least one lowercase character'
  )
  .matches(
    RegExp('(.*[A-Z].*)'),
    'Password should have at least one uppercase character'
  )
  .matches(RegExp('(.*\\d.*)'), 'Password should have at least one number')
  .matches(
    RegExp('[!@#$%^&*(),.?":{}|<>]'),
    'Password should have at least one special character'
  )
  .required('Password is required');

export const passwordConfirmationValidation = yup
  .string()
  .oneOf([yup.ref('password'), null], 'Passwords should be the same')
  .required('Password confirmation is required');

export const currentPasswordValidation = yup
  .string()
  .required('Current password is required');
