import { TreeNode } from '@metavrse-inc/metavrse-lib';
import { atom } from 'jotai';
import { atomWithReset, RESET } from 'jotai/utils';

import { ITargetPath } from 'models/entity/common';

import { selectedEntityAtom } from 'atoms/entities/entities.atom';
import commonHelpers, { KeyPairs } from 'atoms/helpers/common-helpers';

export const treeAtom = atomWithReset<TreeNode[]>([]);
export const treeCopyAtom = atomWithReset<
  [TreeNode[], KeyPairs] | [null, null]
>([null, null]);

export const copiedPathAtom = atomWithReset<number[]>([]);
export const previousTreeAtom = atomWithReset<TreeNode[]>([]);
export const searchTermAtom = atomWithReset<string>('');
export const selectedTreeElementPathAtom = atomWithReset<ITargetPath>([]);
export const selectedConfigElementPathAtom = atomWithReset<ITargetPath>([]);

export const getPathBySelectedEntityAtom = atom((get) => {
  const entity = get(selectedEntityAtom);
  const tree = get(treeAtom);

  if (!entity) {
    return null;
  }

  return commonHelpers.getPathBy(entity.key, tree);
});

export const resetTreeAtom = atom(null, (get, set) => {
  set(treeAtom, RESET);
  set(treeCopyAtom, RESET);
  set(copiedPathAtom, RESET);
  set(previousTreeAtom, RESET);
  set(searchTermAtom, RESET);
  set(selectedTreeElementPathAtom, RESET);
  set(selectedConfigElementPathAtom, RESET);
});
