const copyString = (name: string, topLevel: boolean): string => {
  const indexOfLastDotInName = name.lastIndexOf('.');

  let copy = (topLevel) ? '-copy' : '';

  const newName = `${name.slice(0, indexOfLastDotInName)}${copy}${name.slice(
    indexOfLastDotInName
  )}`;

  const nameWithCopyString = name.includes('.') ? newName : `${name}${copy}`;

  return nameWithCopyString;
};

export default copyString;
