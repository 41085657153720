// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AuthBox-module__root--Auixd {\n  --headerGap: 10px;\n\n  margin-top: 150px;\n  flex-grow: 1;\n}\n\n.AuthBox-module__wrapper--XSmAR {\n  width: var(--formWrapperWidth);\n  margin: 0 auto;\n  border-radius: var(--borderRadius2);\n  overflow: hidden;\n}\n\n.AuthBox-module__formBox--qxSjn {\n  background: var(--surface200);\n  display: flex;\n  padding: 40px 50px 37px 50px;\n}\n\n.AuthBox-module__header--ewj67 {\n  display: flex;\n  align-items: center;\n  background: var(--background);\n  padding-left: var(--spacing6);\n  padding-right: var(--spacing6);\n  height: var(--headerHeight);\n  gap: var(--headerGap);\n}\n", "",{"version":3,"sources":["webpack://./src/components/_account/AuthBox/AuthBox.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;;EAEjB,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,8BAA8B;EAC9B,cAAc;EACd,mCAAmC;EACnC,gBAAgB;AAClB;;AAEA;EACE,6BAA6B;EAC7B,aAAa;EACb,4BAA4B;AAC9B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,6BAA6B;EAC7B,6BAA6B;EAC7B,8BAA8B;EAC9B,2BAA2B;EAC3B,qBAAqB;AACvB","sourcesContent":[".root {\n  --headerGap: 10px;\n\n  margin-top: 150px;\n  flex-grow: 1;\n}\n\n.wrapper {\n  width: var(--formWrapperWidth);\n  margin: 0 auto;\n  border-radius: var(--borderRadius2);\n  overflow: hidden;\n}\n\n.formBox {\n  background: var(--surface200);\n  display: flex;\n  padding: 40px 50px 37px 50px;\n}\n\n.header {\n  display: flex;\n  align-items: center;\n  background: var(--background);\n  padding-left: var(--spacing6);\n  padding-right: var(--spacing6);\n  height: var(--headerHeight);\n  gap: var(--headerGap);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "AuthBox-module__root--Auixd",
	"wrapper": "AuthBox-module__wrapper--XSmAR",
	"formBox": "AuthBox-module__formBox--qxSjn",
	"header": "AuthBox-module__header--ewj67"
};
export default ___CSS_LOADER_EXPORT___;
