import * as yup from 'yup';

import {
  passwordConfirmationValidation,
  passwordValidation,
} from '../passwordValidation';

export const resetPasswordSchema = yup.object().shape({
  password: passwordValidation,
  passwordConfirmation: passwordConfirmationValidation,
});
