import {
  Entities,
  HTMLHudNode,
  ProjectData,
  Scene,
  TreeNode,
  World,
} from '@metavrse-inc/metavrse-lib';
import { atom } from 'jotai';
import { atomWithReset, RESET } from 'jotai/utils';
import { v4 as uuidv4 } from 'uuid';

import { LoadingStatus, StateTypes } from 'models';

import {
  readEntitiesFile,
  writeEntitiesFile,
} from 'services/fileSystem/entities';
import {
  createSceneFolder,
  removeSceneFiles,
} from 'services/fileSystem/scenes';
import { readTreeFile, writeTreeFile } from 'services/fileSystem/tree';
import { readWorldFile, writeWorldFile } from 'services/fileSystem/world';

import { getIncrementalId } from 'stores/editor';

import {
  saveChangesAtom,
  shiftAutosaveIntervalAtom,
} from 'atoms/editor/editor';
import { generateProjectDataAtom, projectAtom } from 'atoms/editor/project';
import { worldAtom } from 'atoms/editor/world';
import { entitiesAtom } from 'atoms/entities/entities.atom';
import { fillEntitiesAtom } from 'atoms/entities/fillEntities.atom';
import commonHelpers from 'atoms/helpers/common-helpers';
import entitiesHelpers from 'atoms/helpers/entities';
import worldHelpers from 'atoms/helpers/world-helpers';
import { setStateAtom } from 'atoms/state';
import { fillNodeAtom } from 'atoms/tree/fillNode.atom';
import { treeAtom } from 'atoms/tree/tree.atom';
import { loadSceneAtom } from 'atoms/viewer/loadScene.atom';

import {
  htmlHudTreeAtom,
  selectedHTMLHudPathAtom,
} from '_html-hud/atoms/htmlHudTree.atom';
import {
  readHTMLHudTreeFile,
  writeHTMLHudTreeFile,
} from '_html-hud/services/filesystem/HTMLHudTreeFS.service';

export const scenesAtom = atomWithReset<Scene[]>([]);
export const selectedSceneAtom = atomWithReset('');

export const resetScenesAtom = atom(null, (get, set) => {
  set(scenesAtom, RESET);
  set(selectedSceneAtom, RESET);
});

const { VIEWER, SCENES, SCENES_CHANGE, CONFIGURATIONS } = StateTypes;
const { LOADING, READY } = LoadingStatus;

export const selectSceneAtom = atom(null, (get, set, key: string) => {
  const run = async () => {
    set(setStateAtom([VIEWER, SCENES, SCENES_CHANGE, CONFIGURATIONS]), {
      value: LOADING,
    });
    const project = get(projectAtom);

    await set(saveChangesAtom);

    const newProject = {
      ...project,
      startingScene: key,
      selectedScene: key,
    };

    set(projectAtom, newProject);
    set(selectedSceneAtom, key);
    set(scenesAtom, project?.scenes);

    const newTree = await readTreeFile(key);
    const newWorld = await readWorldFile(key);
    const newEntities = await readEntitiesFile(key);
    const newHTMLHudTree = await readHTMLHudTreeFile(key);

    if (newTree) {
      set(fillNodeAtom, newTree);
    }
    if (newWorld) {
      set(worldAtom, newWorld);
    }
    if (newEntities) {
      set(fillEntitiesAtom, newEntities);
    }
    if (newHTMLHudTree) {
      set(htmlHudTreeAtom, newHTMLHudTree);
    }

    set(generateProjectDataAtom);
    set(loadSceneAtom);

    set(setStateAtom([VIEWER, SCENES, SCENES_CHANGE, CONFIGURATIONS]), {
      value: READY,
    });
  };

  run();
});

interface CreateSceneUpdate {
  name: string;
  tree: TreeNode[];
  entities: Entities;
  world: World;
  htmlHudTree: HTMLHudNode[];
}

const emptyUpdate: CreateSceneUpdate = {
  name: '',
  tree: [],
  entities: {},
  world: worldHelpers.worldObject(),
  htmlHudTree: [],
};

export const createSceneAtom = atom(
  null,
  (get, set, update: CreateSceneUpdate | undefined = emptyUpdate) => {
    const run = async () => {
      set(shiftAutosaveIntervalAtom);

      set(setStateAtom([SCENES, VIEWER, SCENES_CHANGE, CONFIGURATIONS]), {
        value: LOADING,
      });
      const { name, tree, entities, world, htmlHudTree } = update;
      const project = get(projectAtom);
      const scenes = get(scenesAtom);
      const key = uuidv4();
      const newName = name === '' ? `New scene ${scenes.length + 1}` : name;

      await createSceneFolder(key);
      await writeTreeFile(key, tree);
      await writeEntitiesFile(key, entities);
      await writeWorldFile(key, world);
      await writeHTMLHudTreeFile(key, htmlHudTree);

      const newProject: ProjectData = {
        ...project,
        startingScene: key,
        selectedScene: key,
        scenes: [
          ...project.scenes,
          {
            key,
            name: newName,
          },
        ],
      };

      set(projectAtom, newProject);
      set(selectedSceneAtom, key);
      set(scenesAtom, newProject?.scenes);

      set(fillNodeAtom, tree);
      set(fillEntitiesAtom, entities);
      set(worldAtom, world);

      set(htmlHudTreeAtom, RESET);
      set(selectedHTMLHudPathAtom, RESET);

      set(generateProjectDataAtom);
      set(loadSceneAtom);

      set(setStateAtom([SCENES, VIEWER, SCENES_CHANGE, CONFIGURATIONS]), {
        value: READY,
      });
    };

    run();
  }
);

export const updateSceneAtom = atom(
  null,
  (get, set, { name, key: sceneKey }: { name: string; key: string }) => {
    const run = async () => {
      set(shiftAutosaveIntervalAtom);

      set(setStateAtom([SCENES_CHANGE]), { value: LOADING });
      const project = get(projectAtom);
      const scenes = get(scenesAtom);
      const selectedScene = scenes.find((scene) => scene.key === sceneKey);

      if (selectedScene) {
        const updatedScene = {
          ...selectedScene,
          name,
        };

        const notChangedScenes = scenes.filter(
          (scene) => scene.key !== sceneKey
        );
        const newScenes = [...notChangedScenes, updatedScene];
        set(scenesAtom, newScenes);

        const newProject = { ...project, scenes: newScenes };

        set(projectAtom, newProject);

        set(setStateAtom([SCENES_CHANGE]), { value: READY });
      }
    };

    run();
  }
);

export const removeSceneAtom = atom(null, (get, set, sceneKey: string) => {
  const run = async () => {
    set(shiftAutosaveIntervalAtom);

    set(setStateAtom([VIEWER, SCENES, SCENES_CHANGE, CONFIGURATIONS]), {
      value: LOADING,
    });
    const project = get(projectAtom);
    const scenes = get(scenesAtom);
    const sceneIndex = scenes.findIndex((s) => s.key === sceneKey);

    await set(saveChangesAtom);

    const firstSceneKey =
      scenes.length > 1
        ? sceneIndex === 0
          ? scenes[scenes.length - 1].key
          : scenes[0].key
        : '';

    await removeSceneFiles(sceneKey);

    const newProject: ProjectData = {
      ...project,
      selectedScene: firstSceneKey,
      startingScene: firstSceneKey,
      scenes: [...project.scenes.filter((s) => s.key !== sceneKey)],
    };

    set(projectAtom, newProject);
    set(selectedSceneAtom, firstSceneKey);
    set(scenesAtom, newProject?.scenes);

    const newTree = await readTreeFile(firstSceneKey);
    const newWorld = await readWorldFile(firstSceneKey);
    const newEntities = await readEntitiesFile(firstSceneKey);
    const newHTMLHudTree = await readHTMLHudTreeFile(firstSceneKey);

    if (newTree) {
      set(fillNodeAtom, newTree);
    }
    if (newWorld) {
      set(worldAtom, newWorld);
    }
    if (newEntities) {
      set(fillEntitiesAtom, newEntities);
    }
    if (newHTMLHudTree) {
      set(htmlHudTreeAtom, newHTMLHudTree);
    }

    set(generateProjectDataAtom);
    set(loadSceneAtom);

    set(setStateAtom([VIEWER, SCENES, SCENES_CHANGE, CONFIGURATIONS]), {
      value: READY,
    });
  };

  run();
});

export const duplicateSceneAtom = atom(null, (get, set, sceneKey: string) => {
  const run = async () => {
    set(shiftAutosaveIntervalAtom);

    set(setStateAtom([SCENES, VIEWER, SCENES_CHANGE, CONFIGURATIONS]), {
      value: LOADING,
    });

    const project = get(projectAtom);
    const tree = get(treeAtom);
    const htmlHudTree = get(htmlHudTreeAtom);
    const entities = get(entitiesAtom);
    const world = get(worldAtom);
    const sceneName = project.scenes.find((s) => s.key === sceneKey)?.name;
    const name = `${sceneName} copy`;

    await set(saveChangesAtom);

    const [treeCopy, keyPairs] = commonHelpers.copy(tree, getIncrementalId);
    const [htmlTreeCopy, htmlTreeKeyPairs] = commonHelpers.copy(
      htmlHudTree,
      getIncrementalId
    );
    const entitiesCopy = entitiesHelpers.copy(entities, treeCopy, keyPairs);

    await set(createSceneAtom, {
      name,
      tree: treeCopy,
      entities: entitiesCopy,
      world,
      htmlHudTree: htmlTreeCopy,
    });

    set(setStateAtom([SCENES, VIEWER, SCENES_CHANGE, CONFIGURATIONS]), {
      value: READY,
    });
  };

  run();
});
